import React, { useEffect } from 'react';
import { Input, InputSize } from '../../../../../../../components/input';
import * as actions from './actions';
import { Button, SelectField } from '../../../../../../../ui';
import { ISelectOption, SelectSize } from '../../../../../../../ui/select';
import { ButtonSize, ButtonVariant } from '../../../../../../../ui/button';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';

export const Degree: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, degree, error } = useSelector(
    (state) => ({
      isLoading: state.pages.admin.settings.degrees.update.degree.isLoading,
      degree: state.pages.admin.settings.degrees.update.degree,
      error: state.pages.admin.settings.degrees.update.degree.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <h1 className={'text-neutral-darkest text-lg mb-2'}>
        Edit Degree: {degree.typeAcronym} | {degree.name}
      </h1>
      <hr className={''} />

      <div className={'flex w-full items-end mt-3'}>
        <div className={'w-4/12 mr-2'}>
          <Input
            label={'Name'}
            placeholder={'name'}
            size={InputSize.xs}
            name={'name'}
            required={true}
            type={'text'}
            error={error}
            value={degree.name}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'name',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-4/12 mr-2">
          <Input
            label={'Abbr. Name'}
            placeholder={'Abbreviated Name'}
            size={InputSize.xs}
            name={'abbreviatedName'}
            required={true}
            type={'text'}
            error={error}
            value={degree.abbreviatedName}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'abbreviatedName',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-4/12 mr-2">
          <Input
            label={'Total Credits Required'}
            placeholder={'25'}
            size={InputSize.xs}
            name={'totalCreditsRequired'}
            required={true}
            type={'number'}
            error={error}
            value={degree.totalCreditsRequired}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'totalCreditsRequired',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className={'w-2/12 items-center flex flex-1'}>
          <Button
            handleOnClick={() => {
              dispatch(actions.update.start());
            }}
            size={ButtonSize.XXSmall}
            variant={ButtonVariant.Primary}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
