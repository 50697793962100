import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as actions from './actions';

const open: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.open),
    map(({ payload }) => actions.fetch(payload))
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    mergeMap(({ payload }) => of(actions.load.start(payload)))
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(({ payload }) =>
      from(api.requirements.getRequirementStatus(payload)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data.data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(open, fetch, load);
