import React from "react";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {DragAndDropSchedule} from "../DragAndDropSchedule";
import {CourseBin} from "./course-bin";
import {ISchedule} from "../../api/schedules";
import {ICourse} from "../../types/course";

export interface IStudentScheduleRenderHeaderProps {
    handleOnAddTerm: () => any;
}
export interface IStudentDragAndDropScheduleProps {
    schedule: ISchedule;
    displayTermCost: boolean;
    handleOnDragEnd: (result: DropResult) => any;
    handleOnAddTerm: () => any;
    handleOnRemoveTerm: (termId: number) => any;
    renderHeader: React.FC<IStudentScheduleRenderHeaderProps>;
    courseBin: ICourse[];
}

export const StudentDragAndDropSchedule: React.FC<IStudentDragAndDropScheduleProps> = ({
      schedule,
      displayTermCost,
      handleOnDragEnd,
      handleOnAddTerm,
      handleOnRemoveTerm,
      renderHeader: ScheduleHeader,
      courseBin
}) => {

    if(!schedule) {
        return null;
    }

    return (
        <>
            <ScheduleHeader handleOnAddTerm={handleOnAddTerm}/>
            <section className={"flex justify-between"}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <DragAndDropSchedule
                        handleOnRemoveTerm={handleOnRemoveTerm}
                        displayTermCost={displayTermCost}
                        isDraggable
                        schedule={schedule}
                    />
                    <CourseBin groupIndex={schedule.termGroups.length} termIndex={schedule.terms.length} courses={courseBin} />
                </DragDropContext>
            </section>
        </>
    )

}
