import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isActive: boolean;
  academicYear: string;
  endDate: string;
  isLoading: boolean;
  registrationPeriodId: number;
}

const initialState: IState = {
  isActive: false,
  isLoading: false,
  academicYear: '',
  endDate: '',
  registrationPeriodId: 0
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.show, (state, payload) => ({
  ...state,
  isActive: true,
}));

reducer.on(actions.dismiss, (state) => ({
  ...state,
  isActive: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isActive: payload.data.hasActiveSurvey,
  academicYear: payload.data.academicYear,
  endDate: payload.data.surveyEndDate,
  registrationPeriodId: payload.data.id
}));

export const preferenceSubmissionNotificationReducer = reducer;
