import React from 'react';
import { AuthHeader } from '../../../../../shared/header';
import { Input } from '../../../../../../../components/input';
import * as actions from '../actions';
import { Button } from '../../../../../../../components/button';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';

export const ResetPasswordInputs = () => {
  const dispatch = useDispatch();

  const { isLoading, password, confirmPassword, error } = useSelector(
    (state) => ({
      error: state.pages.auth.modals.resetPassword.error,
      password: state.pages.auth.modals.resetPassword.password,
      confirmPassword: state.pages.auth.modals.resetPassword.confirmPassword,
      isOpen: state.pages.auth.modals.resetPassword.isOpen,
      isLoading: state.pages.auth.modals.resetPassword.isLoading,
    }),
    shallowEqual
  );

  return (
    <>
      <div className={'w-full'}>
        <AuthHeader
          title={'Reset your password?'}
          subtitle={
            'Enter your new password below.'
          }
        />
      </div>
      <div className={'w-full flex items-center justify-center'}>
        <div className="w-9/12">
          <div className={'mb-8 w-full'}>
            <Input
              onChange={(e) =>
                dispatch(actions.onPasswordChange(e.target.value))
              }
              name={'password'}
              label={'Password'}
              type={'password'}
              error={error}
              value={password}
              required={true}
              placeholder={'enter password'}
            />
          </div>
          <div className={'mb-6 w-full'}>
            <Input
              onChange={(e) =>
                dispatch(actions.onPasswordConfirmChange(e.target.value))
              }
              name={'password_confirmation'}
              label={'Confirm Password'}
              type={'password'}
              error={error}
              value={confirmPassword}
              required={true}
              placeholder={'enter password confirmation'}
            />
          </div>
          <div className={'flex justify-end text-right w-full mt-4'}>
            <Button
              className={
                'text-sm block bg-blue-darkest font-semibold rounded-md px-14 py-3'
              }
              handleOnClick={(e) => dispatch(actions.submit())}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
