import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';
import { epic as courses } from "./courses/epic";
import { epic as info } from "./info/epic";

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );
const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = state$.value.common.match.params.requirementId;

      return from(
        api.degrees.getDegreeRequirement(degreeId, requirementId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.load.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

const save: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.start),
    switchMap(() => {

      const { name, type, numberRequired, color, fallbackRequirementId } = state$.value.pages.admin.settings.degrees.requirements.update.info;
      const { assigned } = state$.value.pages.admin.settings.degrees.requirements.update.courses;
      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = state$.value.common.match.params.requirementId;

      console.log(fallbackRequirementId);

      return from(
        api.degrees.updateDegreeRequirement({
          requirementId,
          degreeId,
          name,
          type: parseInt(type.value),
          numberRequired,
          color,
          courses: assigned,
          fallbackRequirementId: fallbackRequirementId ?? null,
          fallbackRequirement: {
            id: fallbackRequirementId ?? null,
            name: ""
          }
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.save.done());
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.save.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

export const epic = combineEpics(fetch, load, save, courses, info);
