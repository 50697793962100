import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../types/error';

interface IState {
  isLoading: boolean;
  email: string;
  password: string;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  email: '',
  password: '',
  error: {
    message: '',
    errors: {},
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.fetch, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.login.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.login.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.login.done, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  //@ts-ignore
  [payload.key]: payload.value,
}));

export const loginReducer = reducer;
