import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import SkeletonModal from '../../../../../../components/modal/skeleton';
import { Button, SelectField } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { Input, InputSize } from '../../../../../../components/input';
import { DateSelect } from '../../../../../../ui/date-picker';
import { CiCalendarDate } from 'react-icons/ci';
import {useQuery, useQueryClient } from 'react-query';
import { ISelectOption } from '../../../../../../ui/select';
import {
  ICreateRegistrationPeriodRequest,
  IRegistrationPeriod,
  registrationPeriods
} from '../../../../../../api/registration-periods';
import { academicYear } from '../../../../../../api/academic-years';
import { useDegreelyMutation } from '../../../../../../infrastructure/query';
import { AxiosError } from 'axios';
import { IValidationErrorResponse } from '../../../../../../types/error';

export interface IAddRegistrationPeriodModalProps {
  isOpen: boolean;
  handleClose: () => any;
}

export const AddRegistrationPeriodModal: React.FC<IAddRegistrationPeriodModalProps> = ({ isOpen, handleClose }) => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<IValidationErrorResponse>({ errors: {}, message: "" });
  const [registrationPeriodLabel, setRegistrationPeriodLabel] = useState<string>("");
  const [registrationPeriodTerms, setRegistrationPeriodTerms] = useState<ISelectOption[]>([]);
  const [registrationPeriodStartDate, setRegistrationPeriodStartDate] = useState<Date | null>(new Date());
  const [registrationPeriodEndDate, setRegistrationPeriodEndDate] = useState<Date | null>(new Date());
  const [addDropDeadline, setAddDropDeadline] = useState<Date | null>(new Date());
  const [withdrawDeadline, setWithdrawDeadline] = useState<Date | null>(new Date());
  const [preferenceSubmissionStartDate, setPreferenceSubmissionStartDate] = useState<Date | null>(new Date());
  const [preferenceSubmissionEndDate, setPreferenceSubmissionEndDate] = useState<Date | null>(new Date());

  const mutation = useDegreelyMutation<IRegistrationPeriod, AxiosError<IValidationErrorResponse>, ICreateRegistrationPeriodRequest>({
    mutationFn: (payload) => {
      return registrationPeriods.createRegistrationPeriod(payload)
    },
    handleOnSuccess: (response) => {
      queryClient.invalidateQueries(`registrationPeriods-${academicYearId}`);
      handleClose();
    },
    handleOnError: (error: AxiosError<IValidationErrorResponse>) => {
      setErrors(error?.response?.data ?? { errors: {}, message: "" });
  }
  })

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useQuery('academicYearTerms', () => academicYear.getAcademicYearTermsByAcademicYearId(schoolId, academicYearId), {
    enabled: !!schoolId && !!academicYearId && isOpen,
  });

  const handleSave = () => {
    mutation.mutate({
      schoolId: schoolId,
      name: registrationPeriodLabel,
      terms: registrationPeriodTerms.map((term) => parseInt(term.value)),
      academicYearId: academicYearId,
      startDate: registrationPeriodStartDate,
      endDate: registrationPeriodEndDate,
      addDropDeadline: addDropDeadline,
      withdrawDeadline: withdrawDeadline,
      preferenceSubmissionStartDate: preferenceSubmissionStartDate,
      preferenceSubmissionEndDate: preferenceSubmissionEndDate,
      emailAndMessagingCommunication: ""
    })
  }

  useEffect(() => {
    setErrors({ errors: {}, message: "" })
  }, [registrationPeriodLabel, registrationPeriodStartDate, registrationPeriodEndDate, addDropDeadline, withdrawDeadline, preferenceSubmissionStartDate, preferenceSubmissionEndDate]);

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => handleClose()} />;
  }

  return (
    <>
      <Modal
        handleClose={() => handleClose()}
        isOpen={isOpen}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-6'}>
              <div className="mr-2 w-2/12">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
              <div className={"w-2/12"}>
                <Button
                  handleOnClick={handleSave}
                  isLoading={mutation.isLoading}
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Primary}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
              <section className={"flex items-center justify-center"}>
                <div className={"text-center flex flex-col justify-center items-center"}>
                  <CiCalendarDate className={"fill-neutral-light stroke-neutral-light text-3xl mb-1"} />
                  <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Add Registration Period</h2>
                </div>
              </section>
              <div className={"flex items-center mb-4 flex-wrap"}>
                <div className={"w-full mb-4"}>
                    <Input value={registrationPeriodLabel} size={InputSize.md} onChange={(e) => setRegistrationPeriodLabel(e.target.value)} type={"text"} required={true} name={"name"} placeholder={"Fall Registration"} label={"Name"} />
                </div>
                <div className={"w-full"}>
                    <SelectField label={"Terms"} multiple={true} name={"terms"} onChange={(options) => setRegistrationPeriodTerms(options)} options={data?.data.length ? data?.data.map((term) => ({
                      label: term.label,
                      value: term.id.toString()
                    })) : []} placeholder={"Select terms"} error={errors} />
                </div>
              </div>
              <div className={'flex items-center mb-4'}>
                <div className={"w-1/2"}>
                  <div className='pr-2'>
                    <DateSelect error={errors} name={"startDate"} label={`Start Date`} onChange={(date) => setRegistrationPeriodStartDate(date)} selected={registrationPeriodStartDate ?? new Date()} />
                  </div>
                </div>
                <div className={"w-1/2"}>
                  <div className='pl-2'>
                    <DateSelect error={errors} name={"endDate"} label={`End Date`} onChange={(date) => setRegistrationPeriodEndDate(date)} selected={registrationPeriodEndDate ?? new Date()} />
                  </div>
                </div>
              </div>
              <div className={'flex items-center mb-4'}>
                <div className={"w-1/2"}>
                  <div className='pr-2'>
                    <DateSelect error={errors} name={"addDropDeadline"} label={`Add Drop Deadline`} onChange={(date) => setAddDropDeadline(date)} selected={addDropDeadline ?? new Date()} />
                  </div>
                </div>
                <div className={"w-1/2"}>
                  <div className='pl-2'>
                    <DateSelect error={errors} name={"withdrawalDeadline"} label={`Withdrawal Deadline`} onChange={(date) => setWithdrawDeadline(date)} selected={withdrawDeadline ?? new Date()} />
                  </div>
                </div>
              </div>
              <div className={'flex items-center mb-4'}>
                <div className={"w-1/2"}>
                  <div className='pr-2'>
                    <DateSelect error={errors} name={"preferenceSubmissionStartDate"} label={`Preference Submission Start Date`} onChange={(date) => setPreferenceSubmissionStartDate(date)} selected={preferenceSubmissionStartDate ?? new Date()} />
                  </div>
                </div>
                <div className={"w-1/2"}>
                  <div className='pl-2'>
                    <DateSelect error={errors} name={"preferenceSubmissionEndDate"} label={`Preference Submission End Date`} onChange={(date) => setPreferenceSubmissionEndDate(date)} selected={preferenceSubmissionEndDate ?? new Date()} />
                  </div>
                </div>
              </div>
        </div>
      </Modal>
    </>
  );
};
