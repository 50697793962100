import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as trackActions from '../current-track/actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import { ISchedule } from '../../../../../api/schedules';

const fetchOnTrackUpdate: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(trackActions.update.done),
    map(() => actions.load.start())
  );

const fetchOnTrackLoad: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(trackActions.load.done),
    map(() => actions.load.start())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { schedule, list } = state$.value.pages.dashboard.currentTrack;

      return from(api.schedules.getScheduleProgress(schedule.id)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  fetch,
  load,
  fetchOnTrackUpdate,
  fetchOnTrackLoad
);
