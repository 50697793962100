import {combineEpics, ofType} from 'redux-observable';
import {catchError, concat, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {from, of} from 'rxjs';
import * as actions from './actions';
import * as degreeActions from '../majors/actions';
import {api} from '../../../../../../api';
import {IEpic} from '../../../../../../infrastructure/selector';
import {Helpers} from '../../../../../../helpers';
import * as alertActions from "../../../../../../common/alerts/actions";
import {AlertVariant} from "../../../../../../common/alerts/reducer";

const fetchOnMajorUpdate: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(
      degreeActions.assignCourseToDegree.done,
      degreeActions.removeCourseFromDegree.done
    ),
    map(() => actions.fetch())
  );

const onSuccessfulSave: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(
            actions.update.done
        ),
        map(() => alertActions.trigger({
            variant: AlertVariant.Success,
            message: 'Course updated successfully',
            heading: "Updated!"
        }))
    );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () =>
        !!state$.value.pages.admin.settings.common.config.school
          .availableSeasons.length
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.admin.courses.getCourseById(state$.value.common.match.params.id)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error.response.data)).pipe(concat(source$))
    )
  );

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(() => {
      const {
        id,
        name,
        code,
        credits,
        termsOffered,
        difficulty,
        customDetailsEnabled,
        gradeLevel,
        isRepeatable
      } = state$.value.pages.admin.settings.course.details.course;
      const { selected: concurrencyRequirements } =
        state$.value.pages.admin.settings.course.concurrency;
      const { selected: prerequisiteRequirements } =
        state$.value.pages.admin.settings.course.prerequisites;

      debugger;

      return from(
        api.admin.courses.updateCourse({
          id,
          name,
          code,
          credits,
          termsOffered:
            Helpers.mapValuesFromSelectOptions<number>(termsOffered),
          difficulty,
          customDetailsEnabled,
          gradeLevel: gradeLevel ? Helpers.mapValuesFromSelectOptions<number>(gradeLevel) : null,
          prerequisites: Helpers.mapValuesFromSelectOptions<number>(
            prerequisiteRequirements
          ),
          concurrency: Helpers.mapValuesFromSelectOptions<number>(
            concurrencyRequirements
          ),
          isRepeatable
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.update.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.update.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(fetch, load, update, fetchOnMajorUpdate, onSuccessfulSave);
