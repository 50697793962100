import { createReducer } from 'redux-act';
import * as actions from './actions';
import { DegreeType } from '../../../../../../../api/admin/degrees';
import { IValidationErrorResponse } from '../../../../../../../types/error';

interface IState {
  name: string;
  abbreviatedName: string;
  type: DegreeType;
  totalCreditsRequired: number;
  isLoading: boolean;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  name: '',
  abbreviatedName: '',
  type: 0,
  totalCreditsRequired: -1,
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    ...payload,
  };
});

reducer.on(actions.update.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.update.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.update.done, (state, payload) => {
  return {
    ...state,
    ...payload,
    isLoading: false,
  };
});

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  //@ts-ignore
  [payload.key]: payload.value,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onTypeChange, (state, payload) => ({
  ...state,
  type: parseFloat(payload.value),
  error: {
    message: '',
    errors: {},
  },
}));

export const degreeReducer = reducer;
