import React from 'react';
import { shallowEqual } from 'react-redux';
import { SimpleAction } from '../../../../../../components/simple-action';
import { GoChecklist } from 'react-icons/go';
import { FaPlus } from 'react-icons/fa6';
import { registrationPeriods } from '../../../../../../api/registration-periods';
import { useQuery } from 'react-query';
import { academicYear } from '../../../../../../api/academic-years';
import { useSelector } from '../../../../../../infrastructure/selector';
import moment from 'moment';

export interface IRegistrationPeriodsProps {
  isDisabled?: boolean;
  handleModalOpen: () => any;
  handleEditModalOpen?: (id: number) => any;
}
export const RegistrationPeriods: React.FC<IRegistrationPeriodsProps> = ({ isDisabled, handleModalOpen, handleEditModalOpen }) => {

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      academicYearId: state.common.match.params.id,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useQuery(`registrationPeriods-${academicYearId}`, () => registrationPeriods.getRegistrationPeriods({ schoolId, academicYearId }), {
    enabled: !!schoolId && !!academicYearId && !isDisabled,
  });

  return (
    <section className={'mt-10 mb-8'}>
      <div className={"flex items-center justify-between"}>
        <h3 className={"text-lg text-neutral-darkest mb-2"}>Registration Periods</h3>
        {!isDisabled && <SimpleAction icon={FaPlus} label={"Add Registration Period"} onClick={() => handleModalOpen()} />}
      </div>
      <hr className={"border-b border-neutral-lighter mb-4"}/>

      {(!data?.data.length || isDisabled) && <div className={"flex items-center justify-center mt-12"}>
        <div className={"text-center flex flex-col justify-center items-center"}>
          <GoChecklist className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
          <h3 className={"text-black font-semibold"}>No Registration Periods</h3>
          <p className={"text-neutral-dark text-sm"}>You have not created any registration periods for this school year</p>
          <div className={"mt-4"}>
            <SimpleAction disabled={isDisabled} icon={FaPlus} label={"Add Registration Period"} onClick={() => handleModalOpen()} />
          </div>
        </div>
      </div>}

      <section className={'flex items-center overflow-x-auto w-full pb-4 max-w-full'}>
        {!!data?.data.length && !isDisabled && data?.data.map((period) => {
          return (
            <div className={'w-2/12 mr-3 min-w-fit flex-grow-0 flex-shrink-0'} onClick={() => handleEditModalOpen && handleEditModalOpen(period.id)}>
              <div className={'bg-neutral-lightest p-4 rounded-md cursor-pointer hover:bg-neutral-lighter'}>
                <h4 className={'text-neutral-darkest text-sm font-semibold mb-1'}>{period.name}</h4>
                <span
                  className={'text-neutral-dark text-xxs font-medium block'}>Start Date: {moment(period.startDate).format('MM/DD/YYYY')}</span>
                <span
                  className={'text-neutral-dark text-xxs font-medium block'}>End Date: {moment(period.endDate).format('MM/DD/YYYY')}</span>
                <span
                  className={'text-neutral-dark text-xxs font-medium block'}>Course Requests Start Date: {moment(period.preferenceSubmissionStartDate).format('MM/DD/YYYY')}</span>
                <span
                  className={'text-neutral-dark text-xxs font-medium block'}>Course Requests End Date: {moment(period.preferenceSubmissionEndDate).format('MM/DD/YYYY')}</span>

              </div>
            </div>
          );
        })}
      </section>

      {/*{!!data?.data.length && !isDisabled && (*/}
      {/*  <div className={"flex items-center mb-2"}>*/}
      {/*    <div className={"w-2/12 text-neutral-darkest text-xs font-semibold"}>Name</div>*/}
      {/*    <div className={"w-1/12 text-neutral-darkest text-xs font-semibold"}>Start Date</div>*/}
      {/*    <div className={"w-1/12 text-neutral-darkest text-xs font-semibold"}>End Date</div>*/}
      {/*    <div className={"w-2/12 text-neutral-darkest text-xs font-semibold"}>Add/Drop Deadline</div>*/}
      {/*    <div className={"w-2/12 text-neutral-darkest text-xs font-semibold"}>Withdrawal Deadline</div>*/}
      {/*    <div className={"w-2/12 text-neutral-darkest text-xs font-semibold"}>Pref Submission Start Date</div>*/}
      {/*    <div className={"w-2/12 text-neutral-darkest text-xs font-semibold"}>Pref Submission End Date</div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{!!data?.data.length && !isDisabled && data?.data.map((period) => {*/}
      {/*  return (*/}
      {/*    <div className={"flex items-center mb-2"}>*/}
      {/*      <div className={"w-2/12 text-neutral text-xs"}>{period.name}</div>*/}
      {/*      <div className={"w-1/12 text-neutral text-xs"}>{moment(period.startDate).format("MM/DD/YYYY")}</div>*/}
      {/*      <div className={"w-1/12 text-neutral text-xs"}>{moment(period.endDate).format("MM/DD/YYYY")}</div>*/}
      {/*      <div className={"w-2/12 text-neutral text-xs"}>{moment(period.addDropDeadline).format("MM/DD/YYYY")}</div>*/}
      {/*      <div className={"w-2/12 text-neutral text-xs"}>{moment(period.withdrawalDeadline).format("MM/DD/YYYY")}</div>*/}
      {/*      <div className={"w-2/12 text-neutral text-xs"}>{moment(period.preferenceSubmissionStartDate).format("MM/DD/YYYY")}</div>*/}
      {/*      <div className={"w-2/12 text-neutral text-xs"}>{moment(period.preferenceSubmissionEndDate).format("MM/DD/YYYY")}</div>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*})}*/}
    </section>
  )
}
