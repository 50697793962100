import { createAction } from 'redux-act';
import { ISchedule, IScheduleTermGroup } from '../../../../../api/schedules';
import { IScheduleTerm } from '../../../../../api/schedule-terms';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof update.start>
  | ReturnType<typeof update.done>
  | ReturnType<typeof update.error>;

export const fetch = createAction('[pages/dashboard/current-track] fetch');

export const load = {
  start: createAction('[pages/dashboard/current-track] load.start'),
  error: createAction<any>('[pages/dashboard/current-track] load.error'),
  done: createAction<ISchedule>('[pages/dashboard/current-track] load.done'),
};

export const update = {
  start: createAction<IScheduleTerm[]>(
    '[pages/dashboard/current-track] update.start'
  ),
  error: createAction<any>('[pages/dashboard/current-track] update.error'),
  done: createAction<ISchedule>('[pages/dashboard/current-track] update.done'),
};

export const updateTermGroups = {
  done: createAction<IScheduleTermGroup[]>(
    '[pages/dashboard/current-track] updateTermGroups.done'
  ),
};

export const onDragEnd = {
  start: createAction<DropResult>(
    '[pages/dashboard/current-track] onDragEnd.start'
  ),
  done: createAction<IScheduleTermGroup[]>(
    '[pages/dashboard/current-track] onDragEnd.done'
  ),
};

export const setCurrentTrackIndex = createAction<number>(
  '[pages/dashboard/track-list] setCurrentTrackIndex'
);
