import React from 'react';
import { Button } from '../../../../../ui';
import {
  ButtonBorderColorVariant,
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant
} from '../../../../../ui/button';
import { shallowEqual, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { generatePath, Link } from 'react-router-dom';
import { Routes as DegreelyRoutes } from '../../../../../common/routes';
import { UserAvatar } from '../../../../../components/avatar';
import * as actions from '../actions';
import { RequiresMessagingAccess } from '../../../../../common/guards/messaging-access';
import { useAppSelector } from '../../../../../app/hooks';

export const Avatar: React.FC = () => {
  const dispatch = useDispatch();

  const { name, major, avatarUrl, isLoading, email, studentId, flagCount, hasActiveSchedule, activePreview } =
    useAppSelector(
      (state) => ({
        activePreview: state.pages.admin.dashboard.drawers.studentDetail.activePreview,
        isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
        hasActiveSchedule: state.pages.admin.dashboard.drawers.studentDetail.hasActiveSchedule,
        name: state.pages.admin.dashboard.drawers.studentDetail.name,
        studentId: state.pages.admin.dashboard.drawers.studentDetail.studentId,
        email: state.pages.admin.dashboard.drawers.studentDetail.email,
        avatarUrl: state.pages.admin.dashboard.drawers.studentDetail.avatarUrl,
        major: state.pages.admin.dashboard.drawers.studentDetail.major,
        flagCount: state.pages.admin.dashboard.drawers.studentDetail.flags,
      }),
      shallowEqual
    );

  if (isLoading) {
    return (
      <>
        <div className={'flex mt-4 items-center'}>
          <div className={'w-2/12 mr-4'}>
            <Skeleton circle={true} height={70} />
          </div>
          <div className={'w-10/12'}>
            <Skeleton width={'80%'} height={30} />
            <Skeleton width={'50%'} height={15} />
            <Skeleton width={'50%'} height={15} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <section className={'flex mt-4 items-center mb-6'}>
        <UserAvatar
          imageUrl={avatarUrl}
          size={75}
          countableIdentifier={flagCount}
        />
        <div className={'flex flex-col ml-6'}>
          <h3 className={'text-neutral-darkest text-2xl'}>{name}</h3>
          <h3 className={'text-xs text-neutral'}>{email}</h3>
          <span className={'italic text-xs text-neutral'}>{major}</span>
          <div className={"flex items-center mt-2"}>
            <Button
              classes={"mr-4"}
              handleOnClick={() => dispatch(actions.preview.select(0))}
              size={ButtonSize.XXXSmall}
              variant={activePreview == 0 ? ButtonVariant.Primary : ButtonVariant.White}
              borderColorVariant={activePreview == 0 ? ButtonBorderColorVariant.Secondary : ButtonBorderColorVariant.Primary}
              style={activePreview == 0 ? ButtonStyle.Filled : ButtonStyle.Outline}
              radius={ButtonBorderRadius.FULL}
              shadow={true}
            >Progress Preview</Button>
            <Button
              handleOnClick={() => dispatch(actions.preview.select(1))}
              size={ButtonSize.XXXSmall}
              variant={activePreview == 1 ? ButtonVariant.Primary : ButtonVariant.White}
              borderColorVariant={ButtonBorderColorVariant.Secondary}
              style={activePreview == 1 ? ButtonStyle.Filled : ButtonStyle.Outline}
              radius={ButtonBorderRadius.FULL}
              shadow={true}
            >Profile Preview</Button>
          </div>
        </div>
      </section>
    </>
  );
};
