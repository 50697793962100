import { createAction } from 'redux-act';
import { ICourseRequest } from '../../../../../../../api/course-requests';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const open = createAction<number>(
  '[pages/registration/course-requests/modals/course-request-backups-modal] open'
);
export const close = createAction(
  '[pages/registration/course-requests/modals/course-request-backups-modal] close'
);

export const load = {
  start: createAction<number>('[pages/registration/course-requests/modals/course-request-backups-modal] load.start'),
  error: createAction('[pages/registration/course-requests/modals/course-request-backups-modal] load.error'),
  done: createAction<ICourseRequest>('[pages/registration/course-requests/modals/course-request-backups-modal] load.done'),
};

export const add = {
  start: createAction<{ courseRequestId: number, priority: number, backupCourseId: number }>('[pages/registration/course-requests/modals/course-request-backups-modal] add.start'),
  error: createAction('[pages/registration/course-requests/modals/course-request-backups-modal] add.error'),
  done: createAction('[pages/registration/course-requests/modals/course-request-backups-modal] add.done'),
};

export const remove = {
  start: createAction<{ courseRequestId: number, backupId: number }>('[pages/registration/course-requests/modals/course-request-backups-modal] remove.start'),
  error: createAction('[pages/registration/course-requests/modals/course-request-backups-modal] remove.error'),
  done: createAction('[pages/registration/course-requests/modals/course-request-backups-modal] remove.done'),
};

export const onSearch = createAction<string>(
  '[pages/registration/course-requests/modals/course-request-backups-modal] search'
);
