import React, {useEffect} from "react";
import {CourseCard} from "../../../../../../../components/course-card";
import * as actions from "./actions";
import {useDispatch} from "react-redux";
import { useQueryClient, useQuery } from "react-query";
import { courses } from "../../../../../../../api/courses";
import {ICourse} from "../../../../../../../types/course";
import {IActivePreference} from "../../index";

export interface ICourseListProps {
    list: ICourse[];
}
export const CourseList: React.FC<ICourseListProps> = ({ list }) => {

    if(!list?.length) {
        return null;
    }

    return (
        <>
            <h3 className={"text-black font-medium text-sm"}>Available Courses</h3>
            <span className={"text-neutral mb-4 font-light text-sm block"}>Results: {list.length}</span>
            {list.map((course: ICourse) => <CourseCard name={course.name} code={course.code} courseId={course.id} credits={course.credits} borderColor={"#00AAFF"} />)};
        </>
    )
}
