import { combineReducers } from 'redux';

import { courseBinReducer as courseBin } from './course-bin/reducer';
import { courseHistoryReducer as courseHistory } from "./course-history/reducer";
import { requestsReducer as requests} from './requests/reducer';
import { modalsReducer as modals } from './modals/reducer';

export const courseRequestsReducer = combineReducers({
  courseBin,
  courseHistory,
  requests,
  modals
});
