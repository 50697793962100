import { combineEpics } from 'redux-observable';
import { epic as courseBin } from './course-bin/epic';
import { epic as courseHistory } from "./course-history/epic";
import { epic as requests } from "./requests/epic";
import { epic as modals } from "./modals/epic";

export const epic = combineEpics(
  courseBin,
  courseHistory,
  requests,
  modals
);
