import { AxiosPromise } from 'axios';
import client from '../axios.client';
import {coursePreferences} from "./course-preferences";

export interface IRegistration {

}


class RegistrationAPI implements IRegistration {
    public coursePreferences = coursePreferences
}

export const registration = new RegistrationAPI();
