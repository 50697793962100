import { createAction } from 'redux-act';
import { ISelectedDegreeRequirementCourse } from './reducer';
import { IAvailableDegreeCourse } from '../../../../../../../../api/degree';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] load.error'
  ),
  done: createAction<IAvailableDegreeCourse[]>(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] load.done'
  ),
};

export const select = createAction<ISelectedDegreeRequirementCourse>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] select'
);

export const onScheduleRequirementChange = createAction<ISelectedDegreeRequirementCourse>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] onScheduleRequirementChange'
);

export const assign = createAction<number>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] assign'
);

export const unAssign = createAction<ISelectedDegreeRequirementCourse>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] unAssign'
);

export const filter = createAction(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/courses] filter'
);
