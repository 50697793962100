import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourseRequest } from '../../../../../../../api/course-requests';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  scheduleId: number;
  activeCourseRequestId: number | null;
  activeCourseRequest: ICourseRequest | null;
  search: string;
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  scheduleId: 0,
  activeCourseRequestId: null,
  activeCourseRequest: null,
  search: ""
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
  activeCourseRequestId: payload
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  activeCourseRequestId: null,
  activeCourseRequest: null
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    activeCourseRequest: payload,
  };
});

reducer.on(actions.onSearch, (state, payload) => {
  return {
    ...state,
    search: payload
  };
});

export const courseRequestBackupsModalReducer = reducer;
