import React, { useEffect } from 'react';
import { RequirementInfo } from './info';
import { RequirementCourses } from './courses';
import * as actions from './actions';
import { useDispatch } from 'react-redux';

export const DegreeRequirementShowPage: React.FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <RequirementInfo />
      <RequirementCourses />
    </>
  );
}
