import { createAction } from 'redux-act';
import { IRequirementStatusRequest } from '../../../../../../api/requirements';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const open = createAction<IRequirementStatusRequest>(
  '[pages/dashboard/modals/requirement-category-detail] open'
);
export const close = createAction(
  '[pages/dashboard/modals/requirement-category-detail] close'
);

export const fetch = createAction<IRequirementStatusRequest>(
  '[pages/dashboard/modals/requirement-category-detail] fetch'
);

export const load = {
  start: createAction<IRequirementStatusRequest>(
    '[pages/dashboard/modals/requirement-category-detail] load.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/modals/requirement-category-detail] load.error'
  ),
  done: createAction<any>(
    '[pages/dashboard/modals/requirement-category-detail] load.done'
  ),
};
