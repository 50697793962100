import { createReducer } from 'redux-act';
import * as actions from './actions';
import {
  ICourseInfo,
  IRequirementCategoryStatus,
} from '../../../../../../api/requirements';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  details: {
    category: IRequirementCategoryStatus;
    courses: ICourseInfo[];
  };
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  details: {
    category: {
      id: 0,
      name: '',
      completed: 0,
      required: 0,
      color: '',
      unit: '',
    },
    courses: [],
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  details: {
    ...payload,
  },
}));

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

export const requirementCategoryDetailModalReducer = reducer;
