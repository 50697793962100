import React, {ReactNode} from 'react';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export interface ICourseCardSimpleProps {
  name: string;
  code: string;
  courseId: number;
  credits: number;
  borderColor: string;
  isDraggable?: boolean;
  onDestroy?: () => any;
  onClick?: () => any;
  alert?: {
    message: ReactNode;
    icon: ReactNode;
  }
}

export const CourseCardSimple: React.FC<ICourseCardSimpleProps> = ({ name, code, courseId, credits, borderColor, isDraggable, onDestroy, onClick, alert }) => {
  return (
    <section
      onDragStart={(event: any) => {
        event.dataTransfer.setData("id", event.target.id.toString())
      }}
      id={courseId.toString()}
      draggable={!!isDraggable}
      className={'cursor-pointer flex-1 bg-white shadow-lg border-l-4 rounded-md py-2 px-3 mb-2'}
      style={{ borderColor }}
    >
      <div onClick={onClick && onClick}>
        <h6 className={'text-neutral-darkest font-medium text-sm mb-1'}>{name}</h6>
        <div className='flex justify-between'>
          <span className="className text-neutral font-light text-xxs">
            {code}
          </span>
          {onDestroy && !alert && <BiTrash className={"fill-neutral-light hover:fill-red cursor-pointer"} onClick={() => !!onDestroy && onDestroy()} /> }
        </div>
      </div>

      {!!alert && <div className={"flex items-center justify-between mt-2"}>
        <div className={"flex items-center"}>
          {alert.icon}
          {alert.message}
        </div>
        {onDestroy && <BiTrash className={"fill-neutral-light hover:fill-red cursor-pointer"} onClick={() => !!onDestroy && onDestroy()} /> }
      </div>}
    </section>
  )
}
