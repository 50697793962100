const StudentRoutes = {
  student: {
    dashboard: '/student/planner',
    schedules: '/student/planner/schedules',
    noSchedules: '/student/planner/schedules/create',
    schedule: '/student/planner/schedules/:id',
    courseRequests: {
      index: '/student/course-requests',
    },
    registration: {
        preferences: {
          survey: '/student/registration/:id/preferences/survey',
        },
    }
  },
};

const AdminRoutes = {
  admin: {
    overview: {
      index: '/overview',
      courses: {
        index: '/courses',
        show: '/courses/:id',
      },
      students: {
        profile: '/students/:id/profile',
        index: '/students',
        show: '/students/:id',
        courseRequests: {
          index: "/students/:id/course-requests",
        }
      },
      reports: '/reports',
    },
    invitations: {
      index: "/invitations/*",
      students: {
        index: '/students'
      },
    },
    manage: {
      index: '/manage/*',
      courses: {
        create: '/courses/create',
        index: '/courses',
        show: '/courses/:id',
      },
      degrees: {
        index: '/degrees/*',
        show: '/degrees/:id',
        schedule: '/admin/manage/degrees/:id/schedule',
        requirements: {
          index: "/admin/manage/degrees/:degreeId/requirements",
          create: '/admin/manage/degrees/:degreeId/requirements/create',
          show: '/admin/manage/degrees/:degreeId/requirements/:requirementId',
          update: '/admin/manage/degrees/:degreeId/requirements/:requirementId/update',
        },
      },
    },
    academicYears: {
      index: '/academic-years',
      show: '/academic-years/:id',
      create: '/academic-years/create',
    },
  },
};

const AuthRoutes = {
  auth: {
    login: '/login',
    register: '/register',
  },
};

const UserRoutes = {
  user: {
    profile: '/user/profile',
  },
};

export const Routes = {
  ...AdminRoutes,
  ...AuthRoutes,
  ...StudentRoutes,
  ...UserRoutes,
};

export const buildRoute = (route: string, param: string | number) => {
  return route.replace(':id', param.toString());
};
