import { AxiosPromise } from 'axios';
import client from '../../axios.client';
import { IRangeFilter } from '../../../domains/admin/pages/common/filters/students/reducer';
import { IFilter } from '../../../types/filter';

export interface ICourseFilterResponse {
  cohorts: IFilter[];
  grades: IFilter[];
  percentCompleted: IRangeFilter;
  percentInProgress: IRangeFilter;
  percentPlanned: IRangeFilter;
}

export interface ICourseFiltersPayload {
  sort: string;
  grades: number[];
  requirementCategories: number[];
  availableYears: number[];
  availableTerms: number[];
}

export interface ICourseFiltersAPI {
  getFilters(
    payload: ICourseFiltersPayload
  ): AxiosPromise<ICourseFilterResponse>;
}

class CourseFiltersAPI implements ICourseFiltersAPI {
  getFilters(
    payload: ICourseFiltersPayload
  ): AxiosPromise<ICourseFilterResponse> {
    return client.post(`/api/course/filters`, {
      ...payload,
    });
  }
}

export const courseFiltersAPI = new CourseFiltersAPI();
