import React from 'react';
import { AuthHeader } from '../../../../shared/header';

export const Header: React.FC = () => {
  return (
    <AuthHeader
      includeLogo={false}
      title={'Nice to meet you'}
      subtitle={'let’s get to know each other a little better'}
    />
  );
};
