import { combineReducers } from 'redux';

import { degreeRequirementShowReducer as show } from './show/reducer';
import { degreeRequirementEditReducer as update } from './edit/reducer';
import { createRequirementReducer as create } from './create/reducer';
import { modalReducer as modals } from './modals/reducer';

export const degreeRequirementReducer = combineReducers({
  show,
  update,
  create,
  modals
});
