import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Greeting } from '../../../../shared/greeting';
import { TrackList } from './track-list';
import { NoSchedules } from './no-schedules';
import { DashboardTracks } from './tracks';
import { routes as StudentRoutes } from '../../routes';

export const Planner: React.FC = () => {
  return (
    <>
      <Greeting />
      <TrackList />
      <section className={'bg-white rounded-sm p-5'}>
        <Routes>
          <Route
            path={StudentRoutes.planner.schedules.create}
            element={<NoSchedules />}
          />
          <Route
            path={StudentRoutes.planner.schedules.show}
            element={<DashboardTracks />}
          />
        </Routes>
      </section>
    </>
  );
};
