import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { IDegreeRequirement } from '../admin/degrees';
import { ISelectedDegreeRequirementCourse } from '../../domains/admin/pages/manage/degrees/requirement/types';

export interface IDegreeRequirementPayload {
  degreeId: number;
  name: string;
  type: number;
  numberRequired: number;
  color: string;
  courses: ISelectedDegreeRequirementCourse[];
  fallbackRequirementId: number|null;
  fallbackRequirement: {
    id: number|null;
    name: string
  };
}

export interface IDegreeRequirementsResponse extends IDegreeRequirementPayload {
  id: number;
  typeLabel: string;
}

export interface IUpdateDegreeRequirementPayload extends IDegreeRequirementPayload {
  requirementId: number;
}

export interface IAvailableDegreeCourse {
  id: number;
  name: string;
  code: string;
  credits: number;
}

export interface IAvailableDegreeCoursesParams {
  degreeId: number;
  search?: string;
}

export interface IDegreeAPI {
  getDegreeRequirements(degreeId: number): AxiosPromise<IDegreeRequirementsResponse[]>;
  getDegreeRequirement(degreeId: number, requirementId: number): AxiosPromise<IDegreeRequirementsResponse>;
  createDegreeRequirement(payload: IDegreeRequirementPayload): AxiosPromise<IDegreeRequirement>;
  updateDegreeRequirement(payload: IUpdateDegreeRequirementPayload): AxiosPromise<IDegreeRequirement>;
  destroyDegreeRequirement(degreeId: number, requirementId: number): AxiosPromise<any>;
  getAvailableCourses(params: IAvailableDegreeCoursesParams): AxiosPromise<IAvailableDegreeCourse[]>;
}

class DegreeAPI implements IDegreeAPI {

  getDegreeRequirements(degreeId: number): AxiosPromise<IDegreeRequirementsResponse[]> {
    return client.get(`/api/degree/${degreeId}/requirements`);
  }
  getAvailableCourses(params: IAvailableDegreeCoursesParams): AxiosPromise<IAvailableDegreeCourse[]> {
    return client.get(`/api/degree/${params.degreeId}/requirements/courses/available`, {
      params: {
        search: params.search
      }
    });
  }

  createDegreeRequirement(payload: IDegreeRequirementPayload): AxiosPromise<IDegreeRequirement> {
    return client.post(`/api/degree/${payload.degreeId}/requirements`, {
      name: payload.name,
      type: payload.type,
      numberRequired: payload.numberRequired,
      color: payload.color,
      courses: payload.courses,
      fallbackRequirementId: payload.fallbackRequirementId ?? null
    });
  }

  getDegreeRequirement(degreeId: number, requirementId: number): AxiosPromise<IDegreeRequirementsResponse> {
    return client.get(`/api/degree/${degreeId}/requirements/${requirementId}`);
  }

  updateDegreeRequirement(payload: IUpdateDegreeRequirementPayload): AxiosPromise<IDegreeRequirement> {
    return client.put(`/api/degree/${payload.degreeId}/requirements/${payload.requirementId}`, {
      name: payload.name,
      type: payload.type,
      numberRequired: payload.numberRequired,
      color: payload.color,
      courses: payload.courses,
      fallbackRequirementId: payload.fallbackRequirementId ?? null
    });
  }

  destroyDegreeRequirement(degreeId: number, requirementId: number): AxiosPromise<any> {
    return client.delete(`/api/degree/${degreeId}/requirements/${requirementId}`);
  }

}

export const degrees = new DegreeAPI();
