import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { ICreateSchedulePayload } from '../../../../../../api/schedules';
import { ISeasonSelect } from '../edit-track/reducer';
import { Routes } from '../../../../../../common/routes';
import GoogleAnalytics from '../../../../../../analytics';
import { Season } from '../../../../../../constants';

const close: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.create.done),
    map(() => actions.close)
  );

const open: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.open),
    mergeMap(() => of(actions.fetchConfiguration(), actions.fetchMajors()))
  );

const fetchConfiguration: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetchConfiguration),
    map(() => actions.loadConfiguration.start())
  );

const loadConfiguration: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadConfiguration.start),
    switchMap(() =>
      from(
        api.schools.getSchoolConfiguration(
          state$.value.common.user.info.schoolId
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadConfiguration.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.loadConfiguration.error(error)).pipe(concat(source$))
    )
  );

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(() => {
      const { seasons, name, majors, minors } =
        state$.value.pages.dashboard.modals.createTrack.form;

      const payload: ICreateSchedulePayload = {
        name: name,
        major_id: majors.map((m: number) => m.toString()),
        minor_id: minors.map((m: number) => m.toString()),
        available_seasons: seasons.filter((season: ISeasonSelect) => season.checked).map((season: ISeasonSelect) => season.value),
      };

      return from(api.schedules.createSchedule(payload)).pipe(
        mergeMap(({ data }) => {
          GoogleAnalytics.Schedule.createScheduleEvent({
            scheduleId: data.id,
          });
          window.location.href = `${Routes.student.schedules}/${data.id}`;
          return of(actions.create.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.create.error(error.response.data)).pipe(concat(source$))
    )
  );

const fetchMajors: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetchMajors),
    map(() => actions.loadMajors.start())
  );

const loadMajors: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadMajors.start),
    switchMap(() => {
      const { user } = state$.value.common;
      return from(api.majors.getMajorsBySchoolId(user.info.schoolId)).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadMajors.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.loadMajors.error(error)).pipe(concat(source$))
    )
  );

const seasonSelect: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.season.select),
    switchMap(({ payload }) => {
      let seasons =
        state$.value.pages.dashboard.modals.createTrack.form.seasons;

      console.log(payload);
      seasons = seasons.map((season: ISeasonSelect) => {
        if (season.value == payload) {
          return {
            ...season,
            checked: !season.checked,
          };
        }
        return season;
      });

      return of(actions.season.set(seasons));
    }),
    catchError((error, source$) =>
      of(actions.loadMajors.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  create,
  fetchConfiguration,
  close,
  open,
  loadConfiguration,
  fetchMajors,
  loadMajors,
  seasonSelect
);
