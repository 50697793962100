import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  displaySuccessScreen: boolean;
  email: string;
  isOpen: boolean;
  isLoading: boolean;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  displaySuccessScreen: false,
  isOpen: false,
  isLoading: false,
  email: '',
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  displaySuccessScreen: true,
  isLoading: false,
}));

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
  email: '',
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  email: '',
}));

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  email: payload,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.reset, (state) => ({
  ...state,
  displaySuccessScreen: false,
  email: '',
}));

export const forgotPasswordModalReducer = reducer;
