import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../infrastructure/selector';
import { api } from '../../../api';

const apply: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.apply),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { grades, requirementCategories, availableTerms, availableYears } =
        state$.value.common.filters.courseBin.filters;
      const scheduleId =
        state$.value.pages.admin.dashboard.student.schedule.schedule?.id ||
        state$.value.pages.dashboard.currentTrack.schedule?.id ||
        state$.value.common.user.info.activeScheduleId;

      return from(
        api.schedules.getCourseBinFilters({
          scheduleId,
          grades,
          requirementCategories,
          availableTerms,
          availableYears,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, apply);
