import { combineEpics } from 'redux-observable';
import { epic as schedules } from './schedules/epic';
import { epic as dashboard } from '../domains/student/pages/planner/epic';
import { epic as admin } from '../domains/admin/pages/epic';
import { epic as auth } from '../domains/auth/epic';
import { epic as studentProfile } from "./student-profile/epic";
import { epic as profile } from "./profile/epic";
import { epic as courseRequests } from "../domains/student/pages/registration/course-requests/epic";

export default combineEpics(dashboard, schedules, admin, auth, studentProfile, profile, courseRequests);
