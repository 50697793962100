import React from 'react';
import { CoursePreferences } from '../course-preferences';
import { PreferencesCourseBin } from '../course-bin';
import { ICourseRequest, ICourseRequestGroup } from '../../../../../../api/registration-periods';
import { ICourse } from '../../../../../../types/course';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

export interface IDragAndDropPreferencesProps {
  requestGroups: ICourseRequestGroup[];
  courseList: ICourse[];
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getDroppableZones = (requests: ICourseRequest[]) => {
  const preferenceMax = 3;
  console.log('requests', requests);
  // TODO: Typing
  let droppableZones: any[] = requests.map((request, index) => {
    return {
      ...request,
      id: index.toString(),
      placeholder: false,
    }
  });
  for(let i = 0; i < preferenceMax - requests.length; i++) {
    droppableZones.push({
      id: i.toString(),
      placeholder: true,
      groupId: 0,
      courseRequest: [
        {
          courseName: '',
          courseCode: '',
          courseCredits: 0,
          courseId: 0,
          priority: i,
          studentId: 0,
          placeholder: true,
        }
      ]
    });
  }
  return droppableZones;
}

const getDroppableZoneGroups = (groups: any[]) => {
  console.log(groups);
  return groups.map((group, index) => {
    return getDroppableZones(group.courseRequest);
  });
}

export const DragAndDropPreferences: React.FC<IDragAndDropPreferencesProps> = ({ requestGroups, courseList }) => {

  const generatedDroppableZoneGroups = getDroppableZoneGroups(requestGroups);
  const [droppableZoneGroups, setDroppableZoneGroups] = React.useState(generatedDroppableZoneGroups);

  const onDragEnd = (result: DropResult) => {
    console.log('droppable zones', droppableZoneGroups);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      droppableZoneGroups,
      parseInt(result.source.droppableId),
      parseInt(result.destination.droppableId)
    );

    setDroppableZoneGroups(newItems);
  }

  return (
    <>
      <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
        <div className={"flex justify-between"}>
          <div className={"w-7/12"}>
            <CoursePreferences onDragEnd={(result: DropResult) => onDragEnd(result)} groups={droppableZoneGroups ?? []} />
          </div>
          <div className="w-5/12">
            <PreferencesCourseBin list={courseList ?? []} />
          </div>
        </div>
      </DragDropContext>
    </>
  )
}
