import React, {useEffect, useState} from 'react';
import { SearchInput } from '../../../../../../components/search-input';
import { SimpleAction } from '../../../../../../components/simple-action';
import { FaPlus } from 'react-icons/fa6';
import { IoCalendarOutline } from 'react-icons/io5';
import { LiaSchoolSolid } from 'react-icons/lia';
import { useQuery, useQueryClient } from 'react-query';
import {api} from "../../../../../../api";
import {useSelector} from "../../../../../../infrastructure/selector";
import {shallowEqual} from "react-redux";
import {ICourse} from "../../../../../../types/course";
import {CourseList} from "../../../../../../components/course-list/list";
import { CourseCard } from '../../../../../../components/course-card';
import Skeleton from 'react-loading-skeleton';
import { TermPills } from './term-pills';

export interface ICoursesProps {
  isDisabled?: boolean;
  handleModalOpen: () => any;
}

const useGetAcademicYearCourses = (schoolId: number, academicYearId: number, termId: number, isDisabled: boolean) => {
  return useQuery(`academicYearCourses-${academicYearId}-${termId}`, () => api.academicYear.getCoursesByAcademicYearId(schoolId, academicYearId, termId), {
    enabled: !!schoolId && !!academicYearId && !isDisabled,
  });
}

export const Courses: React.FC<ICoursesProps> = ({ isDisabled, handleModalOpen }) => {
    const queryClient = useQueryClient();
    const [search, setSearch] = useState<string>("");
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [selectedTermId, setSelectedTermId] = useState(0);

    const { schoolId, academicYearId } = useSelector(
        (state) => ({
            academicYearId: state.common.match.params.id,
            schoolId: state.common.configs.configuration.schoolId,
        }),
        shallowEqual
    );

    const { data, isLoading, error } = useGetAcademicYearCourses(schoolId, academicYearId, selectedTermId, !!isDisabled);

    const handleTermPillSelect = (term: any) => {
        setSelectedTermId(term?.id ?? 0);
    }

  useEffect(() => {
      queryClient.invalidateQueries(`academicYearCourses-${academicYearId}-${selectedTermId}`);
  }, [selectedTermId]);

    useEffect(() => {
        if(search.length) {
            const filtered = data?.data?.filter((course: ICourse) => course.name.toLowerCase().includes(search.toLowerCase())) ?? [];
            setCourses(filtered);
        } else {
            setCourses(data?.data || []);
        }
    }, [search]);

    useEffect(() => {
        setCourses(data?.data || []);
    }, [data?.data])

  return (
    <>
      <div className={"flex items-center justify-between"}>
        <h3 className={"text-lg text-neutral-darkest mb-2"}>Courses</h3>
        {!isDisabled && <SimpleAction
          disabled={isDisabled}
          onClick={() => handleModalOpen()}
          icon={FaPlus}
          label={"Add Course"}
        />}
      </div>
      <hr className={"border-b border-neutral-lighter mb-4"}/>
      <section>

        {/*<div className={"flex items-center justify-between"}>*/}
        {/*  <div>*/}
        {/*    <SearchInput placeholder={"Enter course name..."} handleOnChange={(s) => false} />*/}
        {/*  </div>*/}
        {/*  <div className={"flex items-center justify-between"}>*/}
        {/*    <SimpleAction*/}
        {/*      disabled={isDisabled}*/}
        {/*      onClick={() => handleModalOpen()}*/}
        {/*      icon={FaPlus}*/}
        {/*      label={"Add Course"}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {!isDisabled && <section className={""}>
         <TermPills onPillSelect={(term) => handleTermPillSelect(term)} />
        </section>}

          {!isDisabled && <section className={"mb-4"}>
            <SearchInput placeholder={"Search courses by name"} handleOnChange={(e) => setSearch(e.target.value)} />
        </section>}

        {!courses.length && !isLoading && <div className={"flex items-center justify-center"}>
          <div className={"text-center flex flex-col justify-center items-center"}>
            <LiaSchoolSolid className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
            <h3 className={"text-black font-semibold"}>No Courses</h3>
            <p className={"text-neutral-dark text-sm"}>You have not add any courses to this school year</p>
            <div className={"mt-4"}>
              <SimpleAction disabled={isDisabled} icon={FaPlus} label={"Add Courses"} onClick={() => handleModalOpen()} />
            </div>
          </div>
        </div>}

        {isLoading && (<>
          <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
          <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
          <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
          <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
        </>)}

        <section className={"flex items-center overflow-x-auto w-full pb-4 max-w-full  mb-8"}>
          {!!courses.length && !isDisabled && courses.map((course: ICourse) => {
            const color = course?.requirementCategories && course.requirementCategories[0]?.color;
            return (
              <div className={'w-2/12 mr-3 min-w-fit flex-grow-0 flex-shrink-0'}>
                <div className={"bg-neutral-lightest p-4 rounded-md cursor-pointer hover:bg-neutral-lighter border-l-4"} style={{ borderColor: color }}>
                  <h4 className={"text-neutral-darkest text-sm font-semibold"}>{course.name}</h4>
                  <span className={"text-neutral text-xxs font-semibold block mb-1"} style={{ color: course?.requirementCategories && course.requirementCategories[0]?.color }}>{course?.requirementCategories && course.requirementCategories[0]?.name}</span>
                  <span className={"text-neutral-dark text-xxs font-medium block"}>{course.code}</span>
                  <span className={"text-neutral text-xxs font-semibold block"}>Units: {course.credits}</span>
                </div>
              </div>
            )
          })}
        </section>

      </section>
    </>
  )
}
