import React, { useEffect } from 'react';
import { SelectField } from '../../../../../../ui';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { ICourse } from '../../../../../../types/course';

export const CoursePrerequisiteRequirementsField: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, selected, list, courseIsLoading } = useSelector(
    (state) => ({
      list: state.pages.admin.settings.course.prerequisites.list,
      selected: state.pages.admin.settings.course.prerequisites.selected,
      courseIsLoading: state.pages.admin.settings.course.details.isLoading,
      isLoading: state.pages.admin.settings.course.prerequisites.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (isLoading || courseIsLoading) {
    return null;
  }

  if (!list.length) {
    return null;
  }

  return (
    <div className={'w-6/12 mr-5'}>
      <h3 className={'font-semibold text-neutral-darkest mt-3'}>
        Prerequisite Course Requirements
      </h3>
      <span className={'text-neutral text-xs'}>
        These are courses that are required to be taken in before this course
        can be taken
      </span>

      <SelectField
        defaultValue={selected}
        onChange={(options) => dispatch(actions.onChange(options))}
        options={list.map((course: ICourse) => ({
          label: course.code + ' - ' + course.name,
          value: course.id,
        }))}
        error={{ errors: {}, message: '' }}
        multiple
        name={'prerequisites'}
      />
    </div>
  );
};
