import { createReducer } from 'redux-act';
import * as actions from '../actions';
import * as infoActions from './actions';
import { ISelectOption } from '../../../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import { IDegreeRequirementsResponse } from '../../../../../../../../api/degree';

interface IState {
  isLoading: boolean;
  name: string;
  type: ISelectOption;
  numberRequired: number|null;
  color: string;
  error: IValidationErrorResponse;
  fallbackRequirementId: number|null;
  fallbackRequirementOptions: IDegreeRequirementsResponse[];
  fallbackRequirement: {
    id: number|null;
    name: string;
  };
}

const initialState: IState = {
  isLoading: false,
  name: "",
  type: {
    label: "Classes",
    value: DegreeRequirementUnitType.Courses.toString()
  },
  numberRequired: null,
  color: "#000",
  error: {errors: {}, message: ""},
  fallbackRequirementId: null,
  fallbackRequirementOptions: [],
  fallbackRequirement: {
    id: null,
    name: ""
  }
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.save.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    name: payload.name,
    type: {
      label: payload.typeLabel,
      value: payload.type.toString()
    },
    numberRequired: payload.numberRequired,
    color: payload.color,
    fallbackRequirementId: payload.fallbackRequirement.id,
    fallbackRequirement: {
      id: payload.fallbackRequirement.id,
      name: payload.fallbackRequirement.name
    }
  };
});

reducer.on(infoActions.load.done, (state, payload) => {
  return {
    ...state,
    fallbackRequirementOptions: payload
  };
});

reducer.on(infoActions.onChange, (state, payload) => ({
  ...state,
  [payload.key]: payload.value,
  error: { errors: {}, message: "" }
}));

reducer.on(infoActions.onTypeChange, (state, payload) => ({
  ...state,
  type: payload,
  error: { errors: {}, message: "" }
}));

reducer.on(infoActions.onColorChange, (state, payload) => ({
  ...state,
  color: payload,
  error: { errors: {}, message: "" }
}));

export const degreeRequirementInfoReducer = reducer;
