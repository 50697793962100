import { AxiosPromise } from 'axios';
import client from '../axios.client';
import moment from 'moment';
import { IValidationErrorResponse } from '../../types/error';
import { ICourse } from '../../types/course';

export interface IAcademicYear {
  id: number;
  label: string;
  endDate: string;
  startDate: string;
}

export interface ICreateAcademicYearRequest {
  schoolId: number;
  name: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IAddTermToAcademicYearRequest {
  schoolId: number;
  academicYearId: number;
  label: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IAcademicYearTerm {
  id: number;
  label: string;
  startDate: string;
  endDate: string;
}

export interface IUpdateAcademicYearRequest {
    schoolId: number;
    academicYearId: number;
    name: string;
    startDate: Date | null;
    endDate: Date | null;
}

export interface IUpdateAcademicYearTermRequest {
    schoolId: number;
    academicYearId: number;
    termId: number;
    label: string;
    startDate: Date | null;
    endDate: Date | null;
}

export interface IAcademicYearAPI {
  createAcademicYear(request: ICreateAcademicYearRequest): AxiosPromise<IAcademicYear>;
  getAcademicYearById(schoolId: number, academicYearId: number): AxiosPromise<IAcademicYear>;
  addTermToAcademicYear(request: IAddTermToAcademicYearRequest): AxiosPromise<any>;
  getAcademicYearTermsByAcademicYearId(schoolId: number, academicYearId: number): AxiosPromise<IAcademicYearTerm[]>;
  addCoursesToAcademicYear(schoolId: number, academicYearId: number, courses: number[]): AxiosPromise<any>;
  getCoursesByAcademicYearId(schoolId: number, academicYearId: number, termId: number): AxiosPromise<any>;
  updateAcademicYear(request: ICreateAcademicYearRequest): AxiosPromise<IAcademicYear>;
  updateAcademicYearTerm(request: IAddTermToAcademicYearRequest): AxiosPromise<any>;
  destroyAcademicYearTerm(schoolId: number, academicYearId: number, termId: number): AxiosPromise<any>;
  addCoursesToAcademicYearTerm(schoolId: number, academicYearId: number, termId: number, courses: number[]): AxiosPromise<any>;
  getCoursesByAcademicYearTermId(schoolId: number, academicYearId: number, termId: number): AxiosPromise<ICourse[]>;
}

class AcademicYearAPI implements IAcademicYearAPI {
  createAcademicYear(
    request: ICreateAcademicYearRequest
  ): AxiosPromise<IAcademicYear> {
    return client.post(`/api/schools/${request.schoolId}/academic-years`, {
      label: request.name,
      startDate: moment(request.startDate).format("YYYY-MM-DD"),
      endDate: moment(request.endDate).format("YYYY-MM-DD"),
    });
  }

  getAcademicYearById(schoolId: number, academicYearId: number): AxiosPromise<IAcademicYear> {
    return client.get(`/api/schools/${schoolId}/academic-years/${academicYearId}`);
  }

  addTermToAcademicYear(
    request: IAddTermToAcademicYearRequest
  ): AxiosPromise<any> {
    return client.post(`/api/schools/${request.schoolId}/academic-years/${request.academicYearId}/terms`, {
      label: request.label,
      startDate: moment(request.startDate).format('YYYY-MM-DD'),
      endDate: moment(request.endDate).format('YYYY-MM-DD'),
    });
  }

    updateAcademicYearTerm(
        request: IUpdateAcademicYearTermRequest
    ): AxiosPromise<any> {
        return client.put(`/api/schools/${request.schoolId}/academic-years/${request.academicYearId}/terms/${request.termId}`, {
          label: request.label,
          startDate: moment(request.startDate).format('YYYY-MM-DD'),
          endDate: moment(request.endDate).format('YYYY-MM-DD'),
        });
    }

  getAcademicYearTermsByAcademicYearId(schoolId: number, academicYearId: number): AxiosPromise<IAcademicYearTerm[]> {
    return client.get(`/api/schools/${schoolId}/academic-years/${academicYearId}/terms`);
  }

  addCoursesToAcademicYear(schoolId: number, academicYearId: number, courses: number[]): AxiosPromise<any> {
    return client.post(`/api/schools/${schoolId}/academic-years/${academicYearId}/courses`, {
      courses: courses,
    });
  }

  getCoursesByAcademicYearId(schoolId: number, academicYearId: number, termId: number = 0): AxiosPromise<ICourse[]> {
    if(termId === 0) {
      return client.get(`/api/schools/${schoolId}/academic-years/${academicYearId}/courses`);
    } else {
      return client.get(`/api/schools/${schoolId}/academic-years/${academicYearId}/terms/${termId}/courses`);
    }
  }

    updateAcademicYear(
        request: IUpdateAcademicYearRequest
    ): AxiosPromise<IAcademicYear> {
        return client.put(`/api/schools/${request.schoolId}/academic-years/${request.academicYearId}`, {
        label: request.name,
        startDate: moment(request.startDate).format("YYYY-MM-DD"),
        endDate: moment(request.endDate).format("YYYY-MM-DD"),
        });
    }

    destroyAcademicYearTerm(schoolId: number, academicYearId: number, termId: number): AxiosPromise<any> {
        return client.delete(`/api/schools/${schoolId}/academic-years/${academicYearId}/terms/${termId}`);
    }

    addCoursesToAcademicYearTerm(schoolId: number, academicYearId: number, termId: number, courses: number[]): AxiosPromise<any> {
        return client.post(`/api/schools/${schoolId}/academic-years/${academicYearId}/terms/${termId}/courses`, {
            courseIds: courses,
        });
    }

    getCoursesByAcademicYearTermId(schoolId: number, academicYearId: number, termId: number): AxiosPromise<ICourse[]> {
        return client.get(`/api/schools/${schoolId}/academic-years/${academicYearId}/terms/${termId}/courses`);
    }
}

export const academicYear = new AcademicYearAPI();
