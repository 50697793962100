import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import * as requirementActions from "../actions";
import { IEpic } from '../../../../../../../../infrastructure/selector';
import { from, of } from 'rxjs';
import { api } from '../../../../../../../../api';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const saveOnChange: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onChange),
    map(() => requirementActions.save.start())
  );

const saveOnTypeChange: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onTypeChange),
    map(() => requirementActions.save.start())
  );

const saveOnColorChange: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onColorChange),
    map(() => requirementActions.save.start())
  );

const onError: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(requirementActions.load.error),
    map(() => requirementActions.save.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = parseInt(state$.value.common.match.params.requirementId);

      return from(
        api.degrees.getDegreeRequirements(degreeId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data.filter((r) => r.id !== requirementId)))
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.load.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

export const epic = combineEpics(fetch, load, saveOnChange, saveOnTypeChange, saveOnColorChange);
