import React from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { DegreeProgressBar } from '../../../../../components/degree-progress-bar';

export const DegreeProgress: React.FC = () => {
  const { unplanned, planned, completed, unitsRequired, schoolName } = useSelector(
    (state) => ({
      unitsRequired: state.pages.dashboard.progress.unitsRequired,
      planned: state.pages.dashboard.progress.planned,
      unplanned: state.pages.dashboard.progress.unplanned,
      completed: state.pages.dashboard.progress.completed,
      schoolName: state.common.configs.configuration.account.name,
    }),
    shallowEqual
  );

  return (
    <div>
      <div className={'border-b border-neutral-light pb-4 mb-12'}>
        <h3 className={'text-xl text-neutral-darkest'}>{schoolName} Degree Progress</h3>
        <span className={'text-xs text-neutral'}>
          Estimated based off this track
        </span>
      </div>
      <DegreeProgressBar
        unplanned={unplanned}
        planned={planned}
        completed={completed}
        required={unitsRequired}
      />
    </div>
  );
};
