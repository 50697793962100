import { createAction } from 'redux-act';
import { ICourseResponse } from '../../../../../../api/admin/courses';
import { ISelectOption } from '../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>
  | ReturnType<typeof update.start>
  | ReturnType<typeof update.start>
  | ReturnType<typeof update.start>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/details] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/details] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/details] load.error'
  ),
  done: createAction<ICourseResponse>(
    '[pages/admin/dashboard/settings/course/details] load.done'
  ),
};

export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>('[pages/admin/dashboard/settings/course/details] onChange');
export const onTermsOfferedChange = createAction<ISelectOption[]>(
  '[pages/admin/dashboard/settings/course/details] onTermsOfferedChange'
);
export const onGradeLevelChange = createAction<ISelectOption[]>(
  '[pages/admin/dashboard/settings/course/details] onGradeLevelChange'
);

export const update = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/details] update.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/course/details] update.error'
  ),
  done: createAction<ICourseResponse>(
    '[pages/admin/dashboard/settings/course/details] update.done'
  ),
};
