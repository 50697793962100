import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(() => {
      const { name, abbreviatedName, type, totalCreditsRequired } =
        state$.value.pages.admin.settings.degrees.create;

      return from(
        api.admin.degrees.createDegree({
          name,
          abbreviatedName,
          type,
          totalCreditsRequired,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.create.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.create.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(create);
