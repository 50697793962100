import React, { useEffect } from 'react';
import { Button, SelectField } from '../../../../../../../ui';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../../ui/button';
import { SelectSize } from '../../../../../../../ui/select';

export const CreateAvailability: React.FC = () => {

  const dispatch = useDispatch();

  const { isLoading, error, availableTerms, availableYears, selectedTerm, selectedYear } = useSelector(
    (state) => ({
      error: state.pages.admin.settings.course.availability.create.error,
      availableTerms: state.pages.admin.settings.course.availability.create.availableTerms,
      availableYears: state.pages.admin.settings.course.availability.create.availableYears,
      isLoading: state.pages.admin.settings.course.concurrency.isLoading,
      selectedTerm: state.pages.admin.settings.course.availability.create.selectedTerm,
      selectedYear: state.pages.admin.settings.course.availability.create.selectedYear,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch())
  }, []);

  return (
    <>
      <div className={"flex items-center mt-4"}>
        <div className={"w-1/2 mr-3"}>
          <SelectField
            isClearable={true}
            placeholder={'Select Year'}
            name={'termYear'}
            label={'Year'}
            onChange={(option) => dispatch(actions.onYearChange(option))}
            error={error}
            size={SelectSize.xs}
            options={availableYears}
          />
        </div>
        <div className={"w-1/2 ml-3"}>
          <SelectField
            isClearable={true}
            placeholder={'Select Term'}
            name={'termSeason'}
            label={'Term'}
            onChange={(option) => dispatch(actions.onTermChange(option))}
            error={error}
            size={SelectSize.xs}
            options={availableTerms}
          />
        </div>
      </div>
      <span className={"text-xs text-red "}>{error.errors.message}</span>
      <Button
        classes={"mt-4"}
        handleOnClick={() => dispatch(actions.create.start())}
        size={ButtonSize.Small}
        style={ButtonStyle.Filled}
        variant={ButtonVariant.Primary}
        isLoading={false}
      >
        + Add Unavailability
      </Button>
    </>
  )
}
