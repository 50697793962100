import { AxiosPromise } from 'axios';
import client from '../../axios.client';

export interface ICoursePreferences {
    survey: () => AxiosPromise<IRegistrationCoursePreferenceSurveyResponse>;
}

export interface IRegistrationCoursePreferenceSurveyResponse {
    data: IRegistrationCoursePreferenceSurvey
}

export interface IRegistrationCoursePreferenceSurvey {
    id: number;
    academicYear: string;
    surveyEndDate: string;
    hasActiveSurvey: boolean;
}


class CoursePreferencesAPI implements ICoursePreferences {
    survey(): AxiosPromise<IRegistrationCoursePreferenceSurveyResponse> {
        return client.get('/api/v1/registration/course-preferences/survey');
    }
}

export const coursePreferences = new CoursePreferencesAPI();
