import Constants, { UserRoleType } from '../constants';
import { ICourse } from '../types/course';
import { DraggableLocation } from 'react-beautiful-dnd';
import { IScheduleTerm } from '../api/schedule-terms';
import { IValidationErrorResponse } from '../types/error';
import { ISelectOption } from '../ui/select';
import { Routes } from '../common/routes';
import { IUser } from '../api/user';
import { ISchedule } from '../api/schedules';
import {api} from "../api";
import { getAdminRoute } from '../domains/admin/routes';

// TODO: Write tests for these...
export const Helpers = {
  move: (
    terms: IScheduleTerm[],
    source: ICourse[],
    destination: ICourse[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // console.log('source clone', sourceClone);
    // console.log('dest clone', destClone);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: { terms: IScheduleTerm[] } = {
      terms: [],
    };

    result.terms = terms.map((term: IScheduleTerm) => {
      if (term.id.toString() === droppableSource.droppableId) {
        term.courses = sourceClone;
      }
      if (term.id.toString() === droppableDestination.droppableId) {
        term.courses = destClone;
      }
      return term;
    });

    return result;
  },

  reorder: (
    list: ICourse[],
    startIndex: number,
    endIndex: number
  ): ICourse[] => {
    // console.log(list);
    // console.log(startIndex);
    // console.log(endIndex);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  },

  getListStyle: (
    isDraggingOver: boolean,
    displayTermCost: boolean = false
  ) => ({
    minHeight: displayTermCost ? '93%' : '96%',
  }),

  getItemStyle: (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: Constants.grid * 2,
    margin: `0 0 10px 0`,
    borderRadius: '4px',
    // minHeight: "110px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // change background colour if dragging
    // background: isDragging ? "#333" : "#555",

    // styles we need to apply on draggables
    ...draggableStyle,
  }),

  calculateCourseCreditTotal: (terms: IScheduleTerm[]): IScheduleTerm[] => {
    // Calculate the total here and return it.
    return terms.map((term): IScheduleTerm => {
      let courseCredits = term.courses.map((course: ICourse) => course.credits);

      let totalCourseCreditsForTerm = 0;

      if (courseCredits.length) {
        totalCourseCreditsForTerm = courseCredits.reduce((n, val) => {
          return n + val;
        });
      }

      term.creditTotal = totalCourseCreditsForTerm;

      return term;
    });
  },

  mapToSelectOptions: function <T>(
    list: T[],
    func: (item: T) => { label: string; value: string }
  ) {
    return list.map((item: T) => func(item));
  },

  mapValuesFromSelectOptions: function <T>(list: ISelectOption[]) {
    return list.map((item: ISelectOption) => item.value as T);
  },

  hasError: (error: IValidationErrorResponse, key: string): boolean => {
    return !!Object.keys(error).length && error.errors.hasOwnProperty(key);
  },

  getCoursesUnitTotal: (courses: ICourse[]): number => {
    return courses
      .map((course: ICourse) => course.credits)
      .reduce((a, b) => a + b, 0);
  },

  redirectToDashboard(user: IUser) {
    if (user.roleId == UserRoleType.Student) {
      if (!!user.activeScheduleId) {
        window.location.href = `${Routes.student.schedules}/${user.activeScheduleId}`;
      } else {
        window.location.href = Routes.student.schedules;
      }
    }
    if (user.roleId == UserRoleType.Administrator) {
      window.location.href = getAdminRoute(Routes.admin.overview.index);
    }
  },

  filterCoursesForCourseBin(schedule: ISchedule, courses: ICourse[]): ICourse[] {

    let currentCoursesInSchedule = schedule.terms
      .map((term: IScheduleTerm) =>
        term.courses.map((course) => course.id)
      )
      .flat();

    console.log(courses);

    return courses.filter(
      (course) => {

        if(course.isRepeatable) {
          return course;
        }
        return !currentCoursesInSchedule.includes(course.id);
      }
    );
  },

  handleLogout() {
    api.auth.logout().then((res) => (window.location.href = Routes.auth.login));
  }
};
