import React from 'react';
import { Link } from 'react-router-dom';
import { getAdminRoute } from '../../routes';
import { Routes } from '../../../../common/routes';
import { FaPlus } from 'react-icons/fa6';
import { Overview } from './overview';
import { Students } from './students';
import { Courses } from './courses';
import { SimpleAction } from '../../../../components/simple-action';
import { Dropdown } from '../../../../components/dropdown';

export const AdminOverview: React.FC = () => {
  return (
    <>
      <>
        <section className={'border-b pb-4 mb-8 flex justify-between items-center'}>
          <div className={"w-1/2"}>
            <h4 className={'text-lg text-neutral-darkest'}>
              You have 0 important items today.
            </h4>
            <span className={'text-xs text-neutral'}>This includes messages, flags, and conflicts</span>
          </div>
          <div className='w-1/2 flex items-center justify-end'>
            {/*<Dropdown renderTarget={() => <div className={"bg-neutral-lightest text-neutral-darkest text-xs"}>2024 - 2025 Academic Year</div>} renderBody={() => <div className={"bg-red text-sm"}>something</div>} />*/}
            <div className={"flex items-center justify-end"}>
              <SimpleAction icon={FaPlus} label={"Add Academic Year"} href={getAdminRoute(Routes.admin.academicYears.create)} />
            </div>
          </div>
        </section>
        <Overview />
        <section>
          <div className={''}>
            {/*<Term />*/}
            <Students />
            <Courses />
          </div>
        </section>
      </>
    </>
  );
};
