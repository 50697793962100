import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { isDisabled } from '@testing-library/user-event/utils/misc/isDisabled';

export interface ISimpleActionProps {
  href?: string;
  onClick?: () => void;
  icon: IconType;
  label: string;
  disabled?: boolean;
}

interface ILinkActionProps {
  href: string;
  icon: IconType;
  label: string;
  containerClassNames: string;
  iconContainerClassNames: string;
  iconClasses: string;
  textClassNames: string;
}

const LinkAction: React.FC<ILinkActionProps> = ({ href, containerClassNames, iconClasses, iconContainerClassNames, icon: Icon, textClassNames, label}) => {
  return (
    <Link to={href} className={containerClassNames}>
      <div className={iconContainerClassNames}>
        <Icon className={iconClasses} />
      </div>
      <span className={textClassNames}>{label}</span>
    </Link>
  )
}

interface IClickActionProps {
  onClick?: () => void;
  icon: IconType;
  label: string;
  containerClassNames: string;
  iconContainerClassNames: string;
  iconClasses: string;
  textClassNames: string;
  disabled: boolean;
}

const ClickAction: React.FC<IClickActionProps> = ({ onClick, disabled, containerClassNames, iconClasses, iconContainerClassNames, icon: Icon, textClassNames, label}) => {
  return (
    <div className={containerClassNames} onClick={() => !!onClick && !disabled && onClick()}>
      <div className={iconContainerClassNames}>
        <Icon className={iconClasses}/>
      </div>
      <span className={textClassNames}>{label}</span>
    </div>
  )
}

export const SimpleAction: React.FC<ISimpleActionProps> = ({ href, icon: Icon, label, onClick, disabled }) => {

  const containerClassNames = classNames("flex items-center justify-end px-2 py-1.5 rounded-md", { 'hover:bg-white': disabled, 'hover:bg-baby-blue-lightest cursor-pointer active:bg-baby-blue-lighter group': !disabled });
  const textClassNames = classNames("text-xs", { 'text-neutral-light': disabled, 'text-baby-blue': !disabled });
  const iconContainerClassNames = classNames("border-dashed border rounded-full flex items-center justify-center w-5 h-5 mr-2", { 'border-baby-blue bg-baby-blue-lightest group-active:bg-baby-blue-lighter': !disabled, 'border-neutral-light bg-neutral-lightest': disabled });
  const iconClasses = classNames("text-baby-blue text-xs", { 'text-neutral-light': disabled });

  if(!!href) return (
    <LinkAction href={href} label={label} containerClassNames={containerClassNames} iconContainerClassNames={iconContainerClassNames} iconClasses={iconClasses} textClassNames={textClassNames} icon={Icon} />
  )

  return (
    <ClickAction onClick={onClick} disabled={!!disabled} label={label} containerClassNames={containerClassNames} iconContainerClassNames={iconContainerClassNames} iconClasses={iconClasses} textClassNames={textClassNames} icon={Icon}/>
  )
}

export const SimpleDangerAction: React.FC<ISimpleActionProps> = ({ href, icon: Icon, label, onClick, disabled }) => {

  const containerClassNames = classNames("flex items-center justify-end px-2 py-1.5 rounded-md", { 'hover:bg-white': disabled, 'hover:bg-red-lightest cursor-pointer': !disabled });
  const textClassNames = classNames("text-xs", { 'text-neutral-light': disabled, 'text-red': !disabled });
  const iconContainerClassNames = classNames("border-dashed border rounded-full flex items-center justify-center w-5 h-5 mr-2", { 'border-red bg-red-lightest': !disabled, 'border-neutral-light bg-neutral-lightest': disabled });
  const iconClasses = classNames("text-xs", { 'text-red': !disabled, 'text-neutral-light': disabled });


  if(!!href) return (
    <LinkAction href={href} label={label} containerClassNames={containerClassNames} iconContainerClassNames={iconContainerClassNames} iconClasses={iconClasses} textClassNames={textClassNames} icon={Icon} />
  )

  return (
    <ClickAction onClick={onClick} disabled={!!disabled} label={label} containerClassNames={containerClassNames} iconContainerClassNames={iconContainerClassNames} iconClasses={iconClasses} textClassNames={textClassNames} icon={Icon}/>
  )


}
