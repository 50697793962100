import React, {useState} from "react";
import {MdClose} from "@react-icons/all-files/md/MdClose";
import {Button} from "../../../../../ui";
import {ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant} from "../../../../../ui/button";
import {CoursePreferences} from "./course-preferences";
import {PreferencesCourseBin} from "./course-bin";
import {ICourse} from "../../../../../types/course";
import { useQuery } from 'react-query';
import { api } from '../../../../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { courses } from '../../../../../api/courses';
import { DragAndDropPreferences } from './drag-and-drop-preferences';

export interface IActivePreference {
    requirementCategoryLabel: string;
    label: string;
    choice: number;
    course: ICourse | null;
}

export const CoursePreferenceSurvey: React.FC = () => {

    const {
        studentId,
        match
    } = useSelector(
      (state) => ({
          studentId: state.common.user.info.id,
          match: state.common.match,
      }),
      shallowEqual
    );

    const { id } = match.params;

    const { data, isLoading, error } = useQuery(`coursePreferenceSurvey-${studentId}-${id}`, () => api.registrationPeriods.getCourseRequestSubmission(id, studentId));
    let getCourses = courses.getCoursesBySchool({
        schoolId: 4,
        majorIds: [],
        minorIds: [],
        filters: {
            grade: []
        }
    }).then(res => res.data);
    const { data: list} = useQuery('[pages/registration/course-preference-survey/course-bin/courses]', () => getCourses);

    const [activePreference, setActivePreference] = useState<IActivePreference|undefined>();

    const handlePreferenceSelect = (preference: IActivePreference) => {
        setActivePreference(preference);
    }

    const courseRequestCourses = data?.data.courseRequestGroups
      .map((group) => group.courseRequest)
      .flatMap((courseRequest) => courseRequest.map((request) => request.courseId)
      .flat());

    // @ts-ignore
    let courseList: ICourse[] = list?.filter((course) => !courseRequestCourses?.includes(course.id));

    return (
            <section className={'relative'}>
                <div className={"bg-white p-7"}>
                    <div className={"pb-4"}>
                        <MdClose className={"fill-black"} style={{ fontSize: "28px" }}/>
                    </div>

                    <div className={"mb-8"}>
                        <h1 className={"text-xl text-black font-semibold border-b pb-4"}>Please Enter Your 2024-2025 Class Preferences</h1>
                    </div>

                    <DragAndDropPreferences requestGroups={data?.data.courseRequestGroups ?? []} courseList={courseList ?? []} />

                    <section className={"flex items-center w-1/6 mt-6"}>
                        <div className="w-1/2 mr-4">
                            <Button
                                style={ButtonStyle.Outline}
                                size={ButtonSize.XSmall}
                                variant={ButtonVariant.Primary}
                                radius={ButtonBorderRadius.FULL}
                            >Save</Button>
                        </div>
                        <div className="w-1/2">
                            <Button
                                style={ButtonStyle.Filled}
                                size={ButtonSize.XSmall}
                                variant={ButtonVariant.Primary}
                                radius={ButtonBorderRadius.FULL}
                            >Submit</Button>
                        </div>
                    </section>
                </div>
            </section>
    )
}
