import React from 'react';
import { IValidationErrorResponse } from '../../types/error';
import { Helpers } from '../../helpers';

export interface ITextAreaProps {
  handleOnChange: (e: any) => any;
  value?: string;
  placeholder?: string;
  name: string;
  renderLabel?: React.FC;
  minHeight?: number;
  variant?: TextAreaVariant;
  error?: IValidationErrorResponse;
}

export enum TextAreaVariant {
  Neutral,
  White
}

export const Textarea: React.FC<ITextAreaProps> = ({
  name,
  variant,
  handleOnChange,
  value,
  placeholder,
  error,
  renderLabel: Label,
  ...props
}) => {
  let styles =
    'w-full border border-neutral-light rounded-lg text-neutral-dark p-3 text-sm';

  const hasError = () => {
    return !!error && Helpers.hasError(error, name);
  };

  if (hasError()) {
    styles =
      'text-sm text-neutral-dark p-3 border border-red rounded-xl w-full';
  }

  if (variant === TextAreaVariant.Neutral) {
    styles += ' bg-neutral-lighter text-neutral-dark';
  } else if (variant === TextAreaVariant.White) {
    styles += ' bg-white text-neutral-dark border border-neutral-dark';
  }
  else {
    styles += ' text-neutral-dark border border-neutral-light';
  }

  return (
    <>
      {!!Label && <Label />}
      <textarea
        {...props}
        onChange={(e) => handleOnChange(e)}
        value={value}
        placeholder={placeholder}
        name={name}
        className={styles}
        style={{ height: 150 }}
      />
      {hasError() && (
        <span className={'text-red text-xs'}>{error?.errors[name]}</span>
      )}
    </>
  );
};

Textarea.defaultProps = {
  error: { errors: {}, message: '' },
  minHeight: 150,
  variant: TextAreaVariant.Neutral,
  placeholder: '',
};
