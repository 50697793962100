import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../../ui';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../ui/button';
import { Link } from 'react-router-dom';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { IDegree } from '../../../../../../api/admin/degrees';
import { AdminCreateDegree } from '../create';

export const AdminDegreesIndexPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isAddingMajor, setIsAddingMajor] = useState(false);
  const { isLoading, list } = useSelector(
    (state) => ({
      list: state.pages.admin.settings.degrees.list.list,
      isLoading: state.pages.admin.settings.degrees.list.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <section>
        <div className={'flex items-center border-b pb-2'}>
          <h5 className={'text-black font-semibold text-sm w-1/12'}>
            Degree Abbr.
          </h5>
          <h5 className={'text-black font-semibold text-sm w-1/12'}>
            Abbr. Name
          </h5>
          <h5 className={'text-black font-semibold text-sm w-4/12'}>Name</h5>
          <h5 className={'text-black font-semibold text-sm w-2/12'}>
            Total Credits Required
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12'}>
            # of Requirements
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12'}>
            # of Courses
          </h5>
        </div>
        {list.map((degree: IDegree, index: number) => {
          return (
            <Link
              to={`/admin/manage/degrees/${degree.id}`}
              key={index}
              className={
                'flex items pt-3 border-b pb-3 hover:bg-neutral-lightest cursor-pointer'
              }
            >
              <div className={'text-neutral-darkest text-sm w-1/12'}>
                {degree.typeAcronym}
              </div>
              <div className={'text-neutral-darkest text-sm w-1/12'}>
                {degree.abbreviatedName}
              </div>
              <div className={'text-neutral-darkest text-sm w-4/12'}>
                {degree.name}
              </div>
              <div className={'text-neutral-darkest text-sm w-2/12'}>
                {degree.totalCreditsRequired}
              </div>
              <div className={'text-neutral-darkest text-sm w-2/12'}>
                {degree.numberOfRequirements}
              </div>
              <div className={'text-neutral-darkest text-sm w-2/12'}>
                {degree.numberOfCourses}
              </div>
            </Link>
          );
        })}
        {isAddingMajor && (
          <AdminCreateDegree onHide={() => setIsAddingMajor(false)} />
        )}
        <div className={'mt-10 w-2/12 flex flex-end'}>
          <Button
            handleOnClick={() => setIsAddingMajor(true)}
            size={ButtonSize.XSmall}
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
          >
            + Add Degree
          </Button>
        </div>
      </section>
    </>
  );
};
