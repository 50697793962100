import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import * as actions from "./actions";
import { RequirementCourses as RequirementCoursesList } from '../../components/requirement-courses';
import { ISelectedDegreeRequirementCourse } from '../../types';

export const RequirementCourses: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, degree, error, degreeId, requirementId, assigned, list, schoolId, gradeLevels, availableSeasons, selected } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      availableSeasons: state.common.configs.configuration.availableSeasons,
      gradeLevels: state.common.configs.configuration.gradeLevels,
      degreeId: state.common.match.params.degreeId,
      requirementId: state.common.match.params.requirementId,
      isLoading: state.pages.admin.settings.degrees.requirements.show.courses.isLoading,
      degree: state.pages.admin.settings.degrees.update.degree,
      error: state.pages.admin.settings.degrees.update.degree.error,
      assigned: state.pages.admin.settings.degrees.requirements.show.courses.assigned,
      list: state.pages.admin.settings.degrees.requirements.show.courses.list,
      selected: state.pages.admin.settings.degrees.requirements.show.courses.selected
    }),
    shallowEqual
  );

  return (
    <>
      <RequirementCoursesList
        isEdit={false}
        isLoading={isLoading}
        assigned={assigned}
        availableSeasons={availableSeasons}
        gradeLevels={gradeLevels}
        handleAssign={(course: number) => dispatch(actions.assign(course))}
        handleFetch={() => dispatch(actions.fetch())}
        handleScheduleRequirementChange={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.onScheduleRequirementChange(course))}
        handleUnAssign={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.unAssign(course))}
        handleSelect={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.select(course))}
        selected={[]}
        list={[]}
        schoolId={schoolId}
      />
    </>
  );
};
