import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as trackActions from '../current-track/actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';

const fetchOnTrackUpdate: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(trackActions.update.done),
    map(() => actions.load.start())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.scheduleNotifications.getScheduleNotifications(
          state$.value.pages.dashboard.currentTrack.schedule.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnTrackUpdate);
