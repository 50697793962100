import React, { useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { IoImageOutline } from '@react-icons/all-files/io5/IoImageOutline';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { api } from '../../../../../../api';
import * as actions from '../../actions';

export const AvatarUpload: React.FC = () => {
  const dispatch = useDispatch();

  const [avatarUploadIsLoading, setAvatarUploadIsLoading] =
    useState<boolean>(false);
  const uploadRef = useRef(null);

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { avatarUrl } = register;

  const handleUploadClick = () => {
    // @ts-ignore
    uploadRef.current.click();
  };

  const handleUploadChange = async () => {
    setAvatarUploadIsLoading(true);
    let inputElement: HTMLInputElement = document.getElementById(
      'avatar'
    ) as HTMLInputElement;
    let files = inputElement.files;
    if (!!files?.length) {
      const file = files[0];
      try {
        let res = await api.user.uploadAvatar(file);
        dispatch(
          actions.onChange({
            key: 'avatarUrl',
            value: res.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
      setAvatarUploadIsLoading(false);
    }
  };

  return (
    <section
      className={'text-center flex items-center flex-col mb-3 cursor-pointer'}
      onClick={() => handleUploadClick()}
    >
      <input
        hidden
        type="file"
        id={'avatar'}
        name={'avatar'}
        accept={'image/png, image/jpeg, image/jpg'}
        ref={uploadRef}
        onChange={() => handleUploadChange()}
      />
      {avatarUploadIsLoading && <Oval color={'black'} height={40} width={40} />}
      {!avatarUploadIsLoading && !avatarUrl && (
        <div
          className={'border-2 border-black p-6 rounded-full w-20 h-20 mb-2'}
        >
          <IoImageOutline
            className={'fill-blue-darkest text-3xl stroke-blue-darkest'}
          />
        </div>
      )}
      {!avatarUploadIsLoading && avatarUrl && (
        <div className={'h-20 w-20 mb-2'}>
          <img className={'rounded-full'} src={avatarUrl} alt={'avatar'} />
        </div>
      )}
      <span className={'text-blue-darkest font-semibold text-sm'}>
        Upload profile picture
      </span>
    </section>
  );
};
