import { createAction } from 'redux-act';
import { IDegreeRequirementsResponse } from '../../../../../../../api/degree';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof loadRequirements.start>
  | ReturnType<typeof loadRequirements.done>
  | ReturnType<typeof loadRequirements.error>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/requirement/show'
);

export const reset = createAction(
  '[pages/admin/dashboard/settings/degrees/requirement/show] reset'
);

export const loadRequirements = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/requirement/show] loadRequirements.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/requirement/show] loadRequirements.error'
  ),
  done: createAction<IDegreeRequirementsResponse[]>(
    '[pages/admin/dashboard/settings/degrees/requirement/show] loadRequirements.done'
  ),
};
export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/requirement/show] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/requirement/show] load.error'
  ),
  done: createAction<IDegreeRequirementsResponse>(
    '[pages/admin/dashboard/settings/degrees/requirement/show] load.done'
  ),
};

export const destroy = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/requirement/show] destroy.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/degrees/requirement/show] destroy.error'
  ),
  done: createAction(
    '[pages/admin/dashboard/settings/degrees/requirement/show] destroy.done'
  ),
};
