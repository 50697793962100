import { createReducer } from 'redux-act';
import * as actions from './actions';

interface IState {
  isLoading: boolean;
  activeStudents: number;
  activeCourses: number;
  emptySchedules: number;
  pendingStudentInvitations: number;
  pendingRegistrations: number;
  studentFlags: number;
  courseConflicts: number;
  unApprovedCourseRequests: number;
}

const initialState: IState = {
  isLoading: false,
  activeStudents: 0,
  activeCourses: 0,
  emptySchedules: 0,
  pendingStudentInvitations: 0,
  pendingRegistrations: 0,
  studentFlags: 0,
  courseConflicts: 0,
  unApprovedCourseRequests: 0,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    ...payload,
  };
});

export const overviewReducer = reducer;
