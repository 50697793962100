import React from 'react';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { Modal, Button, Checkbox, SelectField } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { ISeasonSelect } from '../edit-track/reducer';
import { Input } from '../../../../../../components/input';
import { IMajor, IMinor } from '../../../../../../api/majors';
import { Helpers } from '../../../../../../helpers';
import { ISelectOption } from '../../../../../../ui/select';
import SkeletonModal from '../../../../../../components/modal/skeleton';
import {
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../../../../ui/checkbox';

export const CreateNewTrackModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, majors, minors, isLoading, error, seasons } = useSelector(
    (state) => ({
      isOpen: state.pages.dashboard.modals.createTrack.isOpen,
      isLoading: state.pages.dashboard.modals.createTrack.isLoading,
      majors: state.pages.dashboard.modals.createTrack.majors,
      minors: state.pages.dashboard.modals.createTrack.minors,
      error: state.pages.dashboard.modals.createTrack.error,
      seasons: state.pages.dashboard.modals.createTrack.form.seasons,
    }),
    shallowEqual
  );

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={() => dispatch(actions.close())}>
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                Create Track
              </h2>
            </div>
          </div>
        </div>
        <section className={'mb-4'}>
          <div className={'mb-3'}>
            <Input
              placeholder={'Track #1'}
              onChange={(e) => dispatch(actions.onNameChange(e.target.value))}
              name={'name'}
              label={'Name'}
              type={'text'}
              error={error}
              required={true}
            />
          </div>
          {!!majors.length && (
            <div className="mb-3">
              <SelectField
                multiple={true}
                required={true}
                label={'Major'}
                placeholder={'Type to search majors...'}
                options={Helpers.mapToSelectOptions(majors, (m: IMajor) => ({
                  label: m.name,
                  value: m.id.toString(),
                }))}
                onChange={(option: ISelectOption[]) =>
                  dispatch(actions.onMajorChange(option))
                }
                error={error}
                name={'major_id'}
              />
            </div>
          )}
          {!!minors.length && (
            <div className="mb-3">
              <SelectField
                multiple={true}
                label={'Minor'}
                name={'minor_id'}
                placeholder={'Type to search minors...'}
                options={Helpers.mapToSelectOptions(minors, (m: IMinor) => ({
                  label: m.name,
                  value: m.id.toString(),
                }))}
                onChange={(option) => dispatch(actions.onMinorChange(option))}
                error={error}
              />
            </div>
          )}
        </section>
        {!!seasons.length && (
          <section>
            <h6 className={'text-black font-medium text-sm border-b pb-2 mb-2'}>
              Term Seasons
            </h6>
            {seasons.map((season: ISeasonSelect, index: number) => (
              <div className={'mb-3'} key={index}>
                <Checkbox
                  border={CheckBoxBorder.Black}
                  borderSize={CheckBoxBorderSize.TwoPixel}
                  containerClassNames={'w-full flex'}
                  labelClassNames={'text-black text-sm ml-4'}
                  labelPosition={CheckboxLabelPosition.AfterBox}
                  checked={season.checked}
                  label={season.label}
                  rounded={CheckBoxRounded.md}
                  onChange={() => dispatch(actions.season.select(season.value))}
                />
              </div>
            ))}
          </section>
        )}
        <div className={'flex items-center justify-end mt-10'}>
          <div className="mr-2">
            <Button
              size={ButtonSize.Medium}
              style={ButtonStyle.Outline}
              variant={ButtonVariant.Primary}
              handleOnClick={() => dispatch(actions.close())}
            >
              Cancel
            </Button>
          </div>
          <Button
            isLoading={isLoading}
            size={ButtonSize.Medium}
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            handleOnClick={() => dispatch(actions.create.start())}
          >
            Save Changes
          </Button>
        </div>
      </Modal>
    </>
  );
};
