import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourse } from '../../../../../../types/course';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  course: ICourse;
  isLoading: boolean;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  error: { errors: {}, message: '' },
  course: {
    id: 0,
    name: '',
    code: '',
    content: '',
    category: '',
    credits: 0,
    isUserGenerated: false,
    difficulty: 0,
    locked: {
      reason: '',
      state: false,
    },
    termsOffered: [],
    customDetailsEnabled: false,
    isRepeatable: false,
    requirementCategories: [],
    gradeLevel: [],
    requirementCategory: {
      id: 0,
      color: '',
      major_id: 0,
      name: '',
      number_required: 0,
      planned: 0,
      unit: '',
      completed: 0,
      required: 0,
      updated_at: '',
      created_at: '',
    },
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.create.start, (state, payload) => ({
  ...state,
  isLoading: true,
  error: { errors: {}, message: '' },
}));

reducer.on(actions.create.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.create.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    error: { errors: {}, message: '' },
  };
});

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    //@ts-ignore
    [payload.key]: payload.value,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onTermsOfferedChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    termsOffered: payload,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onGradeLevelChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    gradeLevel: payload,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onRequiredToBeTakenStudentYearChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onRequiredToBeTakenTermChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
  },
  error: {
    message: '',
    errors: {},
  },
}));

export const createCourseReducer = reducer;
