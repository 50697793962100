import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourse } from '../../../../../../types/course';
import { IMajor } from '../../../../../../api/majors';
import { IValidationErrorResponse } from '../../../../../../types/error';
import {ISelectOption} from "../../../../../../ui/select";

interface IState {
  course: ICourse;
  majors: IMajor[];
  isLoading: boolean;
  errors: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  errors: { errors: {}, message: '' },
  majors: [],
  course: {
    id: 0,
    name: '',
    code: '',
    content: '',
    category: '',
    credits: 0,
    isUserGenerated: false,
    difficulty: 0,
    locked: {
      reason: '',
      state: false,
    },
    termsOffered: [],
    customDetailsEnabled: false,
    isRepeatable: false,
    requirementCategories: [],
    gradeLevel: [],
    requirementCategory: {
      id: 0,
      color: '',
      major_id: 0,
      name: '',
      number_required: 0,
      planned: 0,
      unit: '',
      completed: 0,
      required: 0,
      updated_at: '',
      created_at: '',
    },
    coursePrerequisiteGroups: [],
    courseConcurrencyGroups: [],
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    course: {
      ...payload.course,
      gradeLevel: !payload.course.gradeLevel ? [] : payload.course.gradeLevel,
    },
    majors: payload.majors,
    isLoading: false,
  };
});

reducer.on(actions.update.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.update.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.update.done, (state, payload) => {
  return {
    ...state,
    course: payload.course,
    majors: payload.majors,
    isLoading: false,
  };
});

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    //@ts-ignore
    [payload.key]: payload.value,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onTermsOfferedChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    termsOffered: payload,
  },
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onGradeLevelChange, (state, payload) => ({
  ...state,
  course: {
    ...state.course,
    gradeLevel: payload
  },
  error: {
    message: '',
    errors: {},
  },
}));

export const detailsReducer = reducer;
