import React, { useState } from 'react';
import { Input, InputSize } from '../../../../../../../../components/input';
import * as actions from './actions';
import { Button, SelectField } from '../../../../../../../../ui';
import { SelectSize } from '../../../../../../../../ui/select';
import { ButtonSize, ButtonVariant } from '../../../../../../../../ui/button';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import { BlockPicker } from 'react-color';
import { Label } from '../../../../../../../../ui/label';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import { REQUIREMENT_COLORS } from '../../../../../../../../constants';

export interface ICreateRequirementProps {
  onHide: () => any;
}

export const CreateRequirement: React.FC<ICreateRequirementProps> = ({
  onHide,
}) => {
  const dispatch = useDispatch();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const { isLoading, error, requirement } = useSelector(
    (state) => ({
      isLoading:
        state.pages.admin.settings.degrees.update.requirements.create.isLoading,
      requirement:
        state.pages.admin.settings.degrees.update.requirements.create,
      error:
        state.pages.admin.settings.degrees.update.requirements.create.error,
    }),
    shallowEqual
  );

  return (
    <>
      <div className={'flex w-full items-end mt-3'}>
        <div className={'w-5/12 mr-2'}>
          <Input
            label={'Name'}
            placeholder={'name'}
            size={InputSize.xs}
            name={'name'}
            required={true}
            type={'text'}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'name',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-3/12 mr-2">
          <SelectField
            label={'Type'}
            placeholder={'Select Unit Type'}
            // TODO: Make data driven
            options={[
              {
                label: 'Courses',
                value: DegreeRequirementUnitType.Courses.toString(),
              },
              {
                label: 'Units',
                value: DegreeRequirementUnitType.Credits.toString(),
              },
            ]}
            onChange={(option) => dispatch(actions.onTypeChange(option))}
            size={SelectSize.xs}
            name={'type'}
            required={true}
            error={error}
          />
        </div>
        <div className="w-3/12 mr-2">
          <Input
            label={'Number Required'}
            placeholder={'25'}
            size={InputSize.xs}
            name={'numberRequired'}
            required={true}
            type={'number'}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'numberRequired',
                  value: e.target.value,
                })
              )
            }
          />
        </div>
        <div className="w-1/12 mr-2 relative">
          <Label name={'Color'} required={true} />
          <div
            className={
              'h-10 w-full rounded-lg flex items-center justify-center cursor-pointer'
            }
            style={{ backgroundColor: requirement.color }}
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            <div className={'text-sm'}>{requirement.color}</div>
          </div>
          {showColorPicker && (
            <div className={'absolute'} style={{ top: 72, left: -23 }}>
              <BlockPicker
                colors={REQUIREMENT_COLORS}
                color={requirement.color}
                onChangeComplete={(color) => {
                  dispatch(
                    actions.onChange({
                      key: 'color',
                      value: color.hex,
                    })
                  );
                  setShowColorPicker(!showColorPicker);
                }}
              />
            </div>
          )}
        </div>
        <div className={'w-2/12 items-center flex flex-1'}>
          <Button
            handleOnClick={() => {
              dispatch(actions.create.start());
            }}
            size={ButtonSize.XXSmall}
            variant={ButtonVariant.Primary}
            isLoading={isLoading}
          >
            Create
          </Button>
          <IoClose
            className={'cursor-pointer ml-2 fill-neutral'}
            onClick={() => onHide()}
          />
        </div>
      </div>
    </>
  );
};
