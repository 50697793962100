import React, { useEffect, useState } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { Login } from './domains/auth/pages/login';
import { Register } from './domains/auth/pages/register';
import { Page404 } from './pages/404';
import { Routes as DegreelyRoutes } from './common/routes';
import { shallowEqual, useDispatch } from 'react-redux';
import * as userActions from './common/user/actions';
import * as configActions from './common/configs/domain/actions';
import * as configurationActions from "./common/configs/configuration/actions";
import { applyTheme } from './infrastructure/themes/utils';
import { themeDetectorService } from './services/theme-detector';
import { AlertToast } from './common/alerts';
import { MessagesDrawer } from './common/drawers/messages';
import {DegreelyIndex} from "./pages/index";
import {HelpScout} from "./support/help-scout";
import { UserProfile } from './pages/profile';
import * as matchActions from "./common/match/actions";
import { CommonModals } from './common/modals';
import { useSelector } from './infrastructure/selector';
import { StudentApp } from './domains/student/pages';
import { AdminApp } from './domains/admin/pages';
import { appRoutePrefix as adminRoutePrefix } from './domains/admin/routes';

export const App: React.FC = () => {

  let location = window.location;
  let themeKey = themeDetectorService.detectTheme(location.host);

  const dispatch = useDispatch();
  dispatch(matchActions.route.change(window.location));

  const [theme, setTheme] = useState(themeKey);

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(userActions.fetch());
    dispatch(configActions.fetch());
    dispatch(configurationActions.fetch());
    // dispatch(integrationActions.fetch());
  }, []);

  useEffect(() => {
    if(user?.name) {
      dispatch(configurationActions.fetch());
    }
  }, [user?.id]);

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  return (
    <React.Fragment>
      <HelpScout />
      <Routes>
        <Route
          path={'/'}
          element={<DegreelyIndex />}
        />

        <Route path={DegreelyRoutes.auth.login} element={<Login />} />
        <Route path={DegreelyRoutes.auth.register} element={<Register />} />

        <Route path={"/student/*"} element={<StudentApp />} />
        <Route path={`${adminRoutePrefix}/*`} element={<AdminApp />} />

        <Route path={DegreelyRoutes.user.profile} element={<UserProfile />} />
        <Route path={'/404'} element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <CommonModals />
      <MessagesDrawer />
      <AlertToast />
    </React.Fragment>
  );
};
