import React, { useEffect, useState } from 'react';
import {
  Input,
  InputBorderVariant,
  InputSize,
} from '../../../../../../../components/input';
import { Button, SelectField } from '../../../../../../../ui';
import {
  ISelectOption,
  SelectBorderVariant,
  SelectSize,
} from '../../../../../../../ui/select';
import { IPreppedStudentAccountInfo } from '../../../../../../../api/admin/invites';
import {
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../ui/button';
import { BiCheck } from '@react-icons/all-files/bi/BiCheck';
import { IValidationErrorResponse } from '../../../../../../../types/error';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { Helpers } from '../../../../../../../helpers';
import { IDegree } from '../../../../../../../api/admin/degrees';

export interface IAddStudentProps {
  handleAddStudent: (student: IPreppedStudentAccountInfo) => any;
}

export const AddStudent: React.FC<IAddStudentProps> = ({
  handleAddStudent,
}) => {
  const { availableDegrees, availableSeasons, schoolId } = useSelector(
    (state) => ({
      schoolId: state.common.user.info.schoolId,
      availableDegrees: state.pages.admin.common.config.degrees.list,
      availableSeasons: state.common.configs.configuration.availableSeasons,
    }),
    shallowEqual
  );

  const [name, setName] = useState<string>('');
  const [studentId, setStudentId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [degree, setDegree] = useState<ISelectOption>({ label: '', value: '' });
  const [startTerm, setStartTerm] = useState<ISelectOption>({
    label: '',
    value: '',
  });
  const [startYear, setStartYear] = useState<string>(new Date().getFullYear().toString());
  // TODO: Hook up error object from redux store for when the server errors come back...
  const [error, setError] = useState<IValidationErrorResponse>({
    errors: {},
    message: '',
  });

  useEffect(() => {
    setError({ errors: {}, message: '' });
  }, [name, studentId, email, degree, startTerm, startYear]);

  // TODO: This needs to be shared more on the front-end
  const validateFields = (): IValidationErrorResponse => {
    const error: IValidationErrorResponse = { errors: {}, message: '' };

    if (name === '') {
      error.errors = {
        ...error.errors,
        name: 'The name field is required',
      };
    }

    if (studentId === '') {
      error.errors = {
        ...error.errors,
        student_id: 'The student id field is required',
      };
    }

    if (email === '') {
      error.errors = {
        ...error.errors,
        email: 'The email field is required',
      };
    }

    if (!degree) {
      error.errors = {
        ...error.errors,
        degree: 'The program field is required',
      };
    }

    if (!startTerm) {
      error.errors = {
        ...error.errors,
        start_term: 'The start term field is required',
      };
    }

    if (!startYear) {
      error.errors = {
        ...error.errors,
        start_year: 'The start year field is required',
      };
    }

    return error;
  };

  const handleSubmitButtonClick = () => {
    let errors = validateFields();

    if (!!Object.keys(errors.errors).length) {
      setError(errors);
      return;
    }

    handleAddStudent({
      name,
      studentId,
      email,
      degree,
      startTerm,
      startYear: Number(startYear),
      schoolId,
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.code !== 'Enter') {
      return;
    }

    let errors = validateFields();

    if (!!Object.keys(errors.errors).length) {
      setError(errors);
      return;
    }

    handleAddStudent({
      name,
      studentId,
      email,
      degree,
      startTerm,
      startYear: Number(startYear),
      schoolId,
    });
  };

  useEffect(() => {
    document.addEventListener('keypress', (e) => handleKeyPress(e));

    return document.removeEventListener(
      'keypress',
      (e) => handleKeyPress(e),
      false
    );
  }, []);

  return (
    <section className={'flex items-center mb-2'}>
      <div className={'w-2/12'}>
        <Input
          error={error}
          borderVariant={InputBorderVariant.Primary}
          containerStyles={'mr-4'}
          onChange={(e) => setName(e.target.value)}
          type={'text'}
          required={true}
          name={'name'}
          size={InputSize.xs}
          value={name}
          placeholder={'enter name'}
        />
      </div>
      <div className={'w-2/12'}>
        <Input
          error={error}
          borderVariant={InputBorderVariant.Primary}
          containerStyles={'mr-4'}
          onChange={(e) => setStudentId(e.target.value)}
          type={'text'}
          required={true}
          value={studentId}
          name={'student_id'}
          size={InputSize.xs}
          placeholder={'enter id number'}
        />
      </div>
      <div className={'w-2/12'}>
        <Input
          error={error}
          borderVariant={InputBorderVariant.Primary}
          containerStyles={'mr-4'}
          onChange={(e) => setEmail(e.target.value)}
          type={'text'}
          value={email}
          required={true}
          name={'email'}
          size={InputSize.xs}
          placeholder={'enter email'}
        />
      </div>
      <div className={'w-2/12'}>
        <SelectField
          borderVariant={SelectBorderVariant.Primary}
          containerStyles={'mr-4'}
          size={SelectSize.xs}
          name={'degree'}
          onChange={(option) => setDegree(option)}
          options={Helpers.mapToSelectOptions<IDegree>(
            availableDegrees,
            (degree: IDegree) => ({
              label: degree.name,
              value: degree.id.toString(),
            })
          )}
          error={error}
        />
      </div>
      <div className={'w-1/12'}>
        <SelectField
          borderVariant={SelectBorderVariant.Primary}
          containerStyles={'mr-4'}
          placeholder={''}
          name={'start_term'}
          size={SelectSize.xs}
          onChange={(option) => setStartTerm(option)}
          options={availableSeasons}
          error={error}
        />
      </div>
      <div className={'w-1/12'}>
        <Input
          error={error}
          borderVariant={InputBorderVariant.Primary}
          containerStyles={'mr-4'}
          onChange={(e) => setStartYear(e.target.value)}
          type={'text'}
          maxLength={4}
          value={startYear.toString()}
          required={true}
          name={'start_year'}
          size={InputSize.xs}
          placeholder={new Date().getFullYear().toString()}
        />
      </div>
      <div className={'w-1/12 flex justify-end'}>
        <Button
          handleOnClick={() => handleSubmitButtonClick()}
          classes={'h-8 ml-2'}
          size={ButtonSize.XSmall}
          style={ButtonStyle.Filled}
          variant={ButtonVariant.Primary}
          radius={ButtonBorderRadius.LG}
        >
          <BiCheck className={'fill-white text-lg'} />
        </Button>
      </div>
    </section>
  );
};
