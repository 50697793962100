import { combineEpics, ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../infrastructure/selector';
import * as userActions from '../../../../common/user/actions';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    switchMap(() => {
      return from(api.auth.setSession()).pipe(
        mergeMap(() => {
          return of(actions.register.start());
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.register.error(error.response.data)).pipe(concat(source$))
    )
  );

const register: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.register.start),
    switchMap(() => {
      const {
        name,
        email,
        password,
        passwordConfirmation,
        schoolId,
        startYear,
        startSeason,
        pronouns,
        intendedGraduationYear,
        intendedGraduationSeason,
        marketingEmailConsent,
        marketingSMSConsent,
        legalConsent,
        userRoleType,
        majorId,
        avatarUrl,
        studentId,
        phoneNumber,
      } = state$.value.pages.auth.register;

      if (!legalConsent) {
        return of(
          actions.register.error({
            message:
              'You must accept the terms of service and privacy policy in order to register',
            errors: {
              legalConsent: 'Legal Consent is required to register',
            },
          })
        );
      }

      return from(
        api.auth.register({
          name,
          avatarUrl,
          pronouns,
          email,
          password,
          passwordConfirmation,
          schoolId,
          startYear,
          startSeason,
          marketingEmailConsent,
          marketingSMSConsent,
          legalConsent,
          userRoleType,
          intendedGraduationSeason,
          intendedGraduationYear,
          majorId,
          studentId,
          phoneNumber,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.register.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.register.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(register, fetch);
