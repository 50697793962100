import { combineEpics } from 'redux-observable';
import { epic as head } from './head/epic';
import { epic as requirements } from './requirements-progress/epic';
import { epic as courseBin } from './schedule/course-bin/epic';
import { epic as info } from './info/epic';
import { epic as schedule } from "./schedule/epic";
import { epic as profile } from "./profile/epic";
import { epic as courseRequests } from "./course-requests/epic";

export const epic = combineEpics(head, requirements, courseBin, info, schedule, profile, courseRequests);
