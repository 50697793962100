import { createReducer } from 'redux-act';
import * as actions from './actions';
import {IFilter} from "../../../../../../types/filter";

interface IState {
  isLoading: boolean;
  filters: IStudentListFilters;
}

export interface IRangeFilter {
  min: number;
  max: number;
}

export interface IStudentListFilters {
  percentPlanned: IRangeFilter;
  percentInProgress: IRangeFilter;
  percentCompleted: IRangeFilter;
  cohorts: IFilter[];
  grades: IFilter[];
  onlyFlaggedStudents: number;
  courseRequests: string;
}

const initialState: IState = {
  isLoading: false,
  filters: {
    onlyFlaggedStudents: 0,
    grades: [],
    cohorts: [],
    percentInProgress: {
      min: 0,
      max: 100,
    },
    percentPlanned: {
      min: 0,
      max: 100,
    },
    percentCompleted: {
      min: 0,
      max: 100,
    },
    courseRequests: ""
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.apply, (state, payload) => ({
  ...state,
  filters: {
    ...payload,
  },
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  isLoading: false,
  filters: {
    ...payload,
  },
}));

//@ts-ignore
reducer.on(actions.handleFilter, (state, payload) => {
  //@ts-ignore
  let updated = state.filters[payload.key].map((grade: IFilter) => ({
    label: grade.label,
    value: grade.value,
    selected:
      grade.value == payload.filter.value ? !payload.filter.selected : false,
  }));

  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.key]: updated,
    },
  };
});

export const studentFiltersReducer = reducer;
