import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.open),
    map(({ payload }) => actions.load.start(payload))
  );


const fetchOnAddAndRemove: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.add.done, actions.remove.done),
    map(() => actions.load.start(state$.value.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.activeCourseRequestId))
  );

const add: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.add.start),
    switchMap(({ payload }) => {
      return from(
        api.courseRequests.addBackupToCourseRequest(
          state$.value.common.user.info.id,
          payload.courseRequestId,
          payload.backupCourseId,
          payload.priority)
      ).pipe(
        mergeMap(() => {
          return of(actions.add.done());
        })
      )
    }
    ),
    catchError((error, source$) =>
      of(actions.add.error()).pipe(concat(source$))
    )
  );

const remove: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.remove.start),
    switchMap(({ payload }) => {
        return from(
          api.courseRequests.removeBackupFromCourseRequest(
            state$.value.common.user.info.id,
            payload.courseRequestId,
            payload.backupId)
        ).pipe(
          mergeMap(() => {
            return of(actions.remove.done());
          })
        )
      }
    ),
    catchError((error, source$) =>
      of(actions.remove.error()).pipe(concat(source$))
    )
  );

const load: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.load.start),
        switchMap(({ payload }) => {
                return from(
                    api.courseRequests.getCourseRequestById(state$.value.common.user.info.id, payload))
                .pipe(
                    mergeMap(({data}) => {
                        return of(actions.load.done(data));
                    })
                )
            }
        ),
        catchError((error, source$) =>
            of(actions.add.error()).pipe(concat(source$))
        )
    );

export const epic = combineEpics(fetch, fetchOnAddAndRemove, add, load, remove);
