import React, { useEffect, useState } from 'react';
import { CourseCardSimple } from '../../../../../../components/course-card-simple';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "./actions";
import { useSelector } from '../../../../../../infrastructure/selector';
import { ICourse } from '../../../../../../types/course';
import classNames from "classnames";

export const CourseHistory: React.FC = () => {

  const dispatch = useDispatch();

    const [isDraggingOver, setIsDraggingOver] = useState(false);

  const { courses, studentId } = useSelector(
    (state) => ({
      studentId: state.common.user.info.id,
      courses: state.domains.student.pages.courseRequests.courseHistory.list
    }),
    shallowEqual
  );

  useEffect(() => {
    if(studentId) {
      dispatch(actions.fetch())
    }
  }, [studentId]);

  const onDrop = (event: any) => {
    event.preventDefault();
    let courseId = event.dataTransfer.getData("id");
    dispatch(actions.add.start(parseInt(courseId)));
  }

  const onDragOver = (event: any) => {
    event.preventDefault();
  }

  return (
    <>
        <div className={'border-b border-neutral-light w-full pb-4'}>
          <h3 className={'text-black text-lg font-semibold mb-1'}>Course History</h3>
          <p className={'text-neutral text-xs'}>Drop in your completed classes so we know what you've already
            taken.</p>
        </div>
        <section
          style={{minHeight: 150}}
          onDragEnter={() => setIsDraggingOver(true)}
          onDragLeave={() => setIsDraggingOver(false)}
          className={classNames('mt-5 flex bg-neutral-lightest rounded-md py-2 border border-dashed ', {'border-neutral-light': !isDraggingOver}, {'border-baby-blue bg-baby-blue-lightest': isDraggingOver})}
          onDragOver={(event) => onDragOver(event)}
          onDrop={(event) => {
              onDrop(event)
              setIsDraggingOver(false)
          }}
        >
          <div className={'flex flex-wrap w-full'}>
            {courses.map((course: ICourse, index: number) => {
              return (
                <div className={"w-1/4"}>
                  <div className='mx-2'>
                    <CourseCardSimple onDestroy={() => dispatch(actions.remove.start(course.id))} isDraggable={false} name={course.name} code={course.code} courseId={course.id} credits={course.credits} borderColor={course.requirementCategories?.length ? course.requirementCategories[0].color : "#ccc"} />
                  </div>
                </div>
              )
            })}

              {!courses.length && <div className={"flex items-center justify-center w-full"}><span className={classNames("text-sm", {"text-neutral-dark": !isDraggingOver}, {"text-baby-blue": isDraggingOver})}>Drag courses here to mark them as completed.</span></div>}
          </div>
        </section>
    </>
  )
}
