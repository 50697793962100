import { createAction } from 'redux-act';

export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const open = createAction(
  '[pages/admin/settings/degrees/requirement/modals/delete-requirement] open'
);
export const close = createAction(
  '[pages/admin/settings/degrees/requirement/modals/delete-requirement] close'
);

export const destroy = {
  start: createAction(
    '[pages/admin/settings/degrees/requirement/modals/delete-requirement] destroy.start'
  ),
  error: createAction<any>(
    '[pages/admin/settings/degrees/requirement/modals/delete-requirement] destroy.error'
  ),
  done: createAction('[pages/admin/settings/degrees/requirement/modals/delete-requirement] destroy.done'),
};
