import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../../api';
import { IEpic } from '../../../../../../../../infrastructure/selector';
import { generatePath } from 'react-router-dom';
import { Routes } from '../../../../../../../../common/routes';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );
const save: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.start),
    switchMap(() => {

      const { name, type, numberRequired, color, fallbackRequirementId } = state$.value.pages.admin.settings.degrees.requirements.create.requirement;
      const { assigned } = state$.value.pages.admin.settings.degrees.requirements.create.courses;
      const degreeId = state$.value.common.match.params.degreeId;

      return from(
        api.degrees.createDegreeRequirement({
          degreeId,
          name,
          type: parseInt(type.value),
          numberRequired,
          color,
          courses: assigned,
          fallbackRequirementId: fallbackRequirementId ?? null,
          fallbackRequirement: {
            id: null,
            name: ""
          }
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.save.done());
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.save.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;

      return from(
        api.degrees.getDegreeRequirements(degreeId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.load.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

const onSaveComplete: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.save.done),
    map(() => {
      const degreeId = state$.value.common.match.params.degreeId;

      window.location.href = generatePath(Routes.admin.manage.degrees.show, { id: degreeId });

      return EMPTY;
    })
  );

export const epic = combineEpics(fetch, load, save, onSaveComplete);
