import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';
import { ISelectOption } from '../../../../../../ui/select';
import { generatePath } from 'react-router-dom';
import { Routes } from '../../../../../../common/routes';
import * as alertActions from "../../../../../../common/alerts/actions";
import {AlertVariant} from "../../../../../../common/alerts/reducer";
import {ICreateCoursePayload} from "../../../../../../api/admin/courses";

const onSuccessfulCreate: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(
            actions.create.done
        ),
        map(() => alertActions.trigger({
            variant: AlertVariant.Success,
            message: 'Course created successfully',
            heading: "Created!"
        }))
    );

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(() => {
      const {
        name,
        code,
        credits,
        termsOffered,
        difficulty,
        customDetailsEnabled,
        gradeLevel,
        isRepeatable,
      } = state$.value.pages.admin.settings.course.create.course;

      const { schoolId } = state$.value.common.user.info;

      const payload: ICreateCoursePayload = {
          schoolId,
          name,
          code,
          content: code,
          category: 'category',
          credits,
          requiredTerm: 1,
          termsOffered: termsOffered.map((item: ISelectOption) =>
              parseFloat(item.value)
          ),
          difficulty,
          isLocked: false,
          type: 1,
          customDetailsEnabled,
          gradeLevel: gradeLevel.map((item: ISelectOption) =>
              parseFloat(item.value)
          ),
          prerequisites: [],
          concurrency: [],
          isRepeatable,
      }

      return from(
        api.admin.courses.createCourse(payload)
      ).pipe(
        mergeMap(({ data }) => {
          window.location.href = generatePath(
            Routes.admin.manage.courses.show,
            {
              id: data.course.id,
            }
          );
          return of(actions.create.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.create.error(error.response.data)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(create, onSuccessfulCreate);
