import { combineReducers } from 'redux';

import { coursesReducer as courses } from './courses/reducer';
import { requirementReducer as requirement } from './requirement/reducer';
import { searchReducer as search } from './search/reducer';

export const createRequirementReducer = combineReducers({
  courses,
  requirement,
  search
});
