import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as createActions from '../create/actions';
import * as destroyActions from '../delete/actions';
import { api } from '../../../../../../../../api';
import { IEpic } from '../../../../../../../../infrastructure/selector';

const fetchOnCreation: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(createActions.create.done),
    map(() => actions.fetch())
  );

const fetchOnDestroy: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(destroyActions.destroy.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      console.log(state$.value);
      return from(
        api.admin.degrees.getDegreeRequirements(
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data.available));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnCreation, fetchOnDestroy);
