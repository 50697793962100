import React from 'react';
import { PreferenceSubmissionNotificationBanner } from './preference-submission-notification';

export const NotificationBanners: React.FC = () => {
  return (
    <>
      <PreferenceSubmissionNotificationBanner />
    </>
  )
}
