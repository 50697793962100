import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { InviteStudentsModal } from './modals/invite-students';
import { shallowEqual, useDispatch } from 'react-redux';
import * as degreeActions from '../common/config/degrees/actions';
import { useSelector } from '../../../../infrastructure/selector';
import { Routes as DegreelyRoutes } from '../../../../common/routes';
import { InvitationsIndex } from './list';

export const AdminStudentInvitationsPage: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(degreeActions.fetch());
  }, [user?.schoolId]);

  return (
    <>
      <section className={'bg-white rounded-sm p-10'}>
        <Routes>
          <Route
            path={DegreelyRoutes.admin.invitations.students.index}
            element={<InvitationsIndex />}
          />
        </Routes>
      </section>
      <InviteStudentsModal />
    </>
  );
};
