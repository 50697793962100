import React, { useEffect } from 'react';
import { EditRequirementInfo } from './info';
import { EditRequirementCourses } from './courses';
import * as actions from './actions';
import { useDispatch } from 'react-redux';

export const DegreeRequirementUpdatePage: React.FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <EditRequirementInfo />
      <EditRequirementCourses />
    </>
  );
}
