import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { IEpic } from '../../../../../infrastructure/selector';
import * as alertActions from '../../../../../common/alerts/actions';
import { AlertVariant } from '../../../../../common/alerts/reducer';
import React from 'react';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchAfterDelete: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.destroy.done),
    map(() => actions.fetch())
  );

const alertAfterDelete: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.destroy.done),
    map(() =>
      alertActions.trigger({
        message: 'Invitation deleted successfully',
        variant: AlertVariant.Success,
      })
    )
  );

const alertAfterResend: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.resend.done),
    map(() =>
      alertActions.trigger({
        heading: "We're processing sending the invites",
        message: "If you don't see the students in the list, refresh the page.",
        variant: AlertVariant.Success,
      })
    )
  );
const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(api.admin.invites.getStudentAccountInvitations()).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const resend: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.resend.start),
    switchMap(({ payload }) =>
      from(api.admin.invites.resendStudentInvitation(payload)).pipe(
        mergeMap(() => {
          return of(actions.resend.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.resend.error(error)).pipe(concat(source$))
    )
  );

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(({ payload }) =>
      from(api.admin.invites.destroyStudentInvitation(payload)).pipe(
        mergeMap(() => {
          return of(actions.destroy.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.destroy.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  fetch,
  load,
  resend,
  destroy,
  fetchAfterDelete,
  alertAfterDelete,
  alertAfterResend
);
