import { createAction } from 'redux-act';
import { ICourseResponse } from '../../../../../../api/admin/courses';
import { IScheduleConfiguration } from '../../../../../../api/schedules';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/config] fetch'
);

export const load = {
  start: createAction('[pages/admin/dashboard/settings/config] load.start'),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/config] load.error'
  ),
  done: createAction<any>('[pages/admin/dashboard/settings/config] load.done'),
};
