import { createAction } from 'redux-act';
import { ISchoolConfiguration } from '../../../../../../api/schools';
import { IMajor } from '../../../../../../api/majors';
import { ISeasonSelect } from '../edit-track/reducer';
import { ISchedule } from '../../../../../../api/schedules';
import { IValidationErrorResponse } from '../../../../../../types/error';
import { ISelectOption } from '../../../../../../ui/select';

export type IActions =
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof loadConfiguration.start>
  | ReturnType<typeof loadConfiguration.done>
  | ReturnType<typeof loadConfiguration.error>
  | ReturnType<typeof loadMajors.start>
  | ReturnType<typeof loadMajors.done>
  | ReturnType<typeof loadMajors.error>;

export const open = createAction(
  '[pages/dashboard/modals/create-track-modal] open'
);
export const close = createAction(
  '[pages/dashboard/modals/create-track-modal] close'
);

export const onNameChange = createAction<string>(
  '[pages/dashboard/modals/create-track-modal] onNameChange'
);
export const onMajorChange = createAction<ISelectOption[]>(
  '[pages/dashboard/modals/create-track-modal] onMajorChange'
);
export const onMinorChange = createAction<ISelectOption[]>(
  '[pages/dashboard/modals/create-track-modal] onMinorChange'
);

export const fetchConfiguration = createAction(
  '[pages/dashboard/modals/create-track-modal] fetchConfiguration'
);

export const loadConfiguration = {
  start: createAction(
    '[pages/dashboard/current-track] loadConfiguration.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/current-track] loadConfiguration.error'
  ),
  done: createAction<ISchoolConfiguration>(
    '[pages/dashboard/current-track] loadConfiguration.done'
  ),
};

export const fetchMajors = createAction(
  '[pages/dashboard/modals/create-track-modal] fetchMajors'
);

export const loadMajors = {
  start: createAction('[pages/dashboard/current-track] loadMajors.start'),
  error: createAction<any>('[pages/dashboard/current-track] loadMajors.error'),
  done: createAction<IMajor[]>(
    '[pages/dashboard/current-track] loadMajors.done'
  ),
};

export const season = {
  select: createAction<number>(
    '[pages/dashboard/modals/create-track] season.select'
  ),
  set: createAction<ISeasonSelect[]>(
    '[pages/dashboard/modals/create-track] season.set'
  ),
};

export const create = {
  start: createAction('[pages/dashboard/current-track] create.start'),
  error: createAction<IValidationErrorResponse>(
    '[pages/dashboard/current-track] create.error'
  ),
  done: createAction<ISchedule>('[pages/dashboard/current-track] create.done'),
};
