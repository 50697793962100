import { AxiosPromise } from 'axios';
import client from '../axios.client';
import moment from 'moment';

export interface IRegistrationPeriod {
  id: number;
  name: string;
  endDate: string;
  startDate: string;
  addDropDeadline: string;
  withdrawalDeadline: string;
  preferenceSubmissionStartDate: string;
  preferenceSubmissionEndDate: string;
  emailAndMessagingCommunication: string;
  isDestroyable: boolean;
}
export interface ICreateRegistrationPeriodRequest {
  schoolId: number;
  academicYearId: number;
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  addDropDeadline: Date | null;
  withdrawDeadline: Date | null;
  preferenceSubmissionStartDate: Date | null;
  preferenceSubmissionEndDate: Date | null;
  emailAndMessagingCommunication: string;
  terms: number[];
}

export interface IRegistrationPeriodsRequest {
  schoolId: number;
  academicYearId: number;
}

export interface ICourseRequest {
  courseId: number;
  priority: number;
  courseCode: string;
  courseName: string;
  courseCredits: number;
  studentId: number;
}

export interface ICourseRequestGroup {
  groupId: number;
  courseRequest: ICourseRequest[];
}

export interface ICourseRequestSubmission {
  registrationPeriodId: number;
  studentId: number;
  submissionId: number;
  courseRequestGroups: ICourseRequestGroup[];
}

export interface IRegistrationPeriodAPI {
  createRegistrationPeriod(request: ICreateRegistrationPeriodRequest): AxiosPromise<IRegistrationPeriod>;
  getRegistrationPeriods(request: IRegistrationPeriodsRequest): AxiosPromise<IRegistrationPeriod[]>;
  getRegistrationPeriodById(id: number): AxiosPromise<IRegistrationPeriod>;
  destroyRegistrationPeriod(id: number): AxiosPromise<IRegistrationPeriod>;
  getCourseRequestSubmission(registrationPeriodId: number, studentId: number): AxiosPromise<ICourseRequestSubmission>;
}

class RegistrationPeriodAPI implements IRegistrationPeriodAPI {
  createRegistrationPeriod(
    request: ICreateRegistrationPeriodRequest
  ): AxiosPromise<IRegistrationPeriod> {
    return client.post(`/api/registration-periods`, {
      schoolId: request.schoolId,
      academicYearId: request.academicYearId,
      name: request.name,
      terms: request.terms,
      startDate: moment(request.startDate).format("YYYY-MM-DD"),
      endDate: moment(request.endDate).format("YYYY-MM-DD"),
      addDropDeadline: moment(request.addDropDeadline).format("YYYY-MM-DD"),
      withdrawalDeadline: moment(request.withdrawDeadline).format("YYYY-MM-DD"),
      preferenceSubmissionStartDate: moment(request.preferenceSubmissionStartDate).format("YYYY-MM-DD"),
      preferenceSubmissionEndDate: moment(request.preferenceSubmissionEndDate).format("YYYY-MM-DD"),
      emailAndMessagingCommunication: request.emailAndMessagingCommunication,
    });
  }

  getRegistrationPeriods(request: IRegistrationPeriodsRequest): AxiosPromise<IRegistrationPeriod[]> {
    return client.get(`/api/registration-periods`, {
      params: {
        schoolId: request.schoolId,
        academicYearId: request.academicYearId,
      }
    });
  }

  getRegistrationPeriodById(id: number): AxiosPromise<IRegistrationPeriod> {
    return client.get(`/api/registration-periods/${id}`);
  }

  destroyRegistrationPeriod(id: number): AxiosPromise<IRegistrationPeriod> {
    return client.delete(`/api/registration-periods/${id}`);
  }

  getCourseRequestSubmission(registrationPeriodId: number, studentId: number): AxiosPromise<ICourseRequestSubmission> {
    return client.get(`/api/registration-periods/${registrationPeriodId}/course-request-submission`, {
      params: {
        studentId: studentId,
      }
    });
  }
}

export const registrationPeriods = new RegistrationPeriodAPI();
