import React from 'react';
import { Oval } from 'react-loader-spinner';



export enum ButtonVariant {
  Primary,
  Secondary,
  Danger,
  Neutral,
  White,
}

export enum ButtonStyle {
  Filled,
  Outline,
}

export type IButtonStyleOptions = {
  [key in ButtonStyle]: string;
};

const ButtonVariantMapping: Record<ButtonVariant, IButtonStyleOptions> = {
    [ButtonVariant.Primary]: {
      [ButtonStyle.Filled]: 'bg-baby-blue text-white hover:bg-baby-blue-darker active:bg-baby-blue-darkest',
      [ButtonStyle.Outline]: 'border border-baby-blue text-baby-blue hover:bg-baby-blue-lightest active:bg-baby-blue-lighter',
    },
    [ButtonVariant.Secondary]: {
        [ButtonStyle.Filled]: 'bg-baby-blue text-baby-blue',
        [ButtonStyle.Outline]: 'border border-baby-blue-lighter text-baby-blue-lighter hover:bg-baby-blue-lightest',
    },
    [ButtonVariant.Danger]: {
        [ButtonStyle.Filled]: 'bg-red text-white',
        [ButtonStyle.Outline]: 'border border-red text-red',
    },
    [ButtonVariant.Neutral]: {
        [ButtonStyle.Filled]: 'bg-neutral text-neutral-darkest',
        [ButtonStyle.Outline]: 'border border-neutral text-neutral-darkest',
    },
    [ButtonVariant.White]: {
        [ButtonStyle.Filled]: 'bg-white text-baby-blue',
        [ButtonStyle.Outline]: 'border border-neutral text-neutral-darkest',
    },
}

export enum ButtonBorderColorVariant {
  Primary,
  Secondary,
}

export enum ButtonSize {
  Small,
  Medium,
  Large,
  XSmall,
  XXSmall,
  XXXSmall,
}

const ButtonSizeMapping: Record<ButtonSize, string> = {
    [ButtonSize.Small]: 'px-3 py-2 text-xs',
    [ButtonSize.Medium]: 'px-4 py-3 text-sm',
    [ButtonSize.Large]: 'px-4 py-3 text-sm',
    [ButtonSize.XSmall]: 'px-3 py-2 text-xs',
    [ButtonSize.XXSmall]: 'px-3 py-3 text-xxs',
    [ButtonSize.XXXSmall]: 'px-2 py-1 text-xxs',
}

export enum ButtonBorderRadius {
  SM,
  MD,
  LG,
  XL,
  FULL,
}

const ButtonBorderRadiusMapping: Record<ButtonBorderRadius, string> = {
    [ButtonBorderRadius.SM]: 'rounded-sm',
    [ButtonBorderRadius.MD]: 'rounded-md',
    [ButtonBorderRadius.LG]: 'rounded-lg',
    [ButtonBorderRadius.XL]: 'rounded-xl',
    [ButtonBorderRadius.FULL]: 'rounded-full',
}

interface IButtonProps {
  variant: ButtonVariant;
  size?: ButtonSize;
  style?: ButtonStyle;
  radius?: ButtonBorderRadius;
  borderColorVariant?: ButtonBorderColorVariant;
  shadow?: boolean;
  handleOnClick?: () => any;
  children: any;
  isLoading?: boolean;
  classes?: string;
  disabled?: boolean;
  id?: string;
}

export const Button: React.FC<IButtonProps> = ({
  variant,
  size,
  style,
  handleOnClick,
  children,
  isLoading,
  radius,
  disabled,
  classes,
  borderColorVariant,
  shadow
}) => {

  variant = variant || ButtonVariant.Primary;
  size = size || ButtonSize.Medium;
  style = style || ButtonStyle.Filled;
  radius = radius || ButtonBorderRadius.MD;

  let buttonLayoutStyles = 'w-full justify-center flex items-center';
  let buttonStyles = `${buttonLayoutStyles} font-semibold`;

  if (disabled) {
    buttonStyles += ' bg-neutral-lighter text-neutral-dark rounded-md px-2 py-2 text-xs';
  } else {
    buttonStyles += ` ${ButtonVariantMapping[variant][style]} ${ButtonSizeMapping[size]} ${ButtonBorderRadiusMapping[radius]}`;
  }

  if(shadow) {
    buttonStyles += ' shadow';
  }

  if (classes) {
    buttonStyles += ` ${classes}`;
  }

  return (
    <button
      disabled={disabled}
      className={buttonStyles}
      onClick={() => handleOnClick && handleOnClick()}
    >
      {isLoading && (
        <div className={'flex items-center justify-center w-full'}>
          <Oval secondaryColor={'#fff'} color={'#fff'} height={18} width={18} />
        </div>
      )}
      {!isLoading && children}
    </button>
  );
};

Button.defaultProps = {
  style: ButtonStyle.Filled,
  isLoading: false,
  variant: ButtonVariant.Primary,
  size: ButtonSize.Medium,
  radius: ButtonBorderRadius.MD,
  disabled: false,
  handleOnClick: () => true,
};
