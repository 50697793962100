import React from 'react';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { useQuery } from 'react-query';
import { api } from '../../../../../../api';
import Skeleton from 'react-loading-skeleton';
import { SimpleAction } from '../../../../../../components/simple-action';
import { FaPlus } from 'react-icons/fa6';
import { LiaSchoolSolid } from 'react-icons/lia';
import { IRecipient } from '../../../../../../common/drawers/messages/panels/create/recipient/reducer';
import { UserAvatar } from '../../../../../../components/avatar';
import { PiStudent } from 'react-icons/pi';

export interface IStudentsProps {
  isDisabled?: boolean;
  handleModalOpen: () => any;
}
export const Students: React.FC<IStudentsProps> = ({ isDisabled, handleModalOpen }) => {

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useQuery(`academicYearStudents-${academicYearId}`, () => api.students.getStudentsBySchoolId(schoolId), {
    enabled: !!schoolId && !!academicYearId && !isDisabled,
  })

  const list = data?.data || [];

  if(isLoading) {
    return (
      <>
        <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
        <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
        <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
        <Skeleton inline={true} width={"24%"} className={"mr-2"} height={"75px"} />
      </>
    )
  }

  return (
    <>
      <div className={"flex items-center justify-between"}>
        <h3 className={"text-lg text-neutral-darkest mb-2"}>Students</h3>
        {!isDisabled && <SimpleAction
          disabled={isDisabled}
          onClick={() => handleModalOpen()}
          icon={FaPlus}
          label={"Add Students"}
        />}
      </div>
      <hr className={"border-b border-neutral-lighter mb-4"}/>
      <section>

        {/*<div className={"flex items-center justify-between"}>*/}
        {/*  <div>*/}
        {/*    <SearchInput placeholder={"Enter course name..."} handleOnChange={(s) => false} />*/}
        {/*  </div>*/}
        {/*  <div className={"flex items-center justify-between"}>*/}
        {/*    <SimpleAction*/}
        {/*      disabled={isDisabled}*/}
        {/*      onClick={() => handleModalOpen()}*/}
        {/*      icon={FaPlus}*/}
        {/*      label={"Add Course"}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {!list.length && <div className={"flex items-center justify-center"}>
          <div className={"text-center flex flex-col justify-center items-center"}>
            <PiStudent className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
            <h3 className={"text-black font-semibold"}>No Students</h3>
            <p className={"text-neutral-dark text-sm"}>You have not add any students to this school year</p>
            <div className={"mt-4"}>
              <SimpleAction disabled={isDisabled} icon={FaPlus} label={"Add Students"} onClick={() => handleModalOpen()} />
            </div>
          </div>
        </div>}

        <section className={"flex items-center overflow-x-auto w-full pb-4 max-w-full"}>
          {!!list.length && !isDisabled && list.map((student: IRecipient) => {
            return (
              <div className={'w-1/12 mr-3 min-w-fit flex-grow-0 flex-shrink-0'}>
                <div className={"bg-neutral-lightest p-3 rounded-md cursor-pointer hover:bg-neutral-lighter flex items-center"}>
                  <UserAvatar imageUrl={student.avatarUrl} size={30} />
                  <h4 className={"ml-2 text-neutral-darkest text-xs font-semibold"}>{student.name}</h4>
                </div>
              </div>
            )
          })}
        </section>

      </section>
    </>
  )}
