import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../../api';
import { IEpic } from '../../../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const filterOnAssign: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.assign),
    map(() => actions.filter())
  );

const filterOnUnAssign: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.unAssign),
    map(() => actions.filter())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      return from(
        api.degrees.getAvailableCourses({
          degreeId: state$.value.common.match.params.degreeId,
          search: state$.value.pages.admin.settings.degrees.requirements.create.search.keyword,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$))
    }
    )
  );

export const epic = combineEpics(fetch, load, filterOnAssign, filterOnUnAssign);
