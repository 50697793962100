import { createAction } from 'redux-act';
import { ICourse } from '../../../../../../types/course';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/dashboard/current-track/course-bin] fetch'
);

export const load = {
  start: createAction('[pages/dashboard/current-track/course-bin] load.start'),
  error: createAction<any>(
    '[pages/dashboard/current-track/course-bin] load.error'
  ),
  done: createAction<ICourse[]>(
    '[pages/dashboard/current-track/course-bin] load.done'
  ),
};

export const update = createAction<ICourse[]>(
  '[pages/dashboard/current-track/course-bin] update'
);
