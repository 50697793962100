import React, {useEffect} from 'react';
import { Button } from '../../../../../../ui';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import {shallowEqual, useDispatch} from "react-redux";
import * as actions from "./actions";
import {useSelector} from "../../../../../../infrastructure/selector";
import {generatePath, useNavigate} from "react-router-dom";

export const PreferenceSubmissionNotificationBanner: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(actions.fetch())
    }, []);

    const {
        isActive,
        isLoading,
        academicYear,
        endDate,
        registrationPeriodId
    } = useSelector(
        (state) => ({
            isActive: state.pages.dashboard.banners.preferenceSubmissionNotification.isActive,
            isLoading: state.pages.dashboard.banners.preferenceSubmissionNotification.isLoading,
            academicYear: state.pages.dashboard.banners.preferenceSubmissionNotification.academicYear,
            endDate: state.pages.dashboard.banners.preferenceSubmissionNotification.endDate,
            registrationPeriodId: state.pages.dashboard.banners.preferenceSubmissionNotification.registrationPeriodId
        }),
        shallowEqual
    );

    if(!isActive) {
        return null;
    }

    return (
        <div className={"py-2 px-5 bg-baby-blue flex items-center justify-between"}>
          <span className={"text-white text-sm font-normal"}>Please complete and submit your course selection survey for the {academicYear} Academic Year between now and {endDate}</span>
            <div className={"w-2/12"}>
                <Button
                    handleOnClick={() => navigate(generatePath(`/student/registration/:id/preferences/survey`, { id: registrationPeriodId }))}
                    size={ButtonSize.XSmall}
                    variant={ButtonVariant.White}
                    radius={ButtonBorderRadius.FULL}
                >Access Course Selection Survey</Button>
            </div>
        </div>
    )
}
