import { createAction } from 'redux-act';
import { ICourse } from '../../../../../../types/course';

export type IActions =
  ReturnType<typeof fetch> |
  ReturnType<typeof load.start> |
  ReturnType<typeof load.error> |
  ReturnType<typeof load.done>;

export const fetch = createAction('[pages/student/course-requests/course-bin] fetch');

export const load = {
  start: createAction('[pages/student/course-requests/course-bin] load.start'),
  error: createAction('[pages/student/course-requests/course-bin] load.error'),
  done: createAction<ICourse[]>('[pages/student/course-requests/course-bin] load.done'),
};

export const onSearch = createAction<string>('[pages/student/course-requests/course-bin] search');

