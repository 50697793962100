import React from 'react';
import {
  IDegreeRequirement,
} from '../../../../../../../../../api/admin/degrees';
import { shallowEqual } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../../../../common/routes';
import { useSelector } from '../../../../../../../../../infrastructure/selector';
import { useDispatch } from 'react-redux';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import {
  Input,
  InputBorderVariant,
  InputSize,
} from '../../../../../../../../../components/input';
import { REQUIREMENT_COLORS } from '../../../../../../../../../constants';
import { BlockPicker } from 'react-color';
import {
  ISelectOption,
  SelectField,
  SelectSize,
} from '../../../../../../../../../ui/select';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import * as updateActions from '../../update/actions';
import { MdClose } from '@react-icons/all-files/md/MdClose';

export interface IDegreeRequirementItemProps {
  requirement: IDegreeRequirement;
}

export const DegreeRequirementItem: React.FC<IDegreeRequirementItemProps> = ({
  requirement,
}) => {
  const navigate = useNavigate();

  const { degreeId } = useSelector(
    (state) => ({
      degreeId: state.common.match.params.id,
    }),
    shallowEqual
  );

  return (
    <>
        <div
          onClick={() => navigate(generatePath(Routes.admin.manage.degrees.requirements.show, { degreeId: degreeId.toString(), requirementId: requirement.id.toString() }))}
          className={
            'flex items pt-3 border-b pb-3 hover:bg-neutral-lightest cursor-pointer'
          }
        >
          <div className={'text-neutral-darkest text-sm w-3/12'}>{requirement.name}</div>
          <div
            className={'text-neutral-darkest text-sm w-4/12 flex items-center'}
          >
            <div
              className={'h-6 w-6 p-2 rounded-lg mr-2'}
              style={{ backgroundColor: requirement.color }}
            ></div>
            {requirement.color}
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12'}>
            {requirement.numberRequired}
          </div>
          <div className={'text-neutral-darkest text-sm w-2/12'}>
            {requirement.typeLabel}
          </div>
        </div>
    </>
  );
};
