import { createReducer } from 'redux-act';
import * as actions from './actions';
import { Season } from '../../../../../../../../constants';
import * as requirementActions from "../requirement/actions";
import { IAvailableDegreeCourse } from '../../../../../../../../api/degree';

export interface ISelectedDegreeRequirementCourse {
  courseId: number;
  name: string;
  credits: number;
  studentYear: number|null
  termSeason?: Season;
}

interface IState {
  isLoading: boolean;
  original: IAvailableDegreeCourse[];
  list: IAvailableDegreeCourse[];
  selected: ISelectedDegreeRequirementCourse[];
  assigned: ISelectedDegreeRequirementCourse[];
}

const initialState: IState = {
  isLoading: false,
  original: [],
  list: [],
  selected: [],
  assigned: []
};

const reducer = createReducer<typeof initialState>({}, initialState);


reducer.on(requirementActions.save.done, (state, payload) => ({
  ...state,
  isLoading: false,
  selected: [],
  assigned: [],
  list: state.original,
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: payload,
    original: payload
  };
});

reducer.on(actions.filter, (state) => {
  console.log(state.assigned);
  return {
    ...state,
    isLoading: false,
    list: state.original.filter((c) => !state.assigned.map(a => a.courseId).includes(c.id))
  };
});

reducer.on(actions.select, (state, payload) => {

  let selected = state.selected;

  if(selected.map(s => s.courseId).includes(payload.courseId)) {
    selected = selected.filter((s) => s.courseId !== payload.courseId);
  } else {
    selected = [
      ...selected,
      payload
    ]
  }

  return {
    ...state,
    selected: [
      ...selected
    ]
  };
});

reducer.on(actions.onScheduleRequirementChange, (state, payload) => {

  let selected = state.selected;

  let selectedRequirement = selected.find(s => s.courseId === payload.courseId);


  let updatedRequirement: ISelectedDegreeRequirementCourse = {
    courseId: payload.courseId,
    name: payload.name,
    credits: payload.credits,
    // @ts-ignore: Object is possibly 'null'.
    studentYear: payload.studentYear >= 0 ? payload.studentYear : null,
    // @ts-ignore: Object is possibly 'null'.
    termSeason: payload.termSeason >= 0 ? payload.termSeason : undefined
  }

  if(selectedRequirement) {
    selected = selected.filter((s) => s.courseId !== payload.courseId);
  }

  return {
    ...state,
    selected: [
      ...selected,
      updatedRequirement
    ]
  };
});

reducer.on(actions.assign, (state, payload) => {

 let selected = state.selected;

  let assigned = selected.find((s) => s.courseId === payload);

  if(assigned) {
    selected = selected.filter((s) => s.courseId !== payload);
  }

  let newState = {
    ...state,
    selected: [
      ...selected,
    ],
  };

  if(assigned) {
    newState.assigned = [
      ...state.assigned,
      assigned
    ]
  }

  return newState;
});

reducer.on(actions.unAssign, (state, payload) => {

  let assigned = state.assigned;

  return {
    ...state,
    assigned: assigned.filter((s) => s.courseId !== payload.courseId)
  };
});

export const coursesReducer = reducer;
