import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISelectOption } from '../../../../../../../../ui/select';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { IDegreeRequirementsResponse } from '../../../../../../../../api/degree';

interface IState {
  name: string;
  type: ISelectOption;
  numberRequired: number|null;
  color: string;
  error: IValidationErrorResponse;
  fallbackRequirementId: number|null;
  fallbackRequirementOptions: IDegreeRequirementsResponse[];
}

const initialState: IState = {
  name: "",
  type: {
    label: "Classes",
    value: DegreeRequirementUnitType.Courses.toString()
  },
  numberRequired: null,
  color: "#000",
  error: {errors: {}, message: ""},
  fallbackRequirementOptions: [],
  fallbackRequirementId: null
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  [payload.key]: payload.value,
  error: { errors: {}, message: "" }
}));

reducer.on(actions.save.error, (state, payload) => ({
  ...state,
  error: payload
}));

reducer.on(actions.load.done, (state, payload) => ({
  ...state,
  fallbackRequirementOptions: payload
}));

reducer.on(actions.save.done, (state, payload) => ({
  ...state,
  name: "",
  type: {
    label: "Classes",
    value: DegreeRequirementUnitType.Courses.toString()
  },
  numberRequired: null,
  color: "#000",
  error: { errors: {}, message: "" }
}));

reducer.on(actions.onTypeChange, (state, payload) => ({
  ...state,
  type: payload,
  error: { errors: {}, message: "" }
}));

reducer.on(actions.onColorChange, (state, payload) => ({
  ...state,
  color: payload,
  error: { errors: {}, message: "" }
}));
export const requirementReducer = reducer;
