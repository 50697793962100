import React from 'react';
import {GraduationCap, Home} from "../../../icons";
import { NavLink } from 'react-router-dom';
import {Courses} from "../../../icons/courses";
import {AddCourse} from "../../../icons/add-course";
import {AddProgram} from "../../../icons/add-program";
import {InviteStudent} from "../../../icons/invite-student";

export const ExpandedSidebarNavigation = () => {

  const itemClasses = "flex items-center pt-3"
  const textClasses = "text-sm font-medium ml-2";

  const navItemBaseClasses = "w-full px-1 py-0.5 fill-white stroke-white bg-baby-blue flex items-center text-white";
  const navItemActiveClasses = "w-full px-1 py-0.5 bg-white flex items-center rounded-md group is-active fill-baby-blue stroke-baby-blue text-baby-blue"

  return (
    <div className={"px-5"}>
      <h5 className={"text-sm text-white pb-1 border-b font-semibold"}>Data Views</h5>
      <ul className={"flex flex-col mb-8"}>
        <li className={itemClasses}>
          <NavLink to={"/admin/overview"} end={true} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <Home classes={'text-2xl'}/>
            <span className={textClasses}>Overview</span>
          </NavLink>
        </li>
        <li className={itemClasses}>
          <NavLink to={"/admin/students"} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <GraduationCap classes={"text-2xl"} />
            <span className={textClasses}>Students</span>
          </NavLink>
        </li>
        <li className={itemClasses}>
          <NavLink to={"/admin/courses"} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <Courses classes={"text-2xl"} />
            <span className={textClasses}>Courses</span>
          </NavLink>
        </li>
        {/*<li className={itemClasses}>*/}
        {/*  <GiGraduateCap className={iconClasses}/>*/}
        {/*  <span className={textClasses}>Programs</span>*/}
        {/*</li>*/}
      </ul>

      <h5 className={"text-sm font-semibold text-white pb-1 border-b"}>Add & Manage</h5>
      <ul className={"flex flex-col mb-8"}>
        <li className={itemClasses}>
          <NavLink to={"/admin/manage/courses"} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <AddCourse classes={"text-2xl"} />
            <span className={textClasses}>Edit Courses</span>
          </NavLink>
        </li>
        <li className={itemClasses}>
          <NavLink to={"/admin/manage/degrees"} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <AddProgram classes={"text-2xl"} />
            <span className={textClasses}>Programs</span>
          </NavLink>
        </li>
      </ul>

      <h5 className={"text-sm text-white pb-1 border-b font-semibold"}>Invite & Manage</h5>
      <ul className={"flex flex-col mb-8"}>
        <li className={itemClasses}>invitations
          <NavLink to={"/admin/invitations/student"} className={({ isActive}) => isActive ? navItemActiveClasses : navItemBaseClasses}>
            <InviteStudent classes={"text-2xl"} />
            <span className={textClasses}>Invite Student</span>
          </NavLink>
        </li>
        {/*<li className={itemClasses}>*/}
          {/*  <NavLink to={"/admin/invitations/student"} className={({ isActive}) => isActive ? "p-1.5 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1.5 bg-baby-blue flex items-center flex-col mb-4 text-white"}>*/}
          {/*    <InviteAdvisor />*/}
          {/*    <span className={"text-xxs font-light pt-1"}>Invite Advisor</span>*/}
          {/*  </NavLink>*/}
        {/*</li>*/}
      </ul>


    </div>
  )
}
