import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Routes } from '../../../../../../../common/routes';
import { CreateDegreeRequirementCourseList } from './courses';
import { CreateRequirementDetails } from './requirement';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "./requirement/actions";
import { useSelector } from '../../../../../../../infrastructure/selector';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import { FaArrowLeft } from 'react-icons/fa';

export const CreateDegreeRequirementPage: React.FC = () => {

  const dispatch = useDispatch();

  const { degreeId } = useSelector(
    (state) => ({
      degreeId: state.common.match.params.degreeId,
    }),
    shallowEqual
  );

  return (
    <>
      <div className={"flex items-center justify-between"}>
        <div>
          <Link className={"flex items-center cursor-pointer"} to={generatePath("/admin/manage" + Routes.admin.manage.degrees.show, { id: degreeId })}>
            <FaArrowLeft className={"fill-baby-blue text-sm"}/>
            <span className={"text-sm ml-1 block text-baby-blue"}>Back</span>
          </Link>
        </div>
        <div className={"flex items-center cursor-pointer"} onClick={() => dispatch(actions.save.start())}>
          <MdCheck className={"fill-baby-blue ml-4"}/>
          <span className={"text-sm ml-1 block text-baby-blue"}>Done</span>
        </div>
      </div>
      <h1 className={'text-neutral-darkest text-lg mb-2 mt-2 font-medium'}>Create Degree Requirement</h1>
      <hr className={''} />
      <section className={"w-full py-4"}>
        <CreateRequirementDetails />
        <section className={"mt-10"}>
          <CreateDegreeRequirementCourseList />
        </section>
      </section>
    </>
  );
}
