import { createAction } from 'redux-act';
import { IUploadedStudent } from '../../../../../../../api/admin/invites';

export type IActions =
  | ReturnType<typeof upload.start>
  | ReturnType<typeof upload.error>
  | ReturnType<typeof upload.done>;

export const upload = {
  start: createAction<File | null>(
    '[pages/admin/student-invitations/modals/invite-students/upload-csv] send.start'
  ),
  error: createAction<any>(
    '[pages/admin/student-invitations/modals/invite-students/upload-csv] send.error'
  ),
  done: createAction<IUploadedStudent[]>(
    '[pages/admin/student-invitations/modals/invite-students/upload-csv] send.done'
  ),
};
