import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as termActions from '../../../../../../components/DragAndDropSchedule/term/actions';
import { IValidationErrorResponse } from '../../../../../../types/error';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  termId: number;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  termId: 0,
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state, payload) => ({
  ...state,
  isOpen: true,
  termId: payload,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
}));

reducer.on(termActions.removeV2.start, (state) => ({
  ...state,
  isLoading: true,
  error: {
    errors: {},
    message: '',
  },
}));

reducer.on(termActions.removeV2.done, (state) => ({
  ...state,
  isLoading: false,
  isOpen: false,
}));

reducer.on(termActions.removeV2.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

export const deleteTrackModalReducer = reducer;
