import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';
import SkeletonModal from '../../../../../../../components/modal/skeleton';
import * as actions from "./actions";
import { Modal } from '../../../../../../../components/modal';
import { Button } from '../../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../../ui/button';
import { CourseCardSimple } from '../../../../../../../components/course-card-simple';
import { SearchInput } from '../../../../../../../components/search-input';
import { ICourseRequestBackup } from '../../../../../../../api/course-requests';
import { debounce } from 'lodash';
import { CourseBinFilterDropdown } from '../../../../../../../common/filters/course-bin';

export const CourseRequestBackupsModal: React.FC = () => {
  const dispatch = useDispatch();

  const [placeHolderCount, setPlaceHolderCount] = React.useState(3);

  const { isOpen, isLoading, courses, activeCourseRequest, activeCourseRequestId, search } = useSelector(
    (state) => ({
      activeCourseRequestId: state.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.activeCourseRequestId,
      activeCourseRequest: state.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.activeCourseRequest,
      isOpen: state.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.isOpen,
      isLoading: state.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.isLoading,
      courses: state.domains.student.pages.courseRequests.courseBin.list,
      search: state.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.search
    }),
    shallowEqual
  );

  useEffect(() => {
    if(activeCourseRequest?.backups.length > 0) {
      setPlaceHolderCount(3 - activeCourseRequest.backups.length);
    } else {
      setPlaceHolderCount(3);
    }
  }, [activeCourseRequest?.backups?.length]);

  const onDrop = (priority: number, event: any) => {
    event.preventDefault();
    let courseId = event.dataTransfer.getData("id");
    dispatch(actions.add.start({
      backupCourseId: parseInt(courseId),
      courseRequestId: activeCourseRequest.id,
      priority
    }));
  }

  const onDragOver = (event: any) => {
    event.preventDefault();
  }

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderBody={() => <body></body>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="w-2/12 mr-2">
                <Button
                  size={ButtonSize.XSmall}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Cancel
                </Button>
              </div>
              <div className="w-2/12">
                <Button
                  isLoading={isLoading}
                  size={ButtonSize.XSmall}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Done
                </Button>
              </div>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                {activeCourseRequest?.course.name}
              </h2>
            </div>
          </div>
          <div className='flex justify-between'>
            <div className='w-1/2 mr-2'>
              <div className='mb-4'>
                <h3 className={"text-black text-md font-semibold mb-1"}>Backups</h3>
                <p className={"text-xs text-neutral"}>Drag and drop to order preferences.</p>
              </div>
              <div>

                {activeCourseRequest?.backups.map((backup: ICourseRequestBackup) => {
                  return (
                    <CourseCardSimple
                      onDestroy={() => dispatch(actions.remove.start({ courseRequestId: activeCourseRequestId, backupId: backup.id }))}
                      name={backup.course.name}
                      code={backup.course.code}
                      courseId={backup.course.id}
                      credits={backup.course.credits}
                      borderColor={backup.course.requirementCategories?.length ? backup.course.requirementCategories[0].color : "#ccc"}
                    />
                  )
                })}

                {!!placeHolderCount && [...Array(placeHolderCount)].map((_, index) => {
                  return (
                    <div
                      onDrop={(event) => onDrop(activeCourseRequest.backups.length + index + 1, event)}
                      onDragOver={(event) => onDragOver(event)}
                      onDragEnter={() => console.log('on-drag-enter')}
                      onDragLeave={() => console.log('on-drag-exit')}
                      className={"bg-neutral-lightest rounded-md px-2 py-4 text-neutral text-xs mb-3 text-center border-neutral-lighter border border-dashed"}
                    >Backup #{activeCourseRequest?.backups.length + index + 1}</div>
                  )
                })}
              </div>
            </div>
            <div className='w-1/2 ml-2'>
              <div className='border-neutral-lighter border rounded-md ml-2 p-4'>
                <h3 className={"text-black text-md font-semibold mb-1"}>Course Bin</h3>
                <div className='flex mb-4'>
                  <div className='w-1/2 mr-1'>
                    <SearchInput value={search} placeholder={"Search Courses"} handleOnChange={debounce((e) => dispatch(actions.onSearch(e.target.value)), 250)} />
                  </div>
                  <div className='w-1/2 ml-1'>
                    <CourseBinFilterDropdown />
                  </div>
                </div>
                <section style={{ maxHeight: 350 }} className={"flex flex-col overflow-y-auto hide-scrollbar"}>
                  {courses.map((course: any, index: number) => {
                    return (
                      <CourseCardSimple isDraggable={true} name={course.name} code={course.code} courseId={course.id}
                                        credits={course.credits}
                                        borderColor={course.requirementCategories?.length ? course.requirementCategories[0].color : "#ccc"} />
                    )
                  })}
                </section>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
