import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as actions from './actions';
import * as queryString from 'query-string';

interface IQuery {
  token: string;
}

const getResetPasswordToken = () => {
  let token = '';
  // @ts-ignore
  const query: IQuery = queryString.parse<IQuery>(window.location.search);

  if (!!query?.token) {
    token = query.token;
  }

  return token;
};

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    // TODO: Add filter here for query string
    filter(() => !!getResetPasswordToken()),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      let token = getResetPasswordToken();

      return from(api.auth.validateForgotPasswordToken(token)).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const submit: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.submit),
    map(() => actions.resetPassword.start())
  );

const resetPassword: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.resetPassword.start),
    switchMap(() => {
      let token = getResetPasswordToken();

      const { password, confirmPassword } =
        state$.value.pages.auth.modals.resetPassword;

      return from(
        api.auth.resetPassword(token, password, confirmPassword)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.resetPassword.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.resetPassword.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, submit, resetPassword);
