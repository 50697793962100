import React, { useEffect } from 'react';
import { Button } from '../../../../../ui';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../ui/button';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import { ICourseRequest, ICourseRequestBackup } from '../../../../../api/course-requests';
import { CourseCardSimple } from '../../../../../components/course-card-simple';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoCloseCircleSharp } from '@react-icons/all-files/io5/IoCloseCircleSharp';

export const AdminStudentCourseRequests: React.FC = () => {

  const dispatch = useDispatch();

  const { courseRequests, isLoading } = useSelector(
    (state) => ({
      courseRequests: state.pages.admin.dashboard.student.courseRequests.list,
      isLoading: state.pages.admin.dashboard.student.courseRequests.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch())
  }, []);

  return (
    <section className={"border-t border-neutral-light py-4"}>
      <div className={"flex items-center justify-between w-full"}>
        <div className='w-10/12'>
          <h2 className={"text-xl text-black font-semibold"}>2024-2025 Class Preferences</h2>
        </div>
        <div className='w-2/12'>
          {!!courseRequests.filter((request: ICourseRequest) => !request.isApproved).length && <Button handleOnClick={() => dispatch(actions.approve.start())} size={ButtonSize.XSmall} shadow={true} variant={ButtonVariant.Primary} style={ButtonStyle.Outline} radius={ButtonBorderRadius.FULL}><FaCircleCheck className={"fill-baby-blue mr-1"} /> Approve</Button>}
          {!!courseRequests.filter((request: ICourseRequest) => request.isApproved).length && <Button handleOnClick={() => dispatch(actions.disapprove.start())} size={ButtonSize.XSmall} shadow={true} variant={ButtonVariant.Primary} radius={ButtonBorderRadius.FULL}><IoCloseCircleSharp className={"fill-white mr-1"} />Disapprove</Button>}
        </div>
      </div>

      <section className={"mt-8"}>
        {courseRequests.map((courseRequest: ICourseRequest) => {
          return (
            <div className={"flex items-center pb-8 mb-8 border-b border-b-neutral-lighter"}>
              <div className='w-1/4'>
                <div className='mx-2'>
                  <CourseCardSimple name={courseRequest.course.name} code={courseRequest.course.code} courseId={courseRequest.course.id} credits={courseRequest.course.credits} borderColor={!!courseRequest.course.requirementCategories && courseRequest.course.requirementCategories.length > 0 ? courseRequest.course.requirementCategories[0].color : 'gray'} />
                  <span className={"text-neutral text-xs font-light"}>First Choice</span>
                </div>
              </div>
              {courseRequest.backups.map((backup: ICourseRequestBackup, index: number) => {
                return (
                  <div className={"w-1/4"}>
                    <div className='mx-2'>
                      <CourseCardSimple name={backup.course.name} code={backup.course.code} courseId={backup.course.id}
                                        credits={backup.course.credits}
                                        borderColor={!!backup.course.requirementCategories && backup.course.requirementCategories.length > 0 ? backup.course.requirementCategories[0].color : 'gray'} />
                      {index == 0 && <span className={'text-neutral text-xs font-light'}>Second Choice</span>}
                      {index == 1 && <span className={'text-neutral text-xs font-light'}>Third Choice</span>}
                      {index == 2 && <span className={'text-neutral text-xs font-light'}>Fourth Choice</span>}
                    </div>
                  </div>
                )
              })}
              {[...Array(3 - courseRequest?.backups.length)].map((_, index) => {
                    return (
                      <div className={"w-1/4"}>
                        <div className='mx-2'>
                          <div
                            className={"bg-neutral-lightest rounded-md px-2 py-4 text-neutral text-xs mb-3 text-center border-neutral-lighter border border-dashed"}
                          >Backup #{courseRequest?.backups.length + index + 2}</div>
                          {index == 0 && <span className={'text-neutral text-xs font-light'}>Second Choice</span>}
                          {index == 1 && <span className={'text-neutral text-xs font-light'}>Third Choice</span>}
                          {index == 2 && <span className={'text-neutral text-xs font-light'}>Fourth Choice</span>}
                        </div>
                      </div>
                )
            })}
            </div>
          )
        })}
      </section>
    </section>
  )
}
