import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, filter, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as actions from './actions';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(({ payload }) =>
      from(
        api.registration.coursePreferences.survey()
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  fetch,
  load,
);
