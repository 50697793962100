import { combineEpics } from 'redux-observable';
import { epic as currentTrack } from './current-track/epic';
import { epic as courseBin } from './current-track/course-bin/epic';
import { epic as modals } from './modals/epic';
import { epic as progress } from './progress/epic';
import { epic as courseProgress } from './course-progress/epic';
import { epic as trackNotifications } from './track-notifications/epic';
import { epic as scheduleList } from './track-list/epic';
import { epic as banners } from './banners/epic';

export const epic = combineEpics(
  currentTrack,
  courseBin,
  modals,
  progress,
  courseProgress,
  trackNotifications,
  scheduleList,
  banners
);
