import { themes } from '../index';

export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

declare global {
  interface Window {
    appTheme: ITheme;
  }
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    '--color-blue-darkest': variables.blueDarkest || '',
    '--color-blue': variables.blue || '',
    '--color-baby-blue-darkest': variables.babyBlueDarkest || '',
    '--color-baby-blue-darker': variables.babyBlueDarker || '',
    '--color-baby-blue-dark': variables.babyBlueDark || '',
    '--color-baby-blue': variables.babyBlue || '',
    '--color-baby-blue-lighter': variables.babyBlueLighter || '',
    '--color-baby-blue-lightest': variables.babyBlueLightest || '',
    '--color-primary': variables.primary || '',
    '--color-secondary': variables.secondary || '',
    '--color-tertiary': variables.tertiary || '',
    '--color-green': variables.green || '',
    '--color-green-lighter': variables.greenLighter || '',
    '--color-green-lightest': variables.greenLightest || '',
    '--color-yellow-darkest': variables.yellowDarkest || '',
    '--color-yellow': variables.yellow || '',
    '--color-yellow-lighter': variables.yellowLighter || '',
    '--color-yellow-lightest': variables.yellowLightest || '',
    '--color-red': variables.red || '',
    '--color-red-lighter': variables.redLighter || '',
    '--color-red-lightest': variables.redLightest || '',
  };
};

export const applyTheme = (theme: string): void => {
  const themeObject: IMappedTheme = mapTheme(themes[theme]);
  if (!themeObject) return;

  const root = document.documentElement;

  window.appTheme = {};

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);

    // @ts-ignore
    if (themeObject[property]) {
      // KebabCase to CamelCase
      let key = property
        .replace('--color-', '')
        .replace(/-./g, (x) => x[1].toUpperCase());
      // @ts-ignore
      window.appTheme[key] = themeObject[property];
    }
  });
};

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme };
};
