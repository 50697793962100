
export interface IStudentYearService {
  getStudentYearAsString(studentYear: number): string;
}

export enum StudentGradeYear {
  Freshman,
  Sophomore,
  Junior,
  Senior,
  SuperSenior
}

class StudentYearService implements IStudentYearService {

  getStudentYearAsString(studentYear: number|null) {

    if (studentYear == null) {
      return '';
    }

    if (studentYear == StudentGradeYear.Freshman) {
      return 'One';
    }
    if (studentYear == StudentGradeYear.Sophomore) {
      return 'Two';
    }
    if (studentYear == StudentGradeYear.Junior) {
      return 'Three';
    }
    if (studentYear == StudentGradeYear.Senior) {
      return 'Four';
    }
    if (studentYear == StudentGradeYear.SuperSenior) {
      return 'Five+';
    }

    return '';
  }
}

export const studentYearService = new StudentYearService();
