import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import * as actions from './actions';
import * as courseActions from "../courses/actions";
import { IEpic } from '../../../../../../../../infrastructure/selector';

const onSearch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.onSearch),
    map(() => courseActions.fetch())
  );

export const epic = combineEpics(onSearch);
