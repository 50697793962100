import React, { useState } from 'react';
import { Input } from '../../../../../components/input';
import * as actions from '../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../infrastructure/selector';
import { Link } from 'react-router-dom';
import { IValidationErrorResponse } from '../../../../../types/error';
import { api } from '../../../../../api';
import { AxiosError } from 'axios';
import { Button } from '../../../../../components/button';
import { AuthHeader } from '../../../shared/header';
import { history } from '../../../../../app/store';

interface IRegisterPageOneProps {
  handleNext: () => any;
}

export const RegisterPageOne: React.FC<IRegisterPageOneProps> = ({
  handleNext,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { email, password, passwordConfirmation, error } = register;

  const handleSubmit = async () => {
    setIsLoading(true);

    const registrationErrors: IValidationErrorResponse = {
      message: 'The given data was invalid',
      errors: {},
    };

    if (!email) {
      registrationErrors.errors['email'] = 'The email field is required';
    }

    try {
      await api.auth.getEmailAvailability(email, password);
    } catch (error: any) {
      registrationErrors.errors = error.response.data.errors;
    }

    if (!password) {
      registrationErrors.errors['password'] = 'The password field is required';
    }

    if (Object.keys(registrationErrors.errors).length > 0) {
      dispatch(actions.register.error(registrationErrors));
      setIsLoading(false);
      return;
    }

    handleNext();
    setIsLoading(false);
  };

  return (
    <>
      <AuthHeader
        includeLogo={true}
        title={'Create account'}
        subtitle={'To continue, please enter your details below'}
      />

      <section className={'w-5/6 md:1/2 lg:w-1/3'}>
        <div className={'flex justify-between mb-5'}>
          <div className={'mb-4 flex flex-col w-full'}>
            <Input
              placeholder={'enter email'}
              required={true}
              type={'text'}
              label={'Email'}
              name={'email'}
              value={email}
              error={error}
              onChange={(e) =>
                dispatch(
                  actions.onChange({
                    key: 'email',
                    value: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>

        <div className={'mb-4 w-full'}>
          <Input
            placeholder={'enter password'}
            required={true}
            type={'password'}
            label={'Password'}
            name={'password'}
            value={password}
            error={error}
            onChange={(e) =>
              dispatch(
                actions.onChange({
                  key: 'password',
                  value: e.target.value,
                })
              )
            }
          />
        </div>

        <div className={'mt-10 flex align-items flex-col text-center'}>
          <Button
            handleOnClick={() => handleSubmit()}
            className={
              'bg-baby-blue w-full rounded-md py-3 mb-8 font-semibold text-md tracking-wide'
            }
            isLoading={isLoading}
          >
            Submit
          </Button>
        </div>
        <hr className={'border border-neutral-light mb-6'} />
        <p
          className={
            'text-neutral-darkest text-md mt-4 text-center font-semibold'
          }
        >
          Already have an account?
        </p>
        <Button
          className={
            'w-full bg-secondary font-semibold text-white px-4 py-3 text- rounded-xl mt-6 mb-4'
          }
          handleOnClick={(e) => {
            history.push('/login');
          }}
        >
          Sign In
        </Button>
      </section>
    </>
  );
};
