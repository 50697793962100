import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { ISeasonSelect } from './reducer';

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) => {
      const {
        scheduleId,
        name,
        majors,
        minors,
        seasons: seasonsSelected,
      } = state$.value.pages.dashboard.modals.editTrack;
      const schedule = state$.value.pages.dashboard.currentTrack.schedule;

      let seasons = seasonsSelected
        .filter((season: ISeasonSelect) => season.checked)
        .map((season: ISeasonSelect) => season.value);

      return from(
        api.schedules.updateSchedule(
          scheduleId,
          name,
          majors,
          schedule.terms,
          seasons
        )
      ).pipe(
        mergeMap(() => {
          return of(actions.update.done());
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

const open: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.open),
    mergeMap(() => of(actions.fetchConfiguration(), actions.set.start()))
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.open),
    mergeMap(() => {
      const schedule = state$.value.pages.dashboard.currentTrack.schedule;

      return of(
        actions.set.done({
          name: schedule.name,
          majors: schedule.majors,
          minors: schedule.minors,
          seasons: schedule.configuration.availableSeasons,
        })
      );
    })
  );

const seasonSelect: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.season.select),
    mergeMap(({ payload }) => {
      let seasons = [...state$.value.pages.dashboard.modals.editTrack.seasons];

      seasons = seasons.map((season: ISeasonSelect, index: number) => {
        if (index == payload) {
          season.checked = !season.checked;
        }
        return season;
      });

      return of(actions.season.set(seasons));
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

const fetchConfiguration: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetchConfiguration),
    map(() => actions.loadConfiguration.start())
  );

const loadConfiguration: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadConfiguration.start),
    switchMap(() =>
      from(
        api.schools.getSchoolConfiguration(
          state$.value.common.user.info.schoolId
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadConfiguration.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.loadConfiguration.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(
  open,
  seasonSelect,
  load,
  update,
  fetchConfiguration,
  loadConfiguration
);
