import { createAction } from 'redux-act';
import { ICourseAvailability } from '../../../../../../../api/courses/availbility';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/availability/list] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/availability/list] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/availability/list] load.error'
  ),
  done: createAction<ICourseAvailability[]>(
    '[pages/admin/dashboard/settings/course/availability/list] load.done'
  ),
};

export const destroy = {
  start: createAction<number>(
    '[pages/admin/dashboard/settings/course/availability/list] destroy.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/availability/list] destroy.error'
  ),
  done: createAction<any>(
    '[pages/admin/dashboard/settings/course/availability/list] destroy.done'
  ),
};
