import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import * as searchActions from "../search/actions";
import { useSelector } from '../../../../../../../../infrastructure/selector';
import { RequirementCourses } from '../../components/requirement-courses';

export const CreateDegreeRequirementCourseList: React.FC = () => {

  const dispatch = useDispatch();

  const { list, isLoading, assigned, schoolId, selected, gradeLevels, availableSeasons, error } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      gradeLevels: state.common.configs.configuration.gradeLevels,
      availableSeasons: state.common.configs.configuration.availableSeasons,
      list: state.pages.admin.settings.degrees.requirements.create.courses.list,
      assigned: state.pages.admin.settings.degrees.requirements.create.courses.assigned,
      selected: state.pages.admin.settings.degrees.requirements.create.courses.selected,
      isLoading: state.pages.admin.settings.degrees.requirements.create.courses.isLoading,
      error: state.pages.admin.settings.degrees.requirements.create.requirement.error
    }),
    shallowEqual
  );

  useEffect(() => {
    if(schoolId) {
      dispatch(actions.fetch());
    }
  }, [schoolId]);

  return (
    <>
      <RequirementCourses
        isEdit={true}
        schoolId={schoolId}
        gradeLevels={gradeLevels}
        availableSeasons={availableSeasons}
        list={list}
        assigned={assigned}
        selected={selected}
        error={error}
        isLoading={isLoading}
        handleOnSearch={(value) => dispatch(searchActions.onSearch(value))}
        handleFetch={() => dispatch(actions.fetch())}
        handleAssign={(select) => dispatch(actions.assign(select))}
        handleUnAssign={(select) => dispatch(actions.unAssign(select))}
        handleSelect={(select) => dispatch(actions.select(select))}
        handleScheduleRequirementChange={(value) => dispatch(actions.onScheduleRequirementChange(value))}
        />
    </>
  )
}
