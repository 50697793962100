import React from 'react';
import { Header } from '../header';
import { Sidebar } from '../sidebar';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { Footer } from '../footer';
import { SidebarNavigation } from '../sidebar-navigation';
import {UserRoleType} from "../../constants";
import { NotificationBanners } from '../../domains/student/pages/planner/banners';

export interface ILayoutProps {
  children: JSX.Element;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const { isExpanded, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isExpanded: state.shared.sidebar.isExpanded,
    }),
    shallowEqual
  );

  let sidebarExpandedStyles = 'bg-neutral-lightest w-full';
  let sidebarCollapsedStyles = 'bg-neutral-lightest flex-grow';
  let contentStyles = '';

  if (isExpanded) {
    contentStyles = sidebarExpandedStyles;
  } else {
    contentStyles = sidebarCollapsedStyles;
  }

  return (
    <div className={"flex"}>
      {user.roleId === UserRoleType.Administrator && <SidebarNavigation />}
      <div className={'flex flex-col h-screen relative w-full'} style={{ marginLeft: user.roleId === UserRoleType.Administrator ? 72 : 0}}>
        <Header />
        <section className={'flex'}>
          <section className={contentStyles}>{children}</section>
        </section>
        <Footer />
      </div>
    </div>

  );
};
