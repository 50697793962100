import { createReducer } from 'redux-act';
import * as actions from '../actions';
import { ISelectOption } from '../../../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import { IDegreeRequirementsResponse } from '../../../../../../../../api/degree';

interface IState {
  isLoading: boolean;
  name: string;
  type: ISelectOption;
  numberRequired: number|null;
  color: string;
  error: IValidationErrorResponse;
  fallbackRequirement: {
    id: number|null;
    name: string;
  };
  fallbackRequirementOptions: IDegreeRequirementsResponse[];
}

const initialState: IState = {
  isLoading: false,
  name: "",
  type: {
    label: "Classes",
    value: DegreeRequirementUnitType.Courses.toString()
  },
  numberRequired: null,
  color: "#000",
  fallbackRequirement: {
    id: null,
    name: ""
  },
  fallbackRequirementOptions: [],
  error: {errors: {}, message: ""}
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
  fallbackRequirement: {
    id: null,
    name: ""
  },
  fallbackRequirementOptions: []
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    name: payload.name,
    type: {
      label: payload.typeLabel,
      value: payload.type.toString()
    },
    numberRequired: payload.numberRequired,
    fallbackRequirement: {
      id: payload.fallbackRequirement.id,
      name: payload.fallbackRequirement.name
    },
    color: payload.color,
  };
});

reducer.on(actions.loadRequirements.done, (state, payload) => {
  return {
    ...state,
    fallbackRequirementOptions: payload
  };
});

reducer.on(actions.reset, (state, payload) => {
  return {
    ...state,
    fallbackRequirement: {
      id: null,
      name: ""
    }
  };
});
reducer.on(actions.loadRequirements.start, (state, payload) => {
  return {
    ...state,
    fallbackRequirementOptions: []
  };
});
export const degreeRequirementInfoReducer = reducer;
