import { SimpleAction } from '../../../../../../components/simple-action';
import { FaPlus } from 'react-icons/fa6';
import React, {useEffect} from 'react';
import { shallowEqual } from 'react-redux';
import { CiCalendarDate } from 'react-icons/ci';
import { Input, InputSize } from '../../../../../../components/input';
import { DateSelect } from '../../../../../../ui/date-picker';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {academicYear, IUpdateAcademicYearRequest} from '../../../../../../api/academic-years';
import { useSelector } from '../../../../../../infrastructure/selector';
import Skeleton from 'react-loading-skeleton';
import {useDegreelyMutation} from "../../../../../../infrastructure/query";
import {debounce} from "lodash";
import {ToastVariant, useToasts} from "../../../../../../hooks/toasts";
import { IValidationErrorResponse } from '../../../../../../types/error';
import { AxiosError } from 'axios';


export const useAcademicYear = (schoolId: number, academicYearId: number, disabled: boolean) => {
  return useQuery(`academicYear-${academicYearId}`, () => academicYear.getAcademicYearById(schoolId, academicYearId), {
    enabled: !!schoolId && !!academicYearId && !disabled,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
}

export const useUpdateAcademicYear = (request: IUpdateAcademicYearRequest, onSuccess: () => any) => {
  return useDegreelyMutation({
    mutationFn: () => academicYear.updateAcademicYear(request),
    handleOnSuccess: () => {
        onSuccess();
    },
    handleOnError: (error) => {
        console.log('Error updating academic year', error);
    }
  });
}

export interface IYearDetailsProps {
  isDisabled?: boolean;
  handleModalOpen: () => any;
}

export const YearDetails: React.FC<IYearDetailsProps> = ({ isDisabled, handleModalOpen }) => {

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      academicYearId: state.common.match.params.id,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useAcademicYear(schoolId, academicYearId, !!isDisabled);

  const year = data?.data || {
    label: '',
    startDate: new Date(),
    endDate: new Date(),
  };

  const [addToast] = useToasts();
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = React.useState(false);

  const [label, setLabel] = React.useState(year.label);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date(year.startDate));
  const [endDate, setEndDate] = React.useState<Date | null>(new Date(year.endDate));
  const [errors, setErrors] = React.useState<IValidationErrorResponse>({ errors: {}, message: "" });

  useEffect(() => {
    setErrors({ errors: {}, message: "" })
    if(data?.data?.label !== label) {
     setLabel(data?.data.label ?? "");
    }
    if(new Date(data?.data?.startDate ?? "") !== startDate) {
      setStartDate(new Date(data?.data.startDate ?? "") ?? new Date());
    }
    if(new Date(data?.data?.endDate ?? "") !== endDate) {
      setEndDate(new Date(data?.data.endDate ?? "") ?? new Date());
    }
  }, [data?.data.label, data?.data.startDate, data?.data.endDate]);

  const mutation = useMutation({
    mutationFn: (request: IUpdateAcademicYearRequest) => academicYear.updateAcademicYear(request),
    onSuccess: () => {
      addToast({message: 'Academic year updated successfully', variant: ToastVariant.Success});
      queryClient.invalidateQueries(`academicYear-${academicYearId}`);
    },
    onError: (error: AxiosError<IValidationErrorResponse>) => {
      setErrors(error?.response?.data ?? { errors: {}, message: "" })
      addToast({message: 'Error updating academic year', variant: ToastVariant.Error});
    }
  });

  const handleUpdate = debounce((obj) => {
    setErrors({ errors: {}, message: "" })
    mutation.mutate(obj);
  }, 1000);

  useEffect(() => {

    if(!isEditing) {
      return;
    }

    handleUpdate({
        schoolId: schoolId,
        academicYearId: academicYearId,
        startDate: startDate,
        endDate: endDate,
        name: label
    });
  }, [label, startDate, endDate]);

  if(isLoading) {
    return (
      <div>
        <div>
          <Skeleton width={'20%'} className={'mt-8 h-5'} />
          <div>
            <Skeleton inline={true} width={'32.5%'} className={'mr-2 mt-8 h-10'} />
            <Skeleton inline={true} width={'32.5%'} className={'mr-2 mt-8 h-10'} />
            <Skeleton inline={true} width={'32.5%'} className={'mt-8 h-10'} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <section className={'mb-8'}>
        {isDisabled && <div className={"flex items-center justify-center mt-12"}>
          <div className={"text-center flex flex-col justify-center items-center"}>
            <CiCalendarDate className={"fill-neutral-light stroke-neutral-light text-3xl mb-1"} />
            <h3 className={"text-black font-semibold"}>Year Dates</h3>
            <p className={"text-neutral-dark text-sm"}>You haven't added any important dates to this Academic Year</p>
            <div className={"mt-4"}>
              <SimpleAction icon={FaPlus} label={"Add Dates"} onClick={() => handleModalOpen()} />
            </div>
          </div>
        </div>}

        {!isDisabled && label && <div className={"flex mt-8 w-full"}>
          <div className={"flex items-center w-full"}>
            <div className={"w-1/3"}>
              <div className='pr-4'>
                <Input
                  handleOnFocus={() => setIsEditing(true)}
                  handleOnBlur={() => setIsEditing(false)}
                  value={label}
                  size={InputSize.md}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  type={"text"}
                  required={true}
                  name={"name"}
                  placeholder={"2024 - 2025 Academic Year"}
                  label={"Academic Year Name"}
                />
              </div>
            </div>
            <div className={"w-1/3"}>
              <div className='pr-4'>
                <DateSelect error={errors} name={"startDate"} label={`Academic Year Start Date`} onChange={(date) => {
                    setStartDate(date);
                    handleUpdate({
                        schoolId: schoolId,
                        academicYearId: academicYearId,
                        startDate: date,
                        endDate: endDate,
                        name: label
                    });
                }} selected={new Date(startDate ?? "")} />
              </div>
            </div>
            <div className={"w-1/3"}>
              <div className=''>
                <DateSelect error={errors} name={"endDate"} label={`Academic Year End Date`} onChange={(date) => {
                    setEndDate(date);
                    handleUpdate({
                        schoolId: schoolId,
                        academicYearId: academicYearId,
                        startDate: startDate,
                        endDate: date,
                        name: label
                    });
                }} selected={new Date(endDate ?? "")} />
              </div>
            </div>
          </div>
        </div>}
      </section>
    </>
  )
}
