import React, { useEffect, useState } from 'react';
import { Input } from '../../../../../../../../components/input';
import { SelectField } from '../../../../../../../../ui';
import { Label } from '../../../../../../../../ui/label';
import { BlockPicker } from 'react-color';
import { REQUIREMENT_COLORS } from '../../../../../../../../constants';
import { ISelectOption } from '../../../../../../../../ui/select';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { IDegreeRequirementsResponse } from '../../../../../../../../api/degree';

export interface IRequirementDetailsProps {
  name: string;
  color: string;
  numberRequired: string;
  type: ISelectOption;
  error?: IValidationErrorResponse;
  onTypeChange?: (option: ISelectOption) => void;
  onColorChange?: (color: string) => void;
  onChange?: (key: string, value: string) => void;
  handleRequirementsFetch?: () => void;
  fallbackRequirement: {
    label: string
    value: string
  };
  fallbackRequirementOptions?: IDegreeRequirementsResponse[];
}

export const RequirementDetails: React.FC<IRequirementDetailsProps> = ({ name, color, numberRequired, type, error, onChange, onTypeChange, onColorChange, handleRequirementsFetch, fallbackRequirementOptions, fallbackRequirement }) => {

  const [showColorPicker, setShowColorPicker] = useState(false);

  const options: ISelectOption[] = [
    { label: "Classes", value: DegreeRequirementUnitType.Courses.toString() },
    { label: "Units", value: DegreeRequirementUnitType.Credits.toString() },
  ]

  useEffect(() => {
    if(!!handleRequirementsFetch) {
      handleRequirementsFetch();
    }
  }, []);

  return (
    <>
      <div className={"flex items-center mt-4"}>
        <div className={"w-1/3 mr-2"}>
          <Input disabled={!onChange} value={name} onChange={(e) => !!onChange && onChange("name", e.target.value)} type={"text"} required name={"name"} label={"Name"} placeholder={"Math"} error={error} />
        </div>
        <div className={"w-1/3 mr-2"}>
          <SelectField disabled={!onChange}  defaultValue={[type]} onChange={(option) => onTypeChange && onTypeChange(option)} required={true} label={"Classes or Units"} name={"type"} error={error ?? { errors: {}, message: ""}} placeholder={"Select type"} options={options} />
        </div>
        <div className={"w-1/3"}>
          <Input disabled={!onChange} value={numberRequired} error={error} onChange={(e) => onChange && onChange("numberRequired", e.target.value)} type={"text"} required name={"numberRequired"} label={"Number Required"} placeholder={"Enter quantity"} />
        </div>
      </div>
      <div className='flex items-center mt-4'>
        <div className="w-1/2 mr-2 relative">
          <Label name={'Color'} required={true} />
          <div className={"flex items-center"}>
            <div
              className={
                'h-10 w-1/2 rounded-l-lg flex items-center justify-center cursor-pointer'
              }
              style={{ backgroundColor: color }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
            </div>
            <div className={'w-1/2 text-sm rounded-r-lg border border-black text-black h-10 justify-center flex items-center'}>{color}</div>
          </div>
          {showColorPicker && (
            <div className={'absolute z-10'} style={{ top: 72, left: -23 }}>
              {!onColorChange && <div style={{ backgroundColor: color, width: "100%" }}/>}
              {!!onColorChange && <BlockPicker
                colors={REQUIREMENT_COLORS}
                color={color}
                onChangeComplete={(color) => {
                  onColorChange && onColorChange(color.hex);
                  setShowColorPicker(!showColorPicker);
                }}
              />}
            </div>
          )}
        </div>
        <div className={"w-1/2 ml-2"}>
          <SelectField
            disabled={!onChange}
            defaultValue={!!fallbackRequirement.label ? [fallbackRequirement] : []}
            onChange={(option) => onChange && onChange("fallbackRequirementId", option.value)}
            required={false}
            isClearable={true}
            label={"Fallback Requirement Category"}
            name={"fallbackCategory"}
            error={error ?? { errors: {}, message: ""}}
            placeholder={!!fallbackRequirement.label && !onChange ? fallbackRequirement.label : "Select a fallback category"}
            options={fallbackRequirementOptions?.map((requirement) => ({ label: requirement.name, value: requirement.id.toString() })) ?? []}
          />
        </div>
      </div>
    </>
  )
}

RequirementDetails.defaultProps = {
  name: "",
  color: "",
  numberRequired: "",
  type: { label: "", value: "" },
  error: { errors: {}, message: "" },
  onChange: undefined,
  onTypeChange: undefined,
  onColorChange: undefined
}
