import { createAction } from 'redux-act';
import { ISelectOption } from '../../../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { IDegreeRequirementsResponse } from '../../../../../../../../api/degree';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/degrees/requirement/edit/info'
);

export const onChange = createAction<{ [key:string]: string }>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirement] onChange'
);

export const onTypeChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirement] onTypeChange'
);

export const onColorChange = createAction<string>(
  '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirement] onColorChange'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirements] loadRequirements.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirements] loadRequirements.error'
  ),
  done: createAction<IDegreeRequirementsResponse[]>(
    '[pages/admin/dashboard/settings/degrees/edit/requirements/create/requirements] loadRequirements.done'
  ),
};


