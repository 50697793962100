import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import GoogleAnalytics from '../../../../../analytics';

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnApprovalChange: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.approve.done, actions.disapprove.done),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
        return from(
          api.courseRequests.getCourseRequests(state$.value.common.match.params.id)
        ).pipe(
          mergeMap(({ data }) => {
            return of(actions.load.done(data));
          })
        )
      }
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const approve: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.approve.start),
    switchMap(() => {
        return from(
          api.courseRequests.approveCourseRequest(state$.value.common.match.params.id)
        ).pipe(
          mergeMap(({ data }) => {
            return of(actions.approve.done());
          })
        )
      }
    ),
    catchError((error, source$) =>
      of(actions.approve.error(error)).pipe(concat(source$))
    )
  );

const disapprove: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.disapprove.start),
    switchMap(() => {
        return from(
          api.courseRequests.disApproveCourseRequest(state$.value.common.match.params.id)
        ).pipe(
          mergeMap(({ data }) => {
            return of(actions.disapprove.done());
          })
        )
      }
    ),
    catchError((error, source$) =>
      of(actions.disapprove.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, approve, disapprove, fetchOnApprovalChange);
