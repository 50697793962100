import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import * as actions from "./actions";
import { RequirementDetails } from '../../components/requirement-details';

export const CreateRequirementDetails: React.FC = () => {

  const dispatch = useDispatch();

  const { name, color, type, error, numberRequired, fallbackRequirementOptions } = useSelector(
    (state) => ({
      name: state.pages.admin.settings.degrees.requirements.create.requirement.name,
      color: state.pages.admin.settings.degrees.requirements.create.requirement.color,
      numberRequired: state.pages.admin.settings.degrees.requirements.create.requirement.numberRequired,
      type: state.pages.admin.settings.degrees.requirements.create.requirement.type,
      error: state.pages.admin.settings.degrees.requirements.create.requirement.error,
      fallbackRequirementOptions: state.pages.admin.settings.degrees.requirements.create.requirement.fallbackRequirementOptions,
    }),
    shallowEqual
  );

  return (
    <>
      <RequirementDetails
        name={name}
        color={color}
        numberRequired={numberRequired}
        error={{errors: {}, message: ""}}
        onColorChange={(value) => dispatch(actions.onColorChange(value))}
        handleRequirementsFetch={() => dispatch(actions.fetch())}
        fallbackRequirementOptions={fallbackRequirementOptions}
        fallbackRequirement={{ value: "0", label: "" }}
        type={type}
        onTypeChange={(option) => dispatch(actions.onTypeChange(option))}
        onChange={(key, value) => dispatch(actions.onChange({
        key,
        value
      }))} />
    </>
  )
}
