import React from 'react';
import { Modal } from '../../../../../../components/modal';
import { Badge } from '../../../../../../ui';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import {
  ICourseInfo,
  IRequirementCategoryStatus,
} from '../../../../../../api/requirements';
import courseStatusService from '../../../../../../services/course-status/CourseStatusService';
import * as actions from './actions';
import SkeletonModal from '../../../../../../components/modal/skeleton';

interface IRequirementCategoryDetailModalState {
  isOpen: boolean;
  isLoading: boolean;
  category: IRequirementCategoryStatus;
  courses: ICourseInfo[];
}

export const RequirementCategoryDetailModal: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpen, isLoading, category, courses } =
    useSelector<IRequirementCategoryDetailModalState>(
      (state) => ({
        isOpen: state.pages.dashboard.modals.requirementCategory.isOpen,
        isLoading: state.pages.dashboard.modals.requirementCategory.isLoading,
        category:
          state.pages.dashboard.modals.requirementCategory.details.category,
        courses:
          state.pages.dashboard.modals.requirementCategory.details.courses,
      }),
      shallowEqual
    );

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  let unit = category.required > 1 ? category.unit + 's' : category.unit;

  return (
    <Modal
      handleClose={() => dispatch(actions.close())}
      renderHeader={() => <></>}
      renderBody={() => <></>}
      renderFooter={() => <></>}
      isOpen={true}
    >
      <div>
        <div
          className={
            'flex items-center justify-between border-b border-neutral-light pb-4 mb-8'
          }
        >
          <h2
            className={'text-3xl font-semibold'}
            style={{ color: category.color }}
          >
            {category.name}
          </h2>
          <div>
            <span
              className={'text-5xl font-bold mr-4'}
              style={{ color: category.color }}
            >
              {category.completed}
            </span>
            <span
              className={
                'text-neutral-dark font-semibold uppercase tracking-wide text-sm'
              }
            >
              / {category.required} {unit}{' '}
            </span>
          </div>
        </div>
        {/* TODO: Need to figure out a better way of doing this in the modal */}
        <section
          className={'flex flex-col overflow-y-scroll'}
          style={{ maxHeight: 275 }}
        >
          <div className={'flex items-center justify-between'}>
            <span className={'text-neutral-dark text-xs mb-2'}>Course</span>
            <span className={'text-neutral-dark text-xs mb-2'}>Status</span>
          </div>
          {!!courses.length &&
            courses.map((course: ICourseInfo) => {
              return (
                <div className="p-2 bg-neutral-lightest flex items-center justify-between rounded-lg mb-3">
                  <div className="flex items-baseline">
                    <span className={'text-black mr-2 font-medium'}>
                      {course.name}
                    </span>
                    <span className={'text-neutral-dark text-xs'}>
                      {course.code}
                    </span>
                  </div>
                  <Badge
                    variant={courseStatusService.getBadgeVariantByCourseStatus(
                      course.status.status
                    )}
                  >
                    {courseStatusService.buildLabel(
                      course.status.status,
                      course.status.termSeason,
                      course.status.termYear
                    )}
                  </Badge>
                </div>
              );
            })}
        </section>
      </div>
    </Modal>
  );
};
