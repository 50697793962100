import React from 'react';
import { SearchInput } from '../../../../../../../../components/search-input';

export interface ISearchCoursesProps {
  handleOnChange: (value: string) => void;
}
export const SearchCourses: React.FC<ISearchCoursesProps> = ({ handleOnChange }) => {

  return (
    <>
      <SearchInput placeholder={"Search courses"} handleOnChange={(e) => handleOnChange(e.target.value)} />
    </>
  )
}
