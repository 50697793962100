import { createAction } from 'redux-act';
import { ICourseRequest } from '../../../../../api/course-requests';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/admin/dashboard/student/course-requests] fetch');

export const load = {
  start: createAction('[pages/admin/dashboard/student/course-requests] load.start'),
  error: createAction<any>('[pages/admin/dashboard/student/course-requests] load.error'),
  done: createAction<ICourseRequest[]>(
    '[pages/admin/dashboard/student/course-requests] load.done'
  ),
};

export const approve = {
  start: createAction('[pages/admin/dashboard/student/course-requests] approve.start'),
  error: createAction<any>('[pages/admin/dashboard/student/course-requests] approve.error'),
  done: createAction(
    '[pages/admin/dashboard/student/course-requests] approve.done'
  ),
};

export const disapprove = {
  start: createAction('[pages/admin/dashboard/student/course-requests] disapprove.start'),
  error: createAction<any>('[pages/admin/dashboard/student/course-requests] disapprove.error'),
  done: createAction(
    '[pages/admin/dashboard/student/course-requests] disapprove.done'
  ),
};
