import { createReducer } from 'redux-act';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { DegreeRequirementUnitType } from '../../../../../../../../api/admin/degrees';
import * as actions from './actions';

interface IState {
  isLoading: boolean;
  name: string;
  color: string;
  numberRequired: number;
  type: DegreeRequirementUnitType;
  error: IValidationErrorResponse;
}

const initialState: IState = {
  isLoading: false,
  name: '',
  color: '#000',
  numberRequired: -1,
  type: DegreeRequirementUnitType.Courses,
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.create.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.create.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.create.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    name: '',
    numberRequired: -1,
    type: DegreeRequirementUnitType.Courses,
    color: '#000',
  };
});

reducer.on(actions.onChange, (state, payload) => ({
  ...state,
  //@ts-ignore
  [payload.key]: payload.value,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.onTypeChange, (state, payload) => ({
  ...state,
  //@ts-ignore
  type: payload.value,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.create.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

export const createRequirementReducer = reducer;
