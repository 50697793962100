export const routes = {
  planner: {
    index: "/planner/*",
    schedules: {
      create: "/schedules/create",
      show: "/schedules/:id",
    }
  },
  registration: {
    index: "/registration/:id/*",
    preferences: {
      survey: {
        index: "/registration/:id/preferences/survey",
      },
    }
  },
  courseRequest: {
    index: "/course-requests",
  }
}
