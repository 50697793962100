import React from 'react';
import { UserAvatar } from '../../../../../../components/avatar';
import { Flags } from '../flags';
import { Notes } from '../notes';
import { Button } from '../../../../../../ui';
import {
  ButtonBorderColorVariant,
  ButtonBorderRadius,
  ButtonSize,
  ButtonStyle,
  ButtonVariant
} from '../../../../../../ui/button';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../common/routes';
import { getAdminRoute } from '../../../../routes';

export const StudentInfoDetails: React.FC = () => {

  const navigate = useNavigate();


  const {
    studentId,
    name,
    email,
    avatarUrl,
    estimatedGraduation,
    graduationStatus,
    isLoading,
  } = useSelector(
    (state) => ({
      studentId: state.common.match.params.id,
      name: state.pages.admin.dashboard.student.info.details.name,
      isLoading: state.pages.admin.dashboard.student.info.details.isLoading,
      avatarUrl: state.pages.admin.dashboard.student.info.details.avatarUrl,
      email: state.pages.admin.dashboard.student.info.details.email,
      graduationStatus:
      state.pages.admin.dashboard.student.info.details.graduationStatus,
      estimatedGraduation:
      state.pages.admin.dashboard.student.info.details.estimatedGraduation,
    }),
    shallowEqual
  );

  console.log(studentId);

  const isSchedulePath = useMatch(generatePath(getAdminRoute(Routes.admin.overview.students.show), {
    id: studentId
  }));

  const isProfilePath = useMatch(generatePath(getAdminRoute(Routes.admin.overview.students.profile), {
    id: studentId
  }));

  const isCourseRequestPath = useMatch(generatePath(getAdminRoute(Routes.admin.overview.students.courseRequests.index), {
    id: studentId
  }));

  if (isLoading) {
    return (
      <>
        <Skeleton height={30} width={'30%'} />
        <Skeleton className={'mt-2'} width={'10%'} />
        <Skeleton width={'10%'} />
        <Skeleton width={'30%'} />
      </>
    );
  }

  return (
    <section className={'flex mb-10 w-full'}>
      <div className={''}>
        <UserAvatar imageUrl={avatarUrl} size={95} />
      </div>
      <div className={'ml-4 w-full'}>
        <div className={'flex items-center'}>
          <h3 className={'text-2xl text-black mr-2'}>{name}</h3>
          <Flags />
          <Notes />
        </div>
        <span className={'text-neutral text-xs block'}>{email}</span>
        <div
          style={{ backgroundColor: graduationStatus.color }}
          className={'flex items-center py-1 px-2 mt-1 w-fit rounded-md'}
        >
              <span className={'text-black text-xs mr-1'}>
                {estimatedGraduation}
              </span>
          <span className={'text-black text-xs mr-1'}>|</span>
          <span className={'text-black text-xs'}>
                {graduationStatus.label}
              </span>
        </div>
        <div className={"flex items-center mt-4 w-full"}>
          <div className='w-2/12'>
            <div className='mr-4'>
              <Button
                handleOnClick={() => navigate(generatePath(getAdminRoute(Routes.admin.overview.students.show), {
                  id: studentId
                }))}
                size={ButtonSize.XSmall}
                variant={isSchedulePath ? ButtonVariant.Primary : ButtonVariant.White}
                borderColorVariant={ButtonBorderColorVariant.Primary}
                style={isSchedulePath ? ButtonStyle.Filled : ButtonStyle.Outline}
                radius={ButtonBorderRadius.FULL}
                shadow={true}
              >Schedule</Button>
            </div>

          </div>
          <div className='w-2/12'>
            <div className='mr-4'>
              <Button
                handleOnClick={() => navigate(generatePath(getAdminRoute(Routes.admin.overview.students.profile), {
                  id: studentId
                }))}
                size={ButtonSize.XSmall}
                variant={isProfilePath ? ButtonVariant.Primary : ButtonVariant.White}
                borderColorVariant={ButtonBorderColorVariant.Primary}
                style={isProfilePath ? ButtonStyle.Filled : ButtonStyle.Outline}
                radius={ButtonBorderRadius.FULL}
                shadow={true}
              >Profile</Button>
            </div>
          </div>
          <div className='w-2/12'>
            <Button
              handleOnClick={() => navigate(generatePath(getAdminRoute(Routes.admin.overview.students.courseRequests.index), {
                id: studentId
              }))}
              size={ButtonSize.XSmall}
              variant={isCourseRequestPath ? ButtonVariant.Primary : ButtonVariant.White}
              borderColorVariant={ButtonBorderColorVariant.Primary}
              style={isCourseRequestPath ? ButtonStyle.Filled : ButtonStyle.Outline}
              radius={ButtonBorderRadius.FULL}
              shadow={true}
            >Course Requests</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
