import React, { useEffect } from 'react';
import { Dropdown } from '../../../components/dropdown';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp';
import { Button, Checkbox } from '../../../ui';
import {
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../ui/checkbox';
import { Accordion } from '../../../ui/accordion';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../ui/button';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { useSelector } from '../../../infrastructure/selector';
import {IFilter} from "../../../types/filter";

export const CourseBinFilterDropdown: React.FC = () => {
  const { requirementCategories, grades, availableTerms, availableYears, userId } = useSelector(
    (state) => ({
      userId: state.common.user.info.id,
      grades: state.common.filters.courseBin.filters.grades,
      requirementCategories:
        state.common.filters.courseBin.filters.requirementCategories,
      availableYears:
      state.common.filters.courseBin.filters.availableYears,
      availableTerms:
      state.common.filters.courseBin.filters.availableTerms,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetch());
  }, [userId]);

  const handleClear = () => {
    dispatch(
      actions.apply({
        grades: [],
        requirementCategories: [],
        availableYears: [],
        availableTerms: [],
      })
    );
  };

  const handleApply = () => {
    dispatch(
      actions.apply({
        grades: [...grades],
        requirementCategories: [...requirementCategories],
        availableYears: [...availableYears],
        availableTerms: [...availableTerms],
      })
    );
  };

  return (
    <>
      <Dropdown
        maxHeight={475}
        renderTarget={({ isOpen, handleTrigger }) => (
          <div
            onClick={() => handleTrigger()}
            className={'w-full flex items-center justify-between'}
          >
            <span className={'text-neutral text-xs block'}>Filters</span>
            {!isOpen && (
              <RiArrowDownSLine className={'fill-baby-blue text-md'} />
            )}
            {isOpen && (
              <MdClose
                className={'fill-baby-blue text-xl'}
                onClick={() => handleClear()}
              />
            )}
          </div>
        )}
        renderBody={({ handleTrigger }) => (
          <>
            <Accordion
              defaultState={true}
              renderTarget={({ isOpen, handleTrigger }) => (
                <div
                  onClick={() => handleTrigger()}
                  className={
                    'flex justify-between border-b border-b-neutral-light mb-3'
                  }
                >
                  <span className={'text-baby-blue text-sm'}>Available Terms</span>
                  {!isOpen && (
                    <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                  )}
                  {isOpen && (
                    <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                  )}
                </div>
              )}
              renderBody={() => (
                <div className={'w-full'}>
                  <ul className={'w-full mb-2'}>
                    {availableTerms.map((filter: IFilter) => (
                      <li className={'w-full mb-2'}>
                        <Checkbox
                          border={CheckBoxBorder.Neutral}
                          borderSize={CheckBoxBorderSize.TwoPixel}
                          containerClassNames={
                            'w-full flex items-center justify-between'
                          }
                          labelClassNames={'text-neutral-dark text-xs'}
                          labelPosition={CheckboxLabelPosition.BeforeBox}
                          label={filter.label}
                          rounded={CheckBoxRounded.md}
                          onChange={() =>
                            dispatch(
                              actions.handleFilter({
                                key: 'availableTerms',
                                filter: filter,
                              })
                            )
                          }
                          checked={filter.selected}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            />

            <Accordion
              defaultState={true}
              renderTarget={({ isOpen, handleTrigger }) => (
                <div
                  onClick={() => handleTrigger()}
                  className={
                    'flex justify-between border-b border-b-neutral-light mb-3'
                  }
                >
                  <span className={'text-baby-blue text-sm'}>Available Years</span>
                  {!isOpen && (
                    <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                  )}
                  {isOpen && (
                    <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                  )}
                </div>
              )}
              renderBody={() => (
                <div className={'w-full'}>
                  <ul className={'w-full mb-2'}>
                    {availableYears.map((filter: IFilter) => (
                      <li className={'w-full mb-2'}>
                        <Checkbox
                          border={CheckBoxBorder.Neutral}
                          borderSize={CheckBoxBorderSize.TwoPixel}
                          containerClassNames={
                            'w-full flex items-center justify-between'
                          }
                          labelClassNames={'text-neutral-dark text-xs'}
                          labelPosition={CheckboxLabelPosition.BeforeBox}
                          label={filter.label}
                          rounded={CheckBoxRounded.md}
                          onChange={() =>
                            dispatch(
                              actions.handleFilter({
                                key: 'availableYears',
                                filter: filter,
                              })
                            )
                          }
                          checked={filter.selected}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            />
            <Accordion
              defaultState={true}
              renderTarget={({ isOpen, handleTrigger }) => (
                <div
                  onClick={() => handleTrigger()}
                  className={
                    'flex justify-between border-b border-b-neutral-light mb-3'
                  }
                >
                  <span className={'text-baby-blue text-sm'}>Grade</span>
                  {!isOpen && (
                    <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                  )}
                  {isOpen && (
                    <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                  )}
                </div>
              )}
              renderBody={() => (
                <div className={'w-full'}>
                  <ul className={'w-full mb-2'}>
                    {grades.map((grade: IFilter) => (
                      <li className={'w-full mb-2'}>
                        <Checkbox
                          border={CheckBoxBorder.Neutral}
                          borderSize={CheckBoxBorderSize.TwoPixel}
                          containerClassNames={
                            'w-full flex items-center justify-between'
                          }
                          labelClassNames={'text-neutral-dark text-xs'}
                          labelPosition={CheckboxLabelPosition.BeforeBox}
                          label={grade.label}
                          rounded={CheckBoxRounded.md}
                          onChange={() =>
                            dispatch(
                              actions.handleFilter({
                                key: 'grades',
                                filter: grade,
                              })
                            )
                          }
                          checked={grade.selected}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            />
            <Accordion
              defaultState={true}
              renderTarget={({ isOpen, handleTrigger }) => (
                <div
                  onClick={() => handleTrigger()}
                  className={
                    'flex justify-between border-b border-b-neutral-light mb-3'
                  }
                >
                  <span className={'text-baby-blue text-sm'}>Requirements</span>
                  {!isOpen && (
                    <IoIosArrowDown className={'fill-baby-blue text-lg'} />
                  )}
                  {isOpen && (
                    <IoIosArrowUp className={'fill-baby-blue text-lg'} />
                  )}
                </div>
              )}
              renderBody={() => (
                <div className={'w-full'}>
                  <ul className={'w-full mb-2'}>
                    {requirementCategories.map(
                      (requirementCategory: IFilter) => (
                        <li className={'w-full mb-2'}>
                          <Checkbox
                            border={CheckBoxBorder.Neutral}
                            borderSize={CheckBoxBorderSize.TwoPixel}
                            containerClassNames={
                              'w-full flex items-center justify-between'
                            }
                            labelClassNames={'text-neutral-dark text-xs'}
                            labelPosition={CheckboxLabelPosition.BeforeBox}
                            label={requirementCategory.label}
                            rounded={CheckBoxRounded.md}
                            onChange={() =>
                              dispatch(
                                actions.handleFilter({
                                  key: 'requirementCategories',
                                  filter: requirementCategory,
                                })
                              )
                            }
                            checked={requirementCategory.selected}
                          />
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            />
          </>
        )}
        renderFooter={({ handleTrigger }) => (
          <>
            <div className={'flex items-center justify-end pt-4'}>
              <Button
                classes={'mr-2'}
                style={ButtonStyle.Outline}
                variant={ButtonVariant.Primary}
                size={ButtonSize.XXXSmall}
                handleOnClick={() => {
                  handleTrigger();
                }}
              >
                Cancel
              </Button>
              <Button
                style={ButtonStyle.Filled}
                variant={ButtonVariant.Primary}
                size={ButtonSize.XXXSmall}
                handleOnClick={() => {
                  handleApply();
                  handleTrigger();
                }}
              >
                Apply
              </Button>
            </div>
          </>
        )}
      />
    </>
  );
};
