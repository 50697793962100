import {combineEpics, Epic, ofType} from "redux-observable";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import { from, of } from "rxjs";
import * as actions from "./actions";
import * as headActions from "../../head/actions";
import {api} from "../../../../../../api";
import {concat} from "rxjs/operators";
import {IEpic} from "../../../../../../infrastructure/selector";
import * as courseBinFilterActions from "../../../../../../common/filters/course-bin/actions";
import { IFilter } from "../../../../../../types/filter";
import { Helpers } from '../../../../../../helpers';

const fetchCourseBinOnFilter: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseBinFilterActions.apply),
    map(() => actions.fetch())
  );

const trackLoaded: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(headActions.load.done),
    map(() => actions.fetch())
  );

const trackUpdated: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(headActions.update.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { schedule } = state$.value.pages.admin.dashboard.student.schedule;
      const { filters } = state$.value.common.filters.courseBin;

      return from(
        api.schedules.getCourseBin({
          scheduleId: schedule.id,
          filters: {
            grades: filters.grade,
            requirementCategories: filters.requirementCategories
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableYears: filters.availableYears
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableTerms: filters.availableTerms
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
          },
        })
      ).pipe(
        mergeMap(({ data }) => {
          let filteredCourses = Helpers.filterCoursesForCourseBin(schedule, data);

          return of(actions.load.done(filteredCourses));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(
  fetch,
  load,
  trackLoaded,
  fetchCourseBinOnFilter
);
