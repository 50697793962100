import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { ProgressionList } from '../../../../../components/progression-list';
import { Add } from '../../../../../ui/add';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../common/routes';
import { SearchInput } from '../../../../../components/search-input';
import * as studentDetailDrawerActions from '../../../drawers/student-detail/actions';
import * as studentInvitationModalActions from '../../student-invitations/modals/invite-students/actions';
import { getAdminRoute } from '../../../routes';
import { SimpleAction } from '../../../../../components/simple-action';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { FiDownload } from 'react-icons/fi';

export const Students: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, students, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.pages.admin.dashboard.overview.students.isLoading,
      students: state.pages.admin.dashboard.overview.students.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  return (
    <div className={'mb-8'}>
      <div className="flex justify-between">
        <h5 className={'text-neutral-darkest text-lg font-semibold mb-2'}>
          Students
        </h5>
        <div className='w-1/2 flex items-center flex-end justify-end'>
            <SimpleAction
              label={'Export Course Requests'}
              icon={FiDownload}
              onClick={() => {
                dispatch(actions.downloadCourseRequests.start());
              }}
            />
            <SimpleAction
              label={'Manage Students'}
              icon={HiPlus}
              onClick={() => {
                dispatch(studentInvitationModalActions.open());
                navigate(getAdminRoute(Routes.admin.invitations.students.index));
              }}
            />
        </div>

      </div>
      <div className='mt-2 w-full'>
        <SearchInput
          placeholder={'Enter student name...'}
          handleOnChange={(e) => dispatch(actions.onChange(e.target.value))}
        />
      </div>
      <ProgressionList
        isLoading={isLoading}
        list={students}
        onClickAction={studentDetailDrawerActions.open}
      />
    </div>
  );
};
