import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { IPreppedStudentAccountInfo } from '../../../../../../api/admin/invites';
import * as alertActions from '../../../../../../common/alerts/actions';
import { AlertVariant } from '../../../../../../common/alerts/reducer';

const alertAfterSend: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.send.done),
    map(() =>
      alertActions.trigger({
        heading: 'We\'re processing sending the invites',
        message: "If you don't see the students in the list, wait a few seconds then refresh the page.",
        variant: AlertVariant.Success,
      })
    )
  );
const send: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.send.start),
    switchMap(({ payload }) =>
      from(
        api.admin.invites.sendStudentInvitations({
          invitations: [
            ...payload.map((student: IPreppedStudentAccountInfo) => ({
              name: student.name,
              studentId: student.studentId,
              schoolId: student.schoolId,
              email: student.email,
              degreeId: parseFloat(student.degree.value),
              startTerm: parseFloat(student.startTerm.value),
              startYear: student.startYear,
            })),
          ],
        })
      ).pipe(
        mergeMap(() => {
          return of(actions.send.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.send.error(error)).pipe(concat(source$))
    )
  );


const fetch: IEpic<any> = (action$) =>
    action$.pipe(
        ofType(actions.fetch),
        map(() => actions.load.start())
    );

const loadBlackBaudStudents: IEpic<any> = (action$, state$) =>
    action$.pipe(
        ofType(actions.loadBlackBaudStudents.start),
        switchMap(() =>
            from(api.admin.blackbaud.getStudents()).pipe(
                mergeMap(({ data }) => {
                    return of(actions.loadBlackBaudStudents.done(data));
                })
            )
        ),
        catchError((error, source$) =>
            of(actions.loadBlackBaudStudents.error(error)).pipe(concat(source$))
        )
    );



export const epic = combineEpics(fetch, send, loadBlackBaudStudents, alertAfterSend);
