import { useMutation } from 'react-query';

export interface  IDegreelyMutationProps<TData, TError, TVariables> {
  mutationFn: (payload: TVariables) => any;
  handleOnSuccess: (response: TData) => any;
  handleOnError: (error: TError) => any;
}
export function useDegreelyMutation<TData, TError, TVariables>({
   mutationFn,
   handleOnSuccess,
   handleOnError,
}: IDegreelyMutationProps<TData, TError, TVariables>) {
  return useMutation<TData, TError, TVariables>({
    mutationFn: mutationFn,
    onSuccess: (response) => {
      handleOnSuccess(response);
    },
    onError(error) {
      handleOnError(error);
    }
  })
}
