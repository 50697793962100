import React from 'react';
import DatePicker from 'react-datepicker';
import { IValidationErrorResponse } from '../../types/error';

export interface IDateSelectProps {
  label?: string;
  name: string;
  onChange: (date: Date | null) => void;
  selected: Date;
  error?: IValidationErrorResponse;
}

export const DateSelect: React.FC<IDateSelectProps> = ({ onChange, selected, label, name, error}) => {

  const hasError = () => {
    return (
      !!error &&
      !!Object.keys(error).length &&
      error?.errors.hasOwnProperty(name)
    );
  };

  return (
    <>
      {label &&  <label
        className={'block text-sm font-medium text-neutral-darkest mb-1 w-full'}
        role={'label'}
        htmlFor={label}
      >{label}</label>}
      <DatePicker name={name} className={"w-full border border-neutral-darkest rounded-xl px-3 py-3 text-neutral-dark text-sm cursor-pointer"} selected={selected} onChange={(date) => onChange(date)} />
      {hasError() && (
        <span className={'text-red text-xs'}>{error?.errors[name]}</span>
      )}
    </>
  )
}
