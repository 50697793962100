import React, { useEffect, useState } from 'react';
import { ToastVariant, useToasts } from '../../../../../../hooks/toasts';
import SkeletonModal from '../../../../../../components/modal/skeleton';
import { Button, Checkbox, SelectField } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../../../../../../api';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { LiaSchoolSolid } from 'react-icons/lia';
import classNames from "classnames";
import { academicYear } from '../../../../../../api/academic-years';
import { TermPills } from '../../create/courses/term-pills';

export interface IAddCourseModalProps {
  isOpen: boolean;
  handleClose: () => any;
}

export const useGetAvailableCoursesBySchoolId = (schoolId: number, isOpen: boolean) => {
  return useQuery(
    'availableAcademicYearCourses',
    () => api.courses.getCoursesBySchool({
      schoolId: schoolId,
      majorIds: [],
      minorIds: [],
    }),
    {
      enabled: !!schoolId && isOpen,
    });
}

export const useAddCoursesToAcademicYear = (schoolId: number, academicYearId: number, courseIds: number[]) => {
    return useMutation(
        'addCoursesToAcademicYear',
        () => api.academicYear.addCoursesToAcademicYear(schoolId, academicYearId, courseIds),
        {
            onSuccess: () => {

            }
        }
    )
}

export const AddCourseModal: React.FC<IAddCourseModalProps> = ({ isOpen, handleClose }) => {
    const queryClient = useQueryClient();
    const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [selectedTermId, setSelectedTermId] = useState(0);

    const handleCourseSelect = (courseId: number) => {
        if (selectedCourses.includes(courseId)) {
            setSelectedCourses(selectedCourses.filter((id) => id !== courseId));
        } else {
            setSelectedCourses([...selectedCourses, courseId]);
        }
    }

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const [addToast] = useToasts();

  const addCoursesToAcademicYear = useMutation(
      'addCoursesToAcademicYear',
      () => api.academicYear.addCoursesToAcademicYear(schoolId, academicYearId, selectedCourses),
      {
          onSuccess: () => {
              handleClose();
              setSelectedCourses([]);
              queryClient.invalidateQueries(`academicYearCourses-${academicYearId}`);
              addToast({
                  variant: ToastVariant.Success,
                  message: "Courses added to academic year successfully!"
              });
          }
      }
  )

  const addCoursesToAcademicYearTerm = useMutation(
    'addCoursesToAcademicYearTerm',
    () => api.academicYear.addCoursesToAcademicYearTerm(schoolId, academicYearId, selectedTermId, selectedCourses),
    {
      onSuccess: () => {
        handleClose();
        setSelectedCourses([]);
        queryClient.invalidateQueries(`academicYearCourses-${academicYearId}-${selectedTermId}`);
        addToast({
          variant: ToastVariant.Success,
          message: "Courses added to academic year term successfully!"
        });

      }
    }
  )

  const { data, isLoading, error } = useGetAvailableCoursesBySchoolId(schoolId, isOpen);

  const { data: academicYearTermsData, isLoading: academicYearTermsLoading, error: academicYearTermsError } = useQuery('academicYearTerms', () => academicYear.getAcademicYearTermsByAcademicYearId(schoolId, academicYearId), {
    enabled: !!schoolId && !!academicYearId && isOpen,
  });

  const list = data?.data;

  const handlePillSelect = (term: any, index: number) => {
    setSelectedTermId(term?.id ?? 0);
  }

    const handleSelectAll = () => {
        if (isSelectAll) {
            setSelectedCourses([]);
        } else {
            setSelectedCourses(list?.length ? list.map((course) => course.id) : []);
        }
        setIsSelectAll(!isSelectAll);
    }

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => handleClose()} />;
  }

  return (
    <>
      <Modal
        handleClose={() => {
          handleClose();
          setSelectedCourses([]);
        }}
        isOpen={isOpen}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-6'}>
              <div className="mr-2 w-2/12">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => {
                    handleClose();
                    setSelectedCourses([]);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={"w-2/12"}>
                <Button
                  handleOnClick={() => !!selectedTermId ? addCoursesToAcademicYearTerm.mutate() : addCoursesToAcademicYear.mutate()}
                  isLoading={addCoursesToAcademicYearTerm.isLoading || addCoursesToAcademicYear.isLoading}
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Primary}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      >
          <div className={'flex flex-col'}>
            <section className={"flex items-center justify-center"}>
              <div className={"text-center flex flex-col justify-center items-center"}>
                <LiaSchoolSolid className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
                <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Add Course</h2>
              </div>
            </section>
          </div>

        <TermPills onPillSelect={(term, index) => handlePillSelect(term, index)}/>

        <section className={"flex items-center justify-between px-2 mb-4"}>
            <Checkbox label={"Select All"} labelClassNames={"text-neutral-darkest text-xs font-semibold ml-4 cursor-pointer"} onChange={() => handleSelectAll()} checked={selectedCourses.length === list?.length} />
            <div className={"text-neutral-light text-xs"}>{selectedCourses.length} selected</div>
        </section>

        <section className={"flex flex-col overflow-auto"} style={{ maxHeight: 350 }}>
          {list?.length && list.map((course) => {

            const containerClasses = classNames("flex items-center border-b-neutral-lightest border-b py-4 px-2 cursor-pointer", {
                'border-b-baby-blue': selectedCourses.includes(course.id),
                'bg-baby-blue-lightest': selectedCourses.includes(course.id),
            })

            return (
              <div className={containerClasses} onClick={() => handleCourseSelect(course.id)}>
                <div className='w-1/12'>
                  <Checkbox onChange={() => false} checked={selectedCourses.includes(course.id)} />
                </div>
                <div className={"w-9/12"}>
                  <div className={"text-neutral-darkest font-medium text-sm"}>{course.name}</div>
                </div>
                <div className={"w-3/12"}>
                  <div className={"text-neutral-darkest font-medium text-sm"}>{course.credits} Units</div>
                </div>
              </div>
            )
          })}
        </section>

      </Modal>
    </>
  );
};
