import { combineReducers } from 'redux';

import { adminDashboardReducer as dashboard } from './overview/reducer';
import { adminSettingsReducer as settings } from './manage/reducer';
import { studentAccountInvitationsReducer as invitations } from './student-invitations/reducer';
import { commonReducer as common } from './common/reducer';

export const adminPagesReducer = combineReducers({
  dashboard,
  settings,
  invitations,
  common,
});
