import React, { useEffect, useState } from 'react';
import { api } from '../../../../api';
import { RegisterPageOne } from './page-1';
import { RegisterPageTwo } from './page-2';
import { RegisterPageThree } from './page-3';

export const Register: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleNext = () => setCurrentPage(currentPage + 1);
  const handlePrevious = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    api.user.getUser().then((res) => {
      window.location.href = '/dashboard';
    });
  }, []);

  return (
    <>
      <section className={'flex justify-between h-full'}>
        <div
          className={
            'w-full md:w-5/6 lg:w-5/6 flex flex-col items-center justify-center'
          }
        >
          {currentPage == 1 && (
            <RegisterPageOne handleNext={() => handleNext()} />
          )}
          {currentPage == 2 && (
            <RegisterPageTwo handleNext={() => handleNext()} />
          )}
          {currentPage == 3 && <RegisterPageThree />}
        </div>
        <div className={'bg-blue-darkest w-0 md:w-1/6 lg:w-2/6 h-screen'}>
          <div
            className={'fixed h-screen w-2/6'}
            style={{ background: "url('/assets/degreely-pattern.jpg')", backgroundPosition: "center", backgroundSize: "cover" }}
          ></div>
        </div>
      </section>
    </>
  );
};
