import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as trackActions from '../actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import { ISchedule } from '../../../../../../api/schedules';
import { IScheduleTerm } from '../../../../../../api/schedule-terms';
import { IMajor, IMinor } from '../../../../../../api/majors';
import * as courseBinFilterActions from '../../../../../../common/filters/course-bin/actions';
import { IFilter } from '../../../../../../types/filter';
import { Helpers } from '../../../../../../helpers';

const fetchCourseBinOnFilter: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseBinFilterActions.apply),
    map(() => actions.fetch())
  );

const trackLoaded: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(trackActions.load.done),
    map(() => actions.fetch())
  );

const trackUpdated: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(trackActions.update.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      const { schedule } = state$.value.pages.dashboard.currentTrack;
      const { filters } = state$.value.common.filters.courseBin;

      return from(
        api.schedules.getCourseBin({
          scheduleId: schedule.id || state$.value.common.user.info.activeScheduleId,
          filters: {
            grades: filters.grades
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            requirementCategories: filters.requirementCategories
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableYears: filters.availableYears
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableTerms: filters.availableTerms
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
          },
        })
      ).pipe(
        mergeMap(({ data }) => {
          const { schedule } = state$.value.pages.dashboard.currentTrack;

          let filteredCourses = Helpers.filterCoursesForCourseBin(schedule, data)

          return of(actions.load.done(filteredCourses));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(
  fetch,
  load,
  trackLoaded,
  trackUpdated,
  fetchCourseBinOnFilter
);
