import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { ToastVariant, useToasts } from '../../../../../../hooks/toasts';
import { useSelector } from '../../../../../../infrastructure/selector';
import SkeletonModal from '../../../../../../components/modal/skeleton';
import { Button } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { Input, InputSize } from '../../../../../../components/input';
import { DateSelect } from '../../../../../../ui/date-picker';
import { CiCalendarDate } from 'react-icons/ci';
import { useMutation } from 'react-query';
import { academicYear, IAcademicYear, ICreateAcademicYearRequest } from '../../../../../../api/academic-years';
import { useNavigate } from 'react-router-dom';
import { IValidationErrorResponse } from '../../../../../../types/error';
import axios, { AxiosError, AxiosResponse } from 'axios';

const useCreateAcademicYear = (handleOnSuccess: (response: AxiosResponse<IAcademicYear>) => any, handleOnError: (error: AxiosError<IValidationErrorResponse>) => any) => {
  return useMutation({
    mutationFn: (payload: ICreateAcademicYearRequest) => {
      return academicYear.createAcademicYear(payload)
    },
    onSuccess: (response) => {
      handleOnSuccess(response);
    },
    onError: (error: AxiosError<IValidationErrorResponse>) => {
      handleOnError(error);
    }
  })
}

export interface IAddYearDetailsModalProps {
  isOpen: boolean;
  handleClose: () => any;
}

export const AddYearDetailsModal: React.FC<IAddYearDetailsModalProps> = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<IValidationErrorResponse>({ errors: {}, message: "" });
  const [academicYearName, setAcademicYearName] = useState<string>("");
  const [academicYearStartDate, setAcademicYearStartDate] = useState<Date | null>(new Date());
  const [academicYearEndDate, setAcademicYearEndDate] = useState<Date | null>(new Date());

  const mutation = useCreateAcademicYear(
    (response) => {
      handleClose();
      navigate(`/admin/academic-years/${response.data.id}`)
    },
    (error) => {
      if(error?.response?.data) {
        setErrors(error.response.data);
      }
    }
  )

  const { schoolId } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const handleSave = () => {
    mutation.mutate({
      schoolId: schoolId,
      name: academicYearName,
      startDate: academicYearStartDate,
      endDate: academicYearEndDate
    })
  }

  const [addToast] = useToasts();

  // useEffect(() => {
  //   if (!!error.message) {
  //     addToast({
  //       variant: ToastVariant.Error,
  //       message: error.message
  //     });
  //   }
  // }, [error.message]);
  //
  // if (!isOpen) {
  //   return null;
  // }
  //
  // if (isLoading) {
  //   return <SkeletonModal handleClose={() => handleClose()} />;
  // }

  return (
    <>
      <Modal
        handleClose={() => handleClose()}
        isOpen={isOpen}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-6'}>
              <div className="mr-2 w-2/12">
                <Button
                  size={ButtonSize.Small}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
              <div className={"w-2/12"}>
                <Button
                  handleOnClick={handleSave}
                  isLoading={mutation.isLoading}
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Primary}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
              <section className={"flex items-center justify-center"}>
                <div className={"text-center flex flex-col justify-center items-center"}>
                  <CiCalendarDate className={"fill-neutral-light stroke-neutral-light text-3xl mb-1"} />
                  <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Add Year Details</h2>
                </div>
              </section>
              <div className={"w-full"}>
                <div className='mb-4'>
                  <Input error={errors} value={academicYearName} size={InputSize.md} onChange={(e) => setAcademicYearName(e.target.value)} type={"text"} required={true} name={"label"} placeholder={"2024 - 2025 Academic Year"} label={"Name of Year"} />
                </div>
              </div>
              <div className={'flex items-center'}>
                <div className={"w-1/2"}>
                  <div className='pr-2'>
                    <DateSelect error={errors} name={"startDate"} label={`Academic Year Start Date`} onChange={(date) => setAcademicYearStartDate(date)} selected={academicYearStartDate ?? new Date()} />
                  </div>
                </div>
                <div className={"w-1/2"}>
                  <div className='pl-2'>
                    <DateSelect error={errors} name={"endDate"} label={`Academic Year End Date`} onChange={(date) => setAcademicYearEndDate(date)} selected={academicYearEndDate ?? new Date()} />
                  </div>
                </div>
              </div>
        </div>
      </Modal>
    </>
  );
};
