import React from 'react';
import { useQuery } from 'react-query';
import { academicYear } from '../../../../../../../api/academic-years';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import classNames from 'classnames';

export interface ITermPillsProps {
  onPillSelect: (term: any, index: number) => any;
}
export const TermPills: React.FC<ITermPillsProps> = ({ onPillSelect }) => {

  const [activePillIndex, setActivePillIndex] = React.useState<number>(0);

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useQuery(`academicYearTerms-${academicYearId}`, () => academicYear.getAcademicYearTermsByAcademicYearId(schoolId, academicYearId), {
    enabled: !!schoolId && !!academicYearId,
  });

  const handlePillClick = (term: any, index: number) => {
    setActivePillIndex(index);
    onPillSelect(term, index);
  }

  return (
    <section className={"flex items-center overflow-x-auto w-full pb-4 max-w-full"}>
      <div className={'w-fit mr-3 min-w-fit flex-grow-0 flex-shrink-0 text-neutral-dark text-xs'}>
        Terms:
      </div>
      <div className={'w-fit mr-3 min-w-fit flex-grow-0 flex-shrink-0'}>
        <div
          onClick={() => handlePillClick(null, 0)}
          className={classNames("border-2 p-2 text-xs rounded-md cursor-pointer ", {
            'bg-baby-blue text-white border-baby-blue': activePillIndex === 0,
            'bg-baby-blue-lightest text-baby-blue border-baby-blue-lightest hover:bg-baby-blue-lighter': activePillIndex !== 0
          })}>
          All
        </div>
      </div>
      {data?.data.map((term, index) => {
        return (
          <div className={'w-fit mr-3 min-w-fit flex-grow-0 flex-shrink-0'} onClick={() => handlePillClick(term, index + 1)}>
            <div
              className={classNames("border-2 p-2 text-xs rounded-md cursor-pointer ", {
                'bg-baby-blue text-white border-baby-blue': activePillIndex === index + 1,
                'bg-baby-blue-lightest text-baby-blue border-baby-blue-lightest hover:bg-baby-blue-lighter': activePillIndex !== index + 1
              })}>
              {term.label}
            </div>
          </div>
        )
      })}
    </section>
  )
}
