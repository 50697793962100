import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import * as actions from './actions';
import { IDegreeRequirement } from '../../../../../../../../api/admin/degrees';
import { CreateRequirement } from '../create';
import { Button } from '../../../../../../../../ui';
import {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from '../../../../../../../../ui/button';
import { DegreeRequirementItem } from './item';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../../../common/routes';

export const DegreeRequirementsList: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCreatingRequirement, setIsCreatingRequirement] = useState(false);
  const { isLoading, requirements, error, degreeId } = useSelector(
    (state) => ({
      degreeId: state.common.match.params.id,
      isLoading:
        state.pages.admin.settings.degrees.update.requirements.list.isLoading,
      requirements:
        state.pages.admin.settings.degrees.update.requirements.list.list,
      error: state.pages.admin.settings.degrees.update.requirements.list.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  return (
    <>
      <section className={'mt-5'}>
        <h2 className={'text-neutral-darkest text-lg mb-2'}>Requirements</h2>
        <hr />

        <div className={'flex items-center border-b pb-2 pt-2'}>
          <h5 className={'text-black font-semibold text-sm w-3/12'}>
            Name
          </h5>
          <h5 className={'text-black font-semibold text-sm w-4/12'}>
            Color
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12'}>
            Number Required
          </h5>
          <h5 className={'text-black font-semibold text-sm w-2/12'}>
            Unit
          </h5>
        </div>
        {requirements.map((requirement: IDegreeRequirement) => (
          <DegreeRequirementItem requirement={requirement} />
        ))}
        {isCreatingRequirement && (
          <CreateRequirement onHide={() => setIsCreatingRequirement(false)} />
        )}
        <div className={'mt-10 w-2/12 flex flex-end'}>
          <Button
            handleOnClick={() => navigate(generatePath(Routes.admin.manage.degrees.requirements.create, { degreeId: degreeId }))}
            size={ButtonSize.XSmall}
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
          >
            + Add Requirement
          </Button>
        </div>
      </section>
    </>
  );
};
