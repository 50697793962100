import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../../api';
import { IEpic } from '../../../../../../../../infrastructure/selector';

const update: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.update.start),
    switchMap(({ payload }) => {
      console.log(state$.value.common);
      return from(
        api.admin.degrees.updateDegreeRequirement({
          ...payload,
          degreeId: state$.value.common.match.params.id,
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.update.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.update.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(update);
