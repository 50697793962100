import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as courseHistoryActions from '../course-history/actions';
import * as courseRequestsActions from '../requests/actions';
import * as courseRequestModalActions from "../modals/course-request-backups-modal/actions";
import { IFilter } from '../../../../../../types/filter';
import * as filterActions from "../../../../../../common/filters/course-bin/actions";

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnFilter: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(filterActions.apply),
    map(() => actions.fetch())
  );

const fetchOnModalLoadDone: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseRequestModalActions.load.done),
    map(() => actions.fetch())
  );

const fetchOnSearch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.onSearch, courseRequestModalActions.onSearch),
    map(() => actions.fetch())
  );

const fetchOnCourseHistoryLoadDone: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseHistoryActions.load.done),
    map(() => actions.fetch())
  );

const fetchOnCourseRequestsLoadDone: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseRequestsActions.load.done),
    map(() => actions.fetch())
  );

const fetchOnModalClose: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(courseRequestModalActions.close),
    map(() => actions.fetch())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.schedules.getCourseBin({
          scheduleId: state$.value.common.user.info.activeScheduleId,
          search: state$.value.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.isOpen ? state$.value.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.search : state$.value.domains.student.pages.courseRequests.courseBin.search,
          filters: {
            grades: state$.value.common.filters.courseBin.filters.grades
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            requirementCategories: state$.value.common.filters.courseBin.filters.requirementCategories
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableYears: state$.value.common.filters.courseBin.filters.availableYears
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
            availableTerms: state$.value.common.filters.courseBin.filters.availableTerms
              .filter((filter: IFilter) => filter.selected)
              .map((filter: IFilter) => Number(filter.value)),
          },
        })
      ).pipe(
        mergeMap(({ data }) => {

          let completedCourses = state$.value.domains.student.pages.courseRequests.courseHistory.list;
          let courseRequestCourses = state$.value.domains.student.pages.courseRequests.requests.list;

          // Filter out courses that are already in the course history
          data = data.filter((course: any) => {
            return !completedCourses.find((completedCourse: any) => completedCourse.id === course.id);
          });

          // Filter out courses that are already in the course requests
          data = data.filter((course: any) => {
            return !courseRequestCourses.find((courseRequest: any) => courseRequest.course.id === course.id);
          });

          // Filter out courses that are already in the course request backups if the modal is open
          if(state$.value.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.isOpen) {
            let activeCourseRequest = state$.value.domains.student.pages.courseRequests.modals.courseRequestBackupsModal.activeCourseRequest;
            let activeCourseRequestBackups = activeCourseRequest.backups.map((backup: any) => backup.course.id);
            data = data.filter((course: any) => {
              return !activeCourseRequestBackups.includes(course.id);
            });
          }

          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) => {
      return of(actions.load.error()).pipe(concat(source$));
      }
    )
  );

export const epic = combineEpics(
  fetch,
  fetchOnSearch,
  fetchOnModalLoadDone,
  fetchOnModalClose,
  fetchOnFilter,
  load,
  fetchOnCourseHistoryLoadDone,
  fetchOnCourseRequestsLoadDone
);
