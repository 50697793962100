import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { shallowEqual, useDispatch } from 'react-redux';
import * as modalActions from '../../domains/student/pages/planner/modals/requirement-category-detail/actions';
import { useSelector } from '../../infrastructure/selector';

interface IRequirementProgressProps {
  id: number;
  title: string;
  unit: string;
  completed: number;
  planned: number;
  total: number;
  color: string;
}

// TODO: This modal action needs to be optional
export const RequirementProgress: React.FC<IRequirementProgressProps> = ({
  id,
  title,
  unit,
  completed,
  total,
  planned,
  color,
}) => {
  const dispatch = useDispatch();

  const { schedule } = useSelector(
    (state) => ({
      schedule: state.pages.dashboard.currentTrack.schedule,
    }),
    shallowEqual
  );

  const completedPercentage = (completed / total) * 100;
  const plannedPercentage = (planned / total) * 100;

  if (total > 1) {
    unit = unit + 's';
  }

  return (
    <>
      <div
        className={
          'p-4 flex flex-col bg-neutral-lightest rounded-lg cursor-pointer md:w-4/12 xl:2/12 mr-1 ml-1'
        }
        onClick={() =>
          dispatch(
            modalActions.open({ requirementId: id, scheduleId: schedule.id })
          )
        }
      >
        <div
          className={
            'flex items-center justify-between md:flex-col lg:flex-col-reverse xl:flex-row'
          }
        >
          <div className={'lg:flex lg:items-center lg:flex-col xl:block'}>
            <h6
              className={
                'text-xs xl:text-sm text-neutral-darkest font-semibold md:-mb-1 lg:mb-0'
              }
            >
              {title}
            </h6>
            <div className={'flex items-baseline -mb-3'}>
              <span
                className={
                  'text-md xl:text-2xl font-bold text-neutral-darkest mr-1 font-semibold'
                }
                style={{ color: color }}
              >
                {completed}
              </span>
              <span
                className={
                  'text-xxs uppercase text-neutral-darkest tracking-wide font-medium font-sembold'
                }
              >
                / {total}
              </span>
              <span
                className={
                  'ml-1 text-xxs uppercase text-neutral-darkest tracking-wide font-medium font-sembold'
                }
              >
                {unit}
              </span>
            </div>
          </div>
          <div className={'w-14 h-14 relative lg:mb-2'}>
            <CircularProgressbar
              value={completedPercentage}
              strokeWidth={16}
              styles={buildStyles({
                pathColor: color,
              })}
            />
            <div className={'opacity-40 absolute top-0 left-0'}>
              <CircularProgressbar
                value={plannedPercentage}
                strokeWidth={16}
                styles={buildStyles({
                  pathColor: color,
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={
            'flex lg:flex-col xl:flex-row items-center justify-between mt-5'
          }
        >
          <div className="flex items-center lg:mb-1 xl:mb-0 lg:justify-center xl:justify-start">
            <div
              className={'h-3 w-3 rounded-lg opacity-40 mr-1'}
              style={{ background: color }}
            ></div>
            <span
              className={'text-neutral-dark text-xxs leading-none font-medium'}
            >
              Planned
            </span>
          </div>
          <div
            className={'flex items-center lg:justify-center xl:justify-start'}
          >
            <div
              className={'h-3 w-3 rounded-lg mr-1'}
              style={{ background: color }}
            ></div>
            <span
              className={'text-neutral-dark text-xxs leading-none font-medium'}
            >
              Completed
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
