import React from 'react';
import { Modal, ModalPosition, ModalVariant } from '../../../../components/modal';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../infrastructure/selector';
import { Button } from '../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../ui/button';
import { UserProfileInterestFields } from '../fields';

export const InterestsModal: React.FC = () => {

  const dispatch = useDispatch();

  const { isOpen, isLoading, error, english, math, performingArts, studioArts, history, science, athletics } = useSelector(
    (state) => ({
      athletics: state.pages.studentProfile.interests.modal.athletics,
      english: state.pages.studentProfile.interests.modal.english,
      history: state.pages.studentProfile.interests.modal.history,
      math: state.pages.studentProfile.interests.modal.math,
      performingArts: state.pages.studentProfile.interests.modal.performingArts,
      science: state.pages.studentProfile.interests.modal.science,
      studioArts: state.pages.studentProfile.interests.modal.studioArts,
      isOpen: state.pages.studentProfile.interests.modal.isOpen,
      error: state.pages.studentProfile.interests.modal.error,
      isLoading:
      state.pages.studentProfile.interests.modal.isLoading,
    }),
    shallowEqual
  );

  return (
    <Modal
      position={ModalPosition.Centered}
      variant={ModalVariant.Centered}
      handleClose={() => dispatch(actions.close())}
      isOpen={isOpen}
      renderBody={() => <></>}
      renderFooter={() => <>
        <div className={"flex items-center justify-end mt-20"}>
          <Button
            classes={"mr-2"}
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            style={ButtonStyle.Outline}
            handleOnClick={() => dispatch(actions.close())}
          >
            Cancel
          </Button>
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            isLoading={isLoading}
            handleOnClick={() => dispatch(actions.save.start())}
          >
            Save
          </Button>
        </div>
      </>}
    >
      <div>
        <h2 className={"text-3xl pb-4 text-baby-blue border-b border-b-neutral-light font-semibold"}>Update Your Interests</h2>
      </div>

      <UserProfileInterestFields
        onChange={(value) => dispatch(actions.onChange(value))}
        isEditable={true}
        values={{
          english,
          history,
          math,
          performingArts,
          science,
          studioArts,
          athletics
      }}  />
    </Modal>
  )
}
