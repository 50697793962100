import React from 'react';
import { Button } from '../../../../../ui';
import 'react-datepicker/dist/react-datepicker.css';
import { Courses } from './courses';
import { AddCourseModal } from '../modals/add-course';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../ui/button';
import { Terms } from './terms';
import { AddTermModal } from '../modals/add-term';
import { YearDetails } from './year';
import { AddYearDetailsModal } from '../modals/add-year';
import { RegistrationPeriods } from './registration-periods';
import { AddRegistrationPeriodModal } from '../modals/add-registration-period';
import {useNavigate} from "react-router-dom";
import { Students } from './students';

export interface IAcademicYearTermDates {
  term: number;
  startDate: Date;
  endDate: Date;
}

export const CreateAcademicYear: React.FC = () => {
  const navigate = useNavigate();
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);
  const [isYearDetailsModalOpen, setIsYearDetailsModalOpen] = React.useState(false);
  const [isRegistrationPeriodsModalOpen, setIsRegistrationPeriodsModalOpen] = React.useState(false);
  const [isCoursesModalOpen, setIsCoursesModalOpen] = React.useState(false);
  const [isStudentModalOpen, setIsStudentModalOpen] = React.useState(false);

  return (
    <>
        <section className={"flex items-center justify-between"}>
            <div>
                <h3 className={"text-black text-lg font-medium"}>Create your Academic Year</h3>
                <span className={"text-neutral text-xs"}>This includes dates, terms, courses, and new students</span>
            </div>
            <section className={"flex justify-end"}>
                <div className={"flex items-center"}>
                    <Button
                        handleOnClick={() => navigate("/admin/overview")}
                        classes={"mr-4"}
                        size={ButtonSize.XSmall}
                        radius={ButtonBorderRadius.FULL}
                        variant={ButtonVariant.Primary}
                        style={ButtonStyle.Outline}
                    >Cancel</Button>
                    {/*<Button*/}
                    {/*    // onClick={() => false}\*/}
                    {/*    classes={"mr-4"}*/}
                    {/*    size={ButtonSize.Small}*/}
                    {/*    radius={ButtonBorderRadius.FULL}*/}
                    {/*    variant={ButtonVariant.Secondary}*/}
                    {/*    style={ButtonStyle.Outline}*/}
                    {/*>Save</Button>*/}
                    {/*<Button*/}
                    {/*    // onClick={() => false}*/}
                    {/*    size={ButtonSize.Small}*/}
                    {/*    radius={ButtonBorderRadius.FULL}*/}
                    {/*    variant={ButtonVariant.Secondary}*/}
                    {/*    style={ButtonStyle.Filled}*/}
                    {/*>Save & Continue</Button>*/}
                </div>
            </section>
        </section>

        <hr className={"border-neutral-lighter my-4"}/>

        <YearDetails handleModalOpen={() => setIsYearDetailsModalOpen(true)} isDisabled={true} />
        <Terms handleModalOpen={() => setIsTermsModalOpen(true)} isDisabled={true} />
        <RegistrationPeriods handleModalOpen={() => setIsRegistrationPeriodsModalOpen(true)} isDisabled={true} />
        <Courses handleModalOpen={() => setIsCoursesModalOpen(true)} isDisabled={true} />
        <Students handleModalOpen={() => setIsStudentModalOpen(true)} isDisabled={true} />

        <AddRegistrationPeriodModal isOpen={isRegistrationPeriodsModalOpen} handleClose={() => setIsRegistrationPeriodsModalOpen(false)} />
        <AddYearDetailsModal isOpen={isYearDetailsModalOpen} handleClose={() => setIsYearDetailsModalOpen(false)} />
        <AddTermModal isOpen={isTermsModalOpen} handleClose={() => setIsTermsModalOpen(false)} />
        <AddCourseModal isOpen={isCoursesModalOpen} handleClose={() => setIsCoursesModalOpen(false)} />

    </>
  )
}
