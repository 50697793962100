import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../ui';
import 'react-datepicker/dist/react-datepicker.css';
import { Courses } from '../create/courses';
import { AddCourseModal } from '../modals/add-course';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../ui/button';
import { ISelectOption } from '../../../../../ui/select';
import { Terms } from '../create/terms';
import { AddTermModal } from '../modals/add-term';
import { YearDetails } from '../create/year';
import { AddYearDetailsModal } from '../modals/add-year';
import { academicYear } from '../../../../../api/academic-years';
import { useQuery } from 'react-query';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { RegistrationPeriods } from '../create/registration-periods';
import { AddRegistrationPeriodModal } from '../modals/add-registration-period';
import {useNavigate} from "react-router-dom";
import { Students } from '../create/students';
import {EditTermModal} from "../modals/edit-term";
import {EditRegistrationPeriodModal} from "../modals/edit-registration-period";

export interface IAcademicYearTermDates {
  term: number;
  startDate: Date;
  endDate: Date;
}

export const AcademicYearPage: React.FC = () => {
  const navigate = useNavigate();

  // Add Modals
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);
  const [isYearDetailsModalOpen, setIsYearDetailsModalOpen] = React.useState(false);
  const [isRegistrationPeriodsModalOpen, setIsRegistrationPeriodsModalOpen] = React.useState(false);
  const [isCoursesModalOpen, setIsCoursesModalOpen] = React.useState(false);
  const [isStudentModalOpen, setIsStudentModalOpen] = React.useState(false);

  // Edit Modals
  const [isEditTermModalOpen, setIsEditTermModalOpen] = React.useState(false);
  const [isEditRegistrationPeriodModalOpen, setIsEditRegistrationPeriodModalOpen] = React.useState(false);

  // Active Editable Entities
  const [activeEditableTerm, setActiveEditableTerm] = React.useState<{ id: number; label: string; startDate: string; endDate: string }>({ id: 0, label: "", startDate: "", endDate: "" });
  const [activeEditableRegistrationPeriodId, setActiveEditableRegistrationPeriod] = React.useState<number>(0);

  const handleEditTermModalOpen = (term: { id: number; label: string; startDate: string; endDate: string }) => {
      setActiveEditableTerm(term);
      setIsEditTermModalOpen(true);
  }

  const handleEditRegistrationPeriodModalOpen = (id: number) => {
    console.log('id', id);
    setActiveEditableRegistrationPeriod(id);
    setIsEditRegistrationPeriodModalOpen(true);
  }

  return (
    <>
      <section className={"flex items-center justify-between"}>
        <div>
            <h3 className={"text-black text-lg font-medium"}>Your Academic Year</h3>
            <span className={"text-neutral text-xs"}>This includes dates, terms, courses, and new students</span>
        </div>
          <section className={"flex justify-end"}>
              <div className={"flex items-center"}>
                  <Button
                      handleOnClick={() => navigate("/admin/overview")}
                      classes={"mr-4"}
                      size={ButtonSize.XSmall}
                      radius={ButtonBorderRadius.FULL}
                      variant={ButtonVariant.Primary}
                      style={ButtonStyle.Outline}
                  >Back to Overview</Button>
                  {/*<Button*/}
                  {/*    // onClick={() => false}\*/}
                  {/*    classes={"mr-4"}*/}
                  {/*    size={ButtonSize.Small}*/}
                  {/*    radius={ButtonBorderRadius.FULL}*/}
                  {/*    variant={ButtonVariant.Secondary}*/}
                  {/*    style={ButtonStyle.Outline}*/}
                  {/*>Save</Button>*/}
                  {/*<Button*/}
                  {/*    // onClick={() => false}*/}
                  {/*    size={ButtonSize.Small}*/}
                  {/*    radius={ButtonBorderRadius.FULL}*/}
                  {/*    variant={ButtonVariant.Secondary}*/}
                  {/*    style={ButtonStyle.Filled}*/}
                  {/*>Save & Continue</Button>*/}
              </div>
          </section>
      </section>
      <hr className={"border-neutral-lighter my-4"}/>
      <YearDetails handleModalOpen={() => setIsYearDetailsModalOpen(true)} />

        <Terms
            handleEditModalOpen={(term) => handleEditTermModalOpen(term)}
            handleModalOpen={() => setIsTermsModalOpen(true)}
        />

        <RegistrationPeriods handleEditModalOpen={(id) => handleEditRegistrationPeriodModalOpen(id)} handleModalOpen={() => setIsRegistrationPeriodsModalOpen(true)} />
      <Courses handleModalOpen={() => setIsCoursesModalOpen(true)} />
      <Students handleModalOpen={() => setIsStudentModalOpen(true)} />

      <AddYearDetailsModal
        isOpen={isYearDetailsModalOpen}
        handleClose={() => setIsYearDetailsModalOpen(false)}
      />
      <AddTermModal
        handleClose={() => setIsTermsModalOpen(false)}
        isOpen={isTermsModalOpen}
      />
      <EditTermModal term={activeEditableTerm} isOpen={isEditTermModalOpen} handleClose={() => setIsEditTermModalOpen(false)} />'
      <AddRegistrationPeriodModal
        isOpen={isRegistrationPeriodsModalOpen}
        handleClose={() => setIsRegistrationPeriodsModalOpen(false)}
      />
      <EditRegistrationPeriodModal registrationPeriodId={activeEditableRegistrationPeriodId} isOpen={isEditRegistrationPeriodModalOpen} handleClose={() => setIsEditRegistrationPeriodModalOpen(false)} />
      <AddCourseModal isOpen={isCoursesModalOpen} handleClose={() => setIsCoursesModalOpen(false)} />
    </>
  )
}
