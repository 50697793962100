import React from "react";
import {CourseList} from "./courses";
import {Filters} from "./filters";
import {IActivePreference} from "../index";
import { ICourse } from '../../../../../../types/course';

export interface IPreferencesCourseBinProps {
    list: ICourse[];
}

export const PreferencesCourseBin: React.FC<IPreferencesCourseBinProps> = ({ list }) => {
    return (
        <section className={"flex"}>
            <div className={"w-2/4 mx-4"}>
                <CourseList list={list}/>
            </div>
            {/*<div className="w-2/4 mx-4">*/}
            {/*    <Filters />*/}
            {/*</div>*/}
        </section>
    )
}
