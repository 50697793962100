import { combineReducers } from 'redux';

import { studentsReducer as students } from '../students/reducer';
import { coursesReducer as courses } from '../courses/reducer';
import { overviewReducer as overview } from '../overview/reducer';
import { termReducer as term } from '../term/reducer';

export const adminOverviewReducer = combineReducers({
  students,
  courses,
  overview,
  term,
});
