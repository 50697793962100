import React from 'react';
import { ToastVariant, useToasts } from '../../../../../../hooks/toasts';
import { Button } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { useQuery, useQueryClient } from 'react-query';
import {
    ICreateRegistrationPeriodRequest,
    IRegistrationPeriod,
    registrationPeriods
} from '../../../../../../api/registration-periods';
import { useDegreelyMutation } from '../../../../../../infrastructure/query';
import { AxiosError } from 'axios';
import { GoChecklist } from 'react-icons/go';
import { SimpleDangerAction } from '../../../../../../components/simple-action';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';

export interface IEditRegistrationPeriodModal {
    isOpen: boolean;
    handleClose: () => any;
    registrationPeriodId?: number;
}

export const EditRegistrationPeriodModal: React.FC<IEditRegistrationPeriodModal> = ({ isOpen, handleClose, registrationPeriodId }) => {
    const queryClient = useQueryClient();

    const [addToast] = useToasts();

  const { data, isLoading, error } = useQuery(`registrationPeriods-${registrationPeriodId}`, () => registrationPeriods.getRegistrationPeriodById(registrationPeriodId ?? 0), {
    enabled: !!registrationPeriodId && isOpen,
  });

  let registrationPeriod = data?.data ?? null;

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

    const destroyMutation = useDegreelyMutation<IRegistrationPeriod, AxiosError, number>({
        mutationFn: (payload) => {
            return registrationPeriods.destroyRegistrationPeriod(registrationPeriodId ?? 0)
        },
        handleOnSuccess: (response) => {
            queryClient.invalidateQueries(`registrationPeriods-${academicYearId}`);
            handleClose();
            addToast({
                message: "Registration Period Deleted Successfully",
                variant: ToastVariant.Success
            });
        },
        handleOnError: (error) => {
            console.log(error);
            addToast({
                message: "An error occurred",
                variant: ToastVariant.Error
            });
        }
    })

    if (!isOpen) {
        return null;
    }

    return (
        <>
          <Modal
            handleClose={() => handleClose()}
            isOpen={isOpen}
            renderBody={() => <div></div>}
            renderFooter={() => (
              <>
                <div className={'flex items-center justify-end mt-6'}>
                  <div className="mr-2 w-2/12">
                    <Button
                      size={ButtonSize.Medium}
                      style={ButtonStyle.Outline}
                      variant={ButtonVariant.Primary}
                      handleOnClick={() => handleClose()}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={"w-2/12"}>
                    <Button
                      handleOnClick={handleClose}
                      size={ButtonSize.Medium}
                      style={ButtonStyle.Filled}
                      variant={ButtonVariant.Primary}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </>
            )}
          >
            <div className={'flex flex-col'}>
              <div className={'flex flex-col'}>
                <section className={'flex items-center justify-center'}>
                  <div className={'text-center flex flex-col justify-center items-center'}>
                    <GoChecklist className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
                    <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Edit Registration Period</h2>
                    <p className={"text-neutral text-sm"}>Registration Periods can't be edited once created and can only be destroyed if the current date is before the preference submission start date.</p>
                    <div className='pt-4'>
                      <SimpleDangerAction onClick={() => destroyMutation.mutate(registrationPeriodId ?? 0)} disabled={!registrationPeriod?.isDestroyable} icon={BiTrash} label={"Delete Registration Period"} />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Modal>
        </>
    );
};
