import React from 'react';
import { Input } from '../../../../../../../components/input';
import * as actions from '../../../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';
import PhoneNumberFormatter from '../../../../../../../services/phone-number-formatter';

export const PhoneField: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { phoneNumber, error } = register;

  return (
    <div className={'mb-4'}>
      <Input
        placeholder={'000-000-0000'}
        required={true}
        type={'text'}
        maxLength={12}
        label={'Phone Number'}
        name={'phone_number'}
        value={PhoneNumberFormatter.format(phoneNumber)}
        error={error}
        onChange={(e) =>
          dispatch(
            actions.onChange({
              key: 'phoneNumber',
              value: e.target.value,
            })
          )
        }
      />
    </div>
  );
};
