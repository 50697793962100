import React from 'react';
import { Button } from '../../../../../components/button';
import * as createTrackModalActions from '../modals/create-track/actions';
import { useDispatch } from 'react-redux';

export const NoSchedules: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className={'flex items-center justify-center flex-col'}>
        <h1 className={'text-black text-xl font-semibold mb-2'}>
          It looks like you haven't created your first degree plan
        </h1>
        <p className={'text-neutral mb-10'}>Let's do that now</p>
        <Button
          className={'bg-baby-blue p-3 text-white rounded-md'}
          handleOnClick={() => dispatch(createTrackModalActions.open())}
        >
          Create Track
        </Button>
      </div>
    </>
  );
};
