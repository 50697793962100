import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { shallowEqual, useDispatch } from 'react-redux';
import * as configActions from './common/config/actions';
import { useSelector } from '../../../../infrastructure/selector';
import { Routes as DegreelyRoutes } from '../../../../common/routes';
import { AdminSettingsCourses } from './courses';
import { AdminSettingsCreateCourse } from './course/create';
import { AdminManageCourse } from './course';
import { AdminEditDegreePage } from './degrees/edit';
import { AdminSettingsDegreesPage } from './degrees';

export const AdminManagePages: React.FC = () => {
  const dispatch = useDispatch();

  const { schoolId } = useSelector(
    (state) => ({
      schoolId: state.common.user.info.schoolId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(configActions.fetch());
  }, [schoolId]);

  return (
    <>
      <section className={'bg-white rounded-sm p-10'}>
        <Routes>
          <Route
            path={DegreelyRoutes.admin.manage.courses.index}
            element={<AdminSettingsCourses />}
          />
          <Route
            path={DegreelyRoutes.admin.manage.courses.create}
            element={<AdminSettingsCreateCourse />}
          />
          <Route
            path={DegreelyRoutes.admin.manage.courses.show}
            element={<AdminManageCourse />}
          />
          <Route
            path={DegreelyRoutes.admin.manage.degrees.index}
            element={<AdminSettingsDegreesPage />}
          />
        </Routes>
      </section>
    </>
  );
};
