import React from 'react';
import { CourseHistory } from './course-history';
import { UpcomingYearRequests } from './requests';
import { CourseBin } from './course-bin';
import { CourseRequestBackupsModal } from './modals/course-request-backups-modal';

export const CourseRequests: React.FC = () => {
  return (
    <>
      <h1 className={"text-black text-xl font-semibold mb-6"}>2024-2025 Registration</h1>
        <section className={"flex justify-between"}>
          <section className={'mr-2 border-neutral-lighter border rounded-md p-6 bg-white w-9/12'}>
            <CourseHistory />
            <UpcomingYearRequests />
          </section>
          <section className='ml-2 border-neutral-lighter border rounded-md p-6 bg-white w-3/12'>
            <CourseBin />
          </section>
        </section>
      <CourseRequestBackupsModal />
    </>
  )
}
