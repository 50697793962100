import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { history, store } from './app/store';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { Provider as RollbarProvider } from '@rollbar/react';
import {getRollbarConfiguration} from "./infrastructure/logging";
import { ToastProvider } from './hooks/toasts';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';

const queryClient = new QueryClient();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <RollbarProvider config={getRollbarConfiguration()}>
          <ToastProvider>
            <ReduxProvider store={store}>
                <ReduxRouter history={history}>
                    <App />
                </ReduxRouter>
            </ReduxProvider>
          </ToastProvider>
        </RollbarProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
