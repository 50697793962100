import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../../infrastructure/selector';
import { api } from '../../../../../../../api';

const fetchOnUpdate: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.sync.done),
    filter(
      () => !!state$.value.pages.admin.settings.course.details.majors.length
    ),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    filter(
      () => !!state$.value.pages.admin.settings.course.details.majors.length
    ),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {
      // TODO: Need to support multiple degrees
      const degreeId = parseFloat(
        state$.value.pages.admin.settings.course.details.majors[0].id
      );

      return from(
        api.admin.degrees.getAvailableDegreeRequirementsByCourseId(
          degreeId,
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.load.error(error)).pipe(concat(source$));
    })
  );

const sync: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.sync.start),
    switchMap(() => {
      const assignedRequirementIds =
        state$.value.pages.admin.settings.course.requirementCategories.assigned;

      return from(
        api.admin.courses.syncCourseDegreeRequirementCategories(
          Number(state$.value.common.match.params.id),
          assignedRequirementIds
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.sync.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      console.log(error);
      return of(actions.sync.error(error)).pipe(concat(source$));
    })
  );

export const epic = combineEpics(fetch, load, sync, fetchOnUpdate);
