import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { ICourse } from '../../types/course';

export interface ICourseRequestBackup {
  id: number;
  courseRequestId: number;
  course: ICourse;
  priority: number;
}

export interface ICourseRequest {
  id: number;
  isApproved: boolean;
  course: ICourse;
  backups: ICourseRequestBackup[];
}

export interface ICourseRequestsAPI {
  getCourseRequests(studentId: number): AxiosPromise<ICourseRequest[]>;
  getCourseRequestById(studentId: number, courseRequestId: number): AxiosPromise<ICourseRequest>;
  addCourseRequest(studentId: number, courseId: number): AxiosPromise;
  removeCourseRequest(studentId: number, courseId: number): AxiosPromise;
  addBackupToCourseRequest(studentId: number, requestId: number, backupCourseId: number, priority: number): AxiosPromise;
  removeBackupFromCourseRequest(studentId: number, courseId: number, backupId: number): AxiosPromise;
  approveCourseRequest(studentId: number): AxiosPromise;
  disApproveCourseRequest(studentId: number): AxiosPromise;
  downloadCourseRequests(schoolId: number): AxiosPromise;
}

class CourseRequestsAPI implements ICourseRequestsAPI {
  approveCourseRequest(studentId: number): AxiosPromise<any> {
      return client.post(`/api/v1/registration/student/${studentId}/desired-course-request-approval`);
  }
  disApproveCourseRequest(studentId: number): AxiosPromise<any> {
      return client.delete(`/api/v1/registration/student/${studentId}/desired-course-request-approval`);
  }
  getCourseRequestById(studentId: number, courseRequestId: number): AxiosPromise<ICourseRequest> {
      return client.get(`/api/v1/registration/student/${studentId}/desired-course-request/${courseRequestId}`);
  }
  addBackupToCourseRequest(studentId: number, requestId: number, backupCourseId: number, priority: number): AxiosPromise {
    return client.post(`/api/v1/registration/student/${studentId}/desired-course-request/${requestId}/backup`, {
      courseId: backupCourseId,
      priority: priority
    });
  }

  addCourseRequest(studentId: number, courseId: number): AxiosPromise {
    return client.post(`/api/v1/registration/student/${studentId}/desired-course-request`, {
      courseId: courseId
    });
  }

  getCourseRequests(studentId: number): AxiosPromise<ICourseRequest[]> {
    return client.get(`/api/v1/registration/student/${studentId}/desired-course-request`);
  }

  removeBackupFromCourseRequest(studentId: number, courseRequestId: number, backupId: number): AxiosPromise {
    return client.delete(`/api/v1/registration/student/${studentId}/desired-course-request/${courseRequestId}/backup/${backupId}`);
  }

  removeCourseRequest(studentId: number, courseId: number): AxiosPromise {
    return client.delete(`/api/v1/registration/student/${studentId}/desired-course-request/${courseId}`);
  }

  downloadCourseRequests(schoolId: number): AxiosPromise {
    return client.get(`/api/v1/registration/school/${schoolId}/course-requests/download`, {
      responseType: "blob"
    });
  }

}

export const courseRequests = new CourseRequestsAPI();
