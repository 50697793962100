import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as createActions from "../create/actions";
import { concat } from 'rxjs/operators';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnDelete: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.destroy.done),
    map(() => actions.fetch())
  );

const fetchOnCreate: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.destroy.done, createActions.create.done),
    map(() => actions.fetch())
  );


const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.courses.availability.getCourseAvailability(
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(({ payload }) =>
      from(
        api.courses.availability.destroyCourseAvailability(
          state$.value.common.match.params.id,
          payload
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.destroy.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.destroy.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, destroy, fetchOnDelete, fetchOnCreate);
