import React from 'react';
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { ProgressionPercentageThresholds } from '../../../../../constants';
import Skeleton from 'react-loading-skeleton';

export const Progression: React.FC = () => {
  const { progression, isLoading } = useSelector(
    (state) => ({
      progression:
        state.pages.admin.dashboard.drawers.studentDetail.progression,
      isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
    }),
    shallowEqual
  );

  if (isLoading) {
    return <Skeleton height={300} className={'rounded-lg mt-3'} />;
  }

  let plannedPathColor = '';
  let completedPathColor = '';

  if (progression.percentCompleted <= ProgressionPercentageThresholds.Danger) {
    completedPathColor = window.appTheme?.red;
    plannedPathColor = window.appTheme?.redLightest;
  } else if (
    progression.percentCompleted > ProgressionPercentageThresholds.Danger &&
    progression.percentCompleted <= ProgressionPercentageThresholds.Warning
  ) {
    completedPathColor = window.appTheme?.yellow;
    plannedPathColor = window.appTheme?.yellowLightest;
  } else {
    completedPathColor = window.appTheme?.green;
    plannedPathColor = window.appTheme?.greenLightest;
  }

  return (
    <>
      <section className={'mt-3'}>
        <div className={'bg-white rounded-md border-baby-blue p-5 border'}>
          <div className={'relative mt-4 mb-8 w-1/2 mx-auto '}>
            <CircularProgressbarWithChildren
              strokeWidth={10}
              value={progression.percentPlanned}
              counterClockwise
              styles={buildStyles({
                pathColor: plannedPathColor,
              })}
            >
              <h4 className={'text-neutral-darkest text-xl font-semibold'}>
                Unavailable
              </h4>
              <p className={'text-neutral-dark text-xs'}>
                target graduation date
              </p>
            </CircularProgressbarWithChildren>
            <div className={'absolute top-0 left-0 w-full h-full'}>
              <CircularProgressbar
                strokeWidth={10}
                value={progression.percentCompleted}
                counterClockwise
                styles={buildStyles({
                  pathColor: completedPathColor,
                  trailColor: 'transparent',
                })}
              ></CircularProgressbar>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className={'text-neutral-darkest text-sm'}>
              {progression.percentCompleted}% Completed
            </span>
            <span className={'text-neutral-darkest text-sm'}>
              {progression.percentPlanned}% Planned
            </span>
            <span className={'text-neutral-darkest text-sm'}>
              {progression.percentUnplanned}% Unplanned
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
