import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISchedule } from '../../../../../api/schedules';

interface IState {
  list: ISchedule[];
  currentScheduleIndex: number;
  currentScheduleId: number;
  isLoading: boolean;
  schedule?: ISchedule | null;
}

const initialState: IState = {
  isLoading: false,
  currentScheduleIndex: 0,
  currentScheduleId: 0,
  list: [],
  schedule: null,
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    schedule: payload,
  };
});

reducer.on(actions.update.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.update.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.update.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: state.list.map((schedule) => {
      if (schedule.id == payload.id) {
        return {
          ...payload,
        };
      }
      return {
        ...schedule,
      };
    }),
    schedule: {
      ...payload,
    },
  };
});

reducer.on(actions.setCurrentTrackIndex, (state, payload) => ({
  ...state,
  currentScheduleIndex: payload,
}));

reducer.on(actions.updateTermGroups.done, (state, payload) => ({
  ...state,
  //@ts-ignore
  schedule: {
    ...state.schedule,
    termGroups: [...payload],
  },
}));

export const currentTrackReducer = reducer;
