import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { ToastVariant, useToasts } from '../../../../../../hooks/toasts';
import { useSelector } from '../../../../../../infrastructure/selector';
import SkeletonModal from '../../../../../../components/modal/skeleton';
import { Button } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { Input, InputSize } from '../../../../../../components/input';
import { DateSelect } from '../../../../../../ui/date-picker';
import { IoCalendarOutline } from 'react-icons/io5';
import { useMutation, useQueryClient } from 'react-query';
import {
  academicYear,
  IAddTermToAcademicYearRequest,
} from '../../../../../../api/academic-years';
import { IValidationErrorResponse } from '../../../../../../types/error';
import { AxiosError } from 'axios';

export interface IAddTermModalProps {
  isOpen: boolean;
  handleClose: () => any;
}
export const AddTermModal: React.FC<IAddTermModalProps> = ({ isOpen, handleClose }) => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<IValidationErrorResponse>({ errors: {}, message: "" });
  const [termName, setTermName] = useState<string>("");
  const [termStartDate, setTermStartDate] = useState<Date | null>(new Date());
  const [termEndDate, setTermEndDate] = useState<Date | null>(new Date());

  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      academicYearId: state.common.match.params.id,
      schoolId: state.common.configs.configuration.schoolId,
    }),
    shallowEqual
  );

  const mutation = useMutation({
    mutationFn: (payload: IAddTermToAcademicYearRequest) => {
      return academicYear.addTermToAcademicYear(payload)
    },
    onSuccess: (response) => {
      handleClose();
      queryClient.invalidateQueries(`academicYearTerms-${academicYearId}`);
    },
    onError: (error: AxiosError<IValidationErrorResponse>) => {
      if(error?.response?.data) {
        setErrors(error.response.data);
      }
    }
  });

  const handleSave = () => {
    mutation.mutate({
      schoolId: schoolId,
      academicYearId: academicYearId,
      label: termName,
      startDate: termStartDate,
      endDate: termEndDate
    })
  }

  if (!isOpen) {
    return null;
  }

  const handleModalClose = () => {
    handleClose();
    setErrors({ errors: {}, message: "" });
  }

  return (
    <>
      <Modal
        handleClose={() => handleModalClose()}
        isOpen={isOpen}
        renderBody={() => <div></div>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-6'}>
              <div className="mr-2 w-2/12">
                <Button
                  size={ButtonSize.Small}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
              </div>
              <div className={"w-2/12"}>
                <Button
                  handleOnClick={() => handleSave()}
                  isLoading={mutation.isLoading}
                  size={ButtonSize.Small}
                  style={ButtonStyle.Filled}
                  variant={ButtonVariant.Primary}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div className={'flex flex-col'}>
            <section className={"flex items-center justify-center"}>
              <div className={"text-center flex flex-col justify-center items-center"}>
                <IoCalendarOutline className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
                <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Add Term</h2>
              </div>
            </section>
            <div className={"w-full"}>
              <div className='mb-4'>
                <Input error={errors} value={termName} size={InputSize.md} onChange={(e) => setTermName(e.target.value)} type={"text"} required={true} name={"label"} placeholder={"Fall Term"} label={"Term Name"} />
              </div>
            </div>
            <div className={'flex items-center'}>
              <div className={"w-1/2"}>
                <div className='pr-2'>
                  <DateSelect error={errors} name={"startDate"} label={`Start Date`} onChange={(date) => setTermStartDate(date)} selected={termStartDate ?? new Date()} />
                </div>
              </div>
              <div className={"w-1/2"}>
                <div className='pl-2'>
                  <DateSelect error={errors} name={"endDate"} label={`End Date`} onChange={(date) => setTermEndDate(date)} selected={termEndDate ?? new Date()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
