import { createAction } from 'redux-act';
import { ICourse } from '../../../../../../types/course';

export type IActions =
  ReturnType<typeof fetch> |
  ReturnType<typeof load.start> |
  ReturnType<typeof load.error> |
  ReturnType<typeof load.done>;

export const fetch = createAction('[pages/student/course-requests/course-history] fetch');

export const load = {
  start: createAction('[pages/student/course-requests/course-history] load.start'),
  error: createAction('[pages/student/course-requests/course-history] load.error'),
  done: createAction<ICourse[]>('[pages/student/course-requests/course-history] load.done'),
};

export const add = {
  start: createAction<number>('[pages/student/course-requests/course-history] add.start'),
  error: createAction('[pages/student/course-requests/course-history] add.error'),
  done: createAction('[pages/student/course-requests/course-history] add.done'),
};

export const remove = {
  start: createAction<number>('[pages/student/course-requests/course-history] remove.start'),
  error: createAction('[pages/student/course-requests/course-history] remove.error'),
  done: createAction('[pages/student/course-requests/course-history] remove.done'),
};
