import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IDegreeRequirement } from '../../../../../../../api/admin/degrees';

interface IState {
  isLoading: boolean;
  list: IDegreeRequirement[];
  assigned: number[];
}

const initialState: IState = {
  isLoading: false,
  assigned: [],
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    assigned: payload.available
      .filter((requirement: IDegreeRequirement) => !!requirement.isAssigned)
      .map((requirement: IDegreeRequirement) => requirement.id),
    list: [...payload.available],
  };
});

reducer.on(actions.sync.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.sync.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.sync.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
  };
});

reducer.on(actions.onCheck, (state, payload) => {
  if (state.assigned.includes(payload)) {
    return {
      ...state,
      assigned: [...state.assigned.filter((id: number) => id != payload)],
    };
  }

  return {
    ...state,
    assigned: [...state.assigned, payload],
  };
});

export const requirementCategoriesReducer = reducer;
