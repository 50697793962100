import React from 'react';
import * as actions from '../../../actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';

export const LegalFields: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { marketingEmailConsent, legalConsent, marketingSMSConsent } = register;

  return (
    <div>
      <div className={'flex items-center cursor-pointer mb-2'}>
        <input
          className={'mr-2'}
          type="checkbox"
          id={'marketingEmails'}
          checked={marketingEmailConsent}
          onChange={(e) =>
            dispatch(
              actions.onChange({
                key: 'marketingEmailConsent',
                value: !marketingEmailConsent,
              })
            )
          }
        />
        <label
          className={'block text-neutral-dark text-xs'}
          htmlFor="marketingEmails"
        >
          Yes, I want to receive Degreely emails
        </label>
      </div>
      <div className={'flex items-center cursor-pointer mb-2'}>
        <input
          className={'mr-2'}
          type="checkbox"
          id={'marketingTexts'}
          checked={marketingSMSConsent}
          onChange={(e) =>
            dispatch(
              actions.onChange({
                key: 'marketingSMSConsent',
                value: !marketingSMSConsent,
              })
            )
          }
        />
        <label
          className={'block text-neutral-dark text-xs'}
          htmlFor="marketingTexts"
        >
          Yes, I want to receive Degreely SMS Messages
        </label>
      </div>
      <div className={'flex items-center cursor-pointer'}>
        <input
          className={'mr-2'}
          type="checkbox"
          id={'privacy'}
          checked={legalConsent}
          onChange={(e) =>
            dispatch(
              actions.onChange({
                key: 'legalConsent',
                value: !legalConsent,
              })
            )
          }
        />
        <label className={'block text-neutral-dark text-xs'} htmlFor="privacy">
          I agree to Degreely's{' '}
          <a
            className={'text-baby-blue hover:underline'}
            href={
              'https://app.termly.io/document/terms-of-use-for-saas/00fba9d8-06e7-4d35-88b2-87feb2faeeea'
            }
            target={'_blank'}
          >
            Terms of Service
          </a>
          ,{' '}
          <a
            className={'text-baby-blue hover:underline'}
            target="_blank"
            href={
              'https://app.termly.io/document/privacy-policy/2bc647aa-17f2-4894-9a9b-57437aec41b8'
            }
          >
            Privacy Policy
          </a>
          ,{' '}
          <a
            className={'text-baby-blue hover:underline'}
            target={'_blank'}
            href={
              'https://app.termly.io/document/cookie-policy/4dff86c2-dc30-4aa5-b873-ebb1a4ada253'
            }
          >
            Cookie Policy
          </a>
          , and{' '}
          <a
            className={'text-baby-blue hover:underline'}
            target="_blank"
            href={
              'https://app.termly.io/document/disclaimer/81bcd7ab-a5b9-4fb1-85a4-d4a535e291e9'
            }
          >
            Disclaimer
          </a>
        </label>
      </div>
    </div>
  );
};
