import React, { useEffect } from 'react';
import { ICourse } from '../../../../../../../../types/course';
import { Button, Checkbox, SelectField } from '../../../../../../../../ui';
import { CheckBoxSize } from '../../../../../../../../ui/checkbox';
import { ISelectOption, SelectSize } from '../../../../../../../../ui/select';
import classNames from 'classnames';
import { ButtonBorderRadius, ButtonSize, ButtonVariant } from '../../../../../../../../ui/button';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { termSeasonService } from '../../../../../../../../services/term-season/TermSeasonService';
import { studentYearService } from '../../../../../../../../services/student-year-service';
import { SearchCourses } from '../search-requirement-courses';
import { ISelectedDegreeRequirementCourse } from '../../types';
import { IValidationErrorResponse } from '../../../../../../../../types/error';


export interface IRequirementCourseListProps {
  isEdit: boolean;
  schoolId: number;
  gradeLevels: ISelectOption[];
  availableSeasons: ISelectOption[];
  list: ICourse[];
  error?: IValidationErrorResponse;
  assigned: ISelectedDegreeRequirementCourse[];
  selected: ISelectedDegreeRequirementCourse[];
  isLoading: boolean;
  handleFetch: () => void;
  handleAssign: (courseId: number) => void;
  handleUnAssign: (course: ISelectedDegreeRequirementCourse) => void;
  handleSelect: (course: ISelectedDegreeRequirementCourse) => void;
  handleScheduleRequirementChange: (course: ISelectedDegreeRequirementCourse) => void;
  handleOnSearch?: (value: string) => void;
}

export const RequirementCourses: React.FC<IRequirementCourseListProps> = ({
  schoolId,
  gradeLevels,
  availableSeasons,
  list,
  assigned,
  selected,
  isLoading,
  handleFetch,
  handleAssign,
  handleUnAssign,
  handleSelect,
  handleScheduleRequirementChange,
  handleOnSearch,
  isEdit,
  error
                                                            }) => {

  useEffect(() => {
    if(schoolId) {
      isEdit && handleFetch();
    }
  }, [schoolId]);

  return (
    <>
      {!!assigned.length && (
        <>
          <h1 className={"text-black mb-2 mt-8"}>
            Assigned Courses
          </h1>
          <p className={"text-xs text-neutral w-1/2 mb-4"}>These are the courses that are assigned to this degree requirement</p>
        </>
      )}
      {!!assigned.length && <div>
        <div className={"px-2 flex items-center border-b border-b-neutral-lighter"}>
          <div className={"mr-2 w-4/12"}>
            <span className={"text-black text-xs font-semibold"}>Name</span>
          </div>
          <div className={"mr-2 w-1/12"}>
            <span className={"text-black text-xs font-semibold"}># of Units</span>
          </div>
          <div className={"mr-2 w-2/12"}>
            <span className={"text-black text-xs font-semibold"}>Must be taken year</span>
          </div>
          <div className={"mr-2 w-2/12"}>
            <span className={"text-black text-xs font-semibold"}>Term</span>
          </div>
        </div>
        {assigned.map((course: ISelectedDegreeRequirementCourse) => {
          return (
            <div className={"px-2 flex items-center py-2 h-16 border-b border-b-neutral-lighter"}>
              <div className={"mr-2 w-4/12"}>
                <span className={"text-black text-sm"}>{course.name}</span>
              </div>
              <div className={"mr-2 w-1/12"}>
                <span className={"text-black text-sm"}>{course.credits}</span>
              </div>
              <div className={"mr-2 w-2/12"}>
                {/*// @ts-ignore: Object is possibly 'null'.*/}
                <span className={"text-black text-sm"}>{course.studentYear == null ? "Any Year" : studentYearService.getStudentYearAsString(course.studentYear)}</span>
              </div>
              <div className={"mr-2 w-2/12"}>
                {/*// @ts-ignore: Object is possibly 'null'.*/}
                <span className={"text-black text-sm"}>{course?.termSeason >= 0 ? termSeasonService.getSeasonAsString(course.termSeason) : "Any term"}</span>
              </div>
              <div className={"mr-2 w-2/12 flex justify-end"}>
                {isEdit && <BiTrash onClick={() => handleUnAssign(course)} className={'fill-red cursor-pointer'} />}
              </div>
            </div>
          )})
        }
      </div>}

      {isEdit && !!error?.errors['courses']?.length && !assigned.length && <div className={"flex text-red text-sm"}>You must assign at least one course to the degree requirement</div>}
      {!!list.length && <h3 className={"text-md text-neutral-darkest mt-4 mb-2"}>Available Courses</h3>}
      {!!list.length && <p className={"text-xs text-neutral w-1/2 mb-4"}>These are the courses that are available to be assigned to this degree requirement</p>}

      {!!handleOnSearch && !!list.length && <SearchCourses handleOnChange={(e) => handleOnSearch(e)} />}

      {!!list.length && list.map((course: ICourse) => {
        const isSelected = selected.map((s: ISelectedDegreeRequirementCourse) => s.courseId).includes(course.id);
        return (
          <div className={classNames("px-2 flex items-center py-2 h-16 border-b border-b-neutral-lighter", { "bg-baby-blue-lightest border-b-baby-blue-lighter": isSelected  })}>
            <div className={"mr-2 w-4/12"}>
              <Checkbox disabled={!isEdit} label={`${course.code} ${course.name}`} labelClassNames={'text-black text-sm ml-2 cursor-pointer'} onChange={() => handleSelect({
                courseId: course.id,
                name: course.name,
                credits: course.credits,
                studentYear: null,
              })} checked={isSelected} size={CheckBoxSize.md} />
            </div>
            <div className={"mr-2 w-1/12"}>
              <span className={"text-black text-sm"}>{course.credits} units</span>
            </div>
            {isSelected && (
              <>
                <div className={"w-3/12 mr-2"}>
                  <SelectField required={true} onChange={(option) => handleScheduleRequirementChange({
                    courseId: course.id,
                    name: course.name,
                    credits: course.credits,
                    studentYear: option.value,
                    termSeason: selected.find((s: ISelectedDegreeRequirementCourse) => s.courseId == course.id)?.termSeason
                  })} error={{ errors: {}, message: "" }} options={gradeLevels} size={SelectSize.xs} placeholder={"Required Year?"}/>
                </div>
                <div className={"w-3/12 mr-2"}>
                  <SelectField required={false} onChange={(option) => handleScheduleRequirementChange({
                    courseId: course.id,
                    name: course.name,
                    credits: course.credits,
                    studentYear: selected.find((s: ISelectedDegreeRequirementCourse) => s.courseId == course.id)?.studentYear ?? null,
                    termSeason: option.value
                  })} error={{ errors: {}, message: "" }} options={availableSeasons} size={SelectSize.xs} placeholder={"Required Term?"}/>
                </div>
                <div className={"w-1/12 mr-2 flex justify-end"}>
                  <Button handleOnClick={() => handleAssign(course.id)} size={ButtonSize.Small} variant={ButtonVariant.Primary} radius={ButtonBorderRadius.MD}>
                    Assign
                  </Button>
                </div>
              </>
            )}
          </div>
        )
      })}
    </>
  )
}
