import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Routes as DegreelyRoutes } from '../../../common/routes';
import { CreateAcademicYear } from './academic-years/create';
import { AdminOverview } from './overview';
import { AdminStudentData } from './students';
import { AdminStudent } from './student';
import { AdminStudentProgression } from './student/progression';
import { AdminStudentProfile } from './student/profile';
import { AdminCoursesData } from './courses';
import { AdminCourseData } from './course';
import { AdminManagePages } from './manage';
import { AdminStudentInvitationsPage } from './student-invitations';
import { Layout } from '../../../shared/layout';
import { Greeting } from '../../../shared/greeting';
import { StudentDetailDrawer } from '../drawers/student-detail';
import { AcademicYearPage } from './academic-years/show';
import { AdminStudentCourseRequests } from './student/course-requests';

export const AdminApp: React.FC = () => {
  return (
    <>
      <Layout>
        <div className={'flex w-full'}>
          <section className={'w-full py-8 px-16'}>
            <Greeting />
            <section className={'bg-white rounded-sm p-10'}>
              <Routes>
                <Route
                  path={DegreelyRoutes.admin.academicYears.create}
                  element={<CreateAcademicYear />}
                />
                <Route
                  path={DegreelyRoutes.admin.academicYears.show}
                  element={<AcademicYearPage />}
                />
                <Route
                  path={"/overview"}
                  element={<AdminOverview />}
                />
                <Route
                  path={DegreelyRoutes.admin.overview.students.index}
                  element={<AdminStudentData />}
                />
                <Route
                  path={DegreelyRoutes.admin.overview.students.show}
                  element={<AdminStudent />}
                >
                  <Route path={DegreelyRoutes.admin.overview.students.show} element={<AdminStudentProgression />} />
                  <Route path={DegreelyRoutes.admin.overview.students.profile} element={<AdminStudentProfile />} />
                  <Route path={DegreelyRoutes.admin.overview.students.courseRequests.index} element={<AdminStudentCourseRequests />} />
                </Route>
                <Route
                  path={DegreelyRoutes.admin.overview.courses.index}
                  element={<AdminCoursesData />}
                />
                <Route
                  path={DegreelyRoutes.admin.overview.courses.show}
                  element={<AdminCourseData />}
                />
                <Route
                  path={DegreelyRoutes.admin.manage.index}
                  element={<AdminManagePages />}
                />
                <Route
                  path={DegreelyRoutes.admin.invitations.index}
                  element={<AdminStudentInvitationsPage />}
                />
              </Routes>
            </section>
          </section>
          <StudentDetailDrawer />
        </div>
      </Layout>
    </>
  )
}
