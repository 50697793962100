import {PreferenceBucket} from "./preference-bucket";
import React from "react";
import {IActivePreference} from "../index";
import { ICourseRequest, ICourseRequestGroup } from '../../../../../../api/registration-periods';

export interface ICoursePreferencesProps {
    groups: any[];
    onDragEnd: (result: any) => void;
}

export const CoursePreferences: React.FC<ICoursePreferencesProps> = ({ groups, onDragEnd }) => {

    return (
      <>
        <div className={'flex items-center'}>
          <h3 className={'text-lg font-semibold text-black mb-4 w-1/3'}>Selection</h3>
          <h3 className={'text-lg font-semibold text-neutral ml-3 mb-4 w-1/3'}>Backups</h3>
        </div>
        { groups.map((group, index) => {
          return (
            <PreferenceBucket onDragEnd={onDragEnd} requests={group.courseRequest} key={index}/>
          )
        })}
      </>
    )
}
