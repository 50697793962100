import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../../api';
import { IEpic } from '../../../../../../../../infrastructure/selector';
import { Routes } from '../../../../../../../../common/routes';
import * as alertActions from '../../../../../../../../common/alerts/actions';
import { AlertVariant } from '../../../../../../../../common/alerts/reducer';
import { generatePath } from 'react-router-dom';

const onDestroyError: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.error),
    map(({ payload }) => alertActions.trigger({
      heading: "Something went wrong!",
      message: payload.message,
      variant: AlertVariant.Error
    }))
  );

const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = state$.value.common.match.params.requirementId;

      return from(
        api.degrees.destroyDegreeRequirement(degreeId, requirementId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.destroy.done());
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.destroy.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

const onDestroyComplete: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.done),
    map(() => {
      const degreeId = state$.value.common.match.params.degreeId;

      window.location.href = generatePath(Routes.admin.manage.degrees.show, { id: degreeId });

      return EMPTY;
    })
  );

export const epic = combineEpics(destroy, onDestroyComplete, onDestroyError);
