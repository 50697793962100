import React, {useEffect, useState} from 'react';
import { CourseCardSimple } from '../../../../../../components/course-card-simple';
import * as actions from './actions';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { ICourseRequest } from '../../../../../../api/course-requests';
import * as modalActions from "../modals/course-request-backups-modal/actions";
import classNames from "classnames";
import {IoIosWarning} from "react-icons/io";
import {FaCircleCheck} from "react-icons/fa6";

export const UpcomingYearRequests: React.FC = () => {

  const dispatch = useDispatch();

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const { courses, studentId } = useSelector(
    (state) => ({
      studentId: state.common.user.info.id,
      courses: state.domains.student.pages.courseRequests.requests.list
    }),
    shallowEqual
  );

  useEffect(() => {
    if(studentId) {
      dispatch(actions.fetch())
    }
  }, [studentId]);

  const onDrop = (event: any) => {
    event.preventDefault();
    let courseId = event.dataTransfer.getData("id");
    dispatch(actions.add.start(parseInt(courseId)));
  }

  const onDragOver = (event: any) => {
    event.preventDefault();
  }

  return (
    <>
      <div className={'border-b border-neutral-light w-full pb-4 mt-4'}>
        <h3 className={'text-black text-lg font-semibold mb-1'}>Upcoming Year Course Requests</h3>
        <p className={'text-neutral text-xs'}>Drop in preferred classes for next year. Click on classes to set
          backup options.</p>
      </div>
      <section
        onDragEnter={() => setIsDraggingOver(true)}
        onDragLeave={() => setIsDraggingOver(false)}
        style={{ minHeight: 150 }}
        className={classNames('mt-5 flex bg-neutral-lightest rounded-md py-2 border border-dashed ', {'border-neutral-light': !isDraggingOver}, {'border-baby-blue bg-baby-blue-lightest': isDraggingOver})}
        onDragOver={(event) => onDragOver(event)}
        onDrop={(event) => {
            onDrop(event)
            setIsDraggingOver(false)
        }}
      >
        <div className={'flex flex-wrap w-full'}>
          {courses.map((request: ICourseRequest, index: number) => {
            return (
              <div className={"w-1/4"}>
                <div className='mx-2'>
                  <CourseCardSimple
                      alert={!request.backups.length ? {
                          message: <span className={"ml-2 block text-xxs text-red uppercase font-semibold tracking-wider"}>No Backups</span>,
                          icon: <IoIosWarning className={"fill-red uppercase font-semibold"} />}
                          : {
                          message: <span className={"ml-2 block text-xxs text-green uppercase font-semibold tracking-wider"}>{request.backups[0].course.code + " Backup"}</span>,
                              icon: <FaCircleCheck className={"fill-green uppercase font-semibold"} />
                      }}
                      onClick={() => dispatch(modalActions.open(request.id))}
                      onDestroy={() => dispatch(actions.remove.start(request.id))}
                      isDraggable={false}
                      name={request.course.name}
                      code={request.course.code}
                      courseId={request.course.id}
                      credits={request.course.credits}
                      borderColor={request.course.requirementCategories?.length ? request.course.requirementCategories[0].color : "#ccc"} />
                </div>
              </div>
            )
          })}

            {!courses.length && <div className={"flex items-center justify-center w-full"}><span className={classNames("text-sm", {"text-neutral-dark": !isDraggingOver}, {"text-baby-blue": isDraggingOver})}>Drag courses here to request them for the upcoming year.</span></div>}

        </div>
      </section>
    </>
  )
}
