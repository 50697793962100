import * as React from 'react';
import { createContext, useState, useCallback, useEffect } from 'react';

const ToastsContext = createContext<any>([]);

export const useToasts = () => {
  return React.useContext(ToastsContext);
};

export default ToastsContext;

interface IToastProviderProps {
  children: React.ReactNode;
}

export enum ToastVariant {
  Success,
  Info,
  Warning,
  Error,
}

export const ToastVariantMapping: Record<ToastVariant, string> = {
  [ToastVariant.Success]: 'bg-green-lightest border-green text-green',
  [ToastVariant.Info]: 'bg-blue-lightest border-blue text-blue',
  [ToastVariant.Warning]: 'bg-yellow-lightest border-yellow text-yellow',
  [ToastVariant.Error]: 'bg-red-lightest border-red text-red',
}

export interface IToast {
  message: string;
  variant: ToastVariant;
}

export const ToastProvider: React.FC<IToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  useEffect(() => {
    const timer = setTimeout(
      () => setToasts((toasts) => toasts.slice(1)),
      3000
    );
    return () => clearTimeout(timer);
  }, [toasts]);

  const addToast = useCallback(
    function (toast: IToast) {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts]
  );

  return (
    <ToastsContext.Provider value={[addToast]}>
      {children}
      <>
        {!!toasts.length &&
          toasts.map((toast: IToast, index: number) => {

            let classes =
              `${ToastVariantMapping[toast.variant]} shadow-md font-semibold text-xs fixed border p-3 rounded-lg top-5 right-5 flex items-center justify-between`;

            return (
              <div key={index} className={classes} style={{ zIndex: 10000 }}>
                {toast.message}
                {/*<MdClose className={"fill-red text-lg"}/>*/}
              </div>
            );
          })}
      </>
    </ToastsContext.Provider>
  );
};
