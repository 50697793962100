import React from 'react';
import { IoCalendarOutline } from 'react-icons/io5';
import { SimpleAction } from '../../../../../../components/simple-action';
import { FaPlus } from 'react-icons/fa6';
import { shallowEqual } from 'react-redux';
import { useQuery } from 'react-query';
import { academicYear } from '../../../../../../api/academic-years';
import { useSelector } from '../../../../../../infrastructure/selector';
import moment from 'moment';

export interface ITermsProps {
  isDisabled?: boolean;
  handleModalOpen: () => any;
  handleEditModalOpen?: (term: { id: number; label: string; startDate: string; endDate: string }) => any;
}

export const Terms: React.FC<ITermsProps> = ({ isDisabled, handleModalOpen, handleEditModalOpen }) => {
  const { schoolId, academicYearId } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      academicYearId: state.common.match.params.id,
    }),
    shallowEqual
  );

  const { data, isLoading, error } = useQuery(`academicYearTerms-${academicYearId}`, () => academicYear.getAcademicYearTermsByAcademicYearId(schoolId, academicYearId), {
    enabled: !!schoolId && !!academicYearId && !isDisabled,
  });

  let list = data?.data;

  return (
    <section className={'mt-10 mb-8'}>
      <div className={"flex items-center justify-between"}>
      <h3 className={"text-lg text-neutral-darkest mb-2"}>Terms</h3>
        {!isDisabled && <SimpleAction
          disabled={isDisabled}
          onClick={() => handleModalOpen()}
          icon={FaPlus}
          label={"Add Term"}
        />}
      </div>

      <hr className={"border-b border-neutral-lighter mb-4"}/>
        <div  className={"flex items-center"}>
      {!!list?.length && list?.map((term, index) => {
        return (
                <div key={index} className={"rounded-lg bg-neutral-lightest mr-2 p-6 hover:bg-neutral-lighter cursor-pointer"} onClick={() => handleEditModalOpen && handleEditModalOpen({
                    id: term.id,
                    label: term.label,
                    startDate: term.startDate,
                    endDate: term.endDate
                })}>
                    <h4 className={"font-semibold text-sm text-neutral-darkest"}>#{index + 1}: {term.label}</h4>
                    <span className={"text-neutral-dark text-xs font-medium"}>{moment(term.startDate).format("MM/DD/YYYY")}</span>
                    <span className={"text-neutral-dark text-xs px-2 font-medium"}>-</span>
                    <span className={"text-neutral-dark text-xs font-medium"}>{moment(term.endDate).format("MM/DD/YYYY")}</span>
                </div>
        )
      })}
        </div>

      {!list?.length && <div className={"flex items-center justify-center mt-12"}>
        <div className={"text-center flex flex-col justify-center items-center"}>
          <IoCalendarOutline className={"fill-neutral-light stroke-neutral-light text-2xl mb-1"} />
          <h3 className={"text-black font-semibold"}>No Terms</h3>
          <p className={"text-neutral-dark text-sm"}>You have not created any terms for this school year</p>
          <div className={"mt-4"}>
            <SimpleAction disabled={isDisabled} icon={FaPlus} label={"Add Term"} onClick={() => handleModalOpen()} />
          </div>
        </div>
      </div>}
    </section>
  )
}
