import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import { generatePath, Link } from 'react-router-dom';
import { Routes } from '../../../../../../../../common/routes';
import { RequirementDetails } from '../../components/requirement-details';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import * as modalActions from "../../modals/delete-requirement/actions";
import { FaArrowLeft } from 'react-icons/fa';

export const RequirementInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { degreeId, requirementId, info } = useSelector(
    (state) => ({
      info: state.pages.admin.settings.degrees.requirements.show.info,
      degreeId: state.common.match.params.degreeId,
      requirementId: state.common.match.params.requirementId,
    }),
    shallowEqual
  );

  const { name, numberRequired, type, color, error, fallbackRequirement, fallbackRequirementOptions, isLoading } = info;

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Link className={"flex items-center"} to={generatePath("/admin/manage" + Routes.admin.manage.degrees.show, { id: degreeId })}>
        <FaArrowLeft className={"fill-baby-blue text-sm"}/>
        <span className={"text-sm ml-1 block text-baby-blue"}>Back</span>
      </Link>
      <div className={"flex items-center justify-between mt-4"}>
        <h1 className={'text-neutral-darkest text-lg mb-2'}>
          Degree Requirement: {name}
        </h1>
        <div className={"flex items-center"}>
          <Link className={"flex items-center"} to={generatePath(Routes.admin.manage.degrees.requirements.update, { degreeId, requirementId })}>
            <AiOutlineEdit className={"fill-baby-blue"}/>
            <span className={"text-sm ml-1 block text-baby-blue"}>Edit</span>
          </Link>
          <div onClick={() => dispatch(modalActions.open())} className={'ml-2 flex items-center cursor-pointer'}>
            <BiTrash className={"fill-red"} />
            <span className={"text-red text-sm ml-1"}>Delete</span>
          </div>
        </div>
      </div>

      <hr className={''} />

      <RequirementDetails
        error={error}
        name={name}
        type={{ label: type.label, value: type.value }}
        color={color}
        numberRequired={numberRequired}
        fallbackRequirement={{ value: fallbackRequirement.id?.toString(), label: fallbackRequirement.name }}
        fallbackRequirementOptions={fallbackRequirementOptions} />
    </>
  );
};
