import { AxiosPromise } from 'axios';
import client from '../axios.client';
import { ICourse } from '../../types/course';

export interface ICourseCompletion {
  id: number;
  courseId: number;
  studentId: number;
}

export interface ICourseCompletionsAPI {
  getCourseCompletions(
    studentId: number
  ): AxiosPromise<ICourse[]>;
  addCourseCompletion(
    studentId: number,
    courseId: number
  ): AxiosPromise;
  removeCourseCompletion(
    studentId: number,
    courseId: number
  ): AxiosPromise;
}

class CourseCompletionsAPI implements ICourseCompletionsAPI {

  getCourseCompletions(
    studentId: number,
  ): AxiosPromise<ICourse[]> {
    return client.get(`/api/v1/registration/student/${studentId}/course-completions`);
  }

  addCourseCompletion(studentId: number, courseId: number): AxiosPromise {
    return client.post(`/api/v1/registration/student/${studentId}/course-completions/${courseId}`);
  }

  removeCourseCompletion(studentId: number, courseId: number): AxiosPromise {
    return client.delete(`/api/v1/registration/student/${studentId}/course-completions/${courseId}`);
  }

}

export const courseCompletions = new CourseCompletionsAPI();
