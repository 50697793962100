import React, { useEffect } from 'react';
import { SearchInput } from '../../../../../../components/search-input';
import { CourseCardSimple } from '../../../../../../components/course-card-simple';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from "./actions";
import { useSelector } from '../../../../../../infrastructure/selector';
import { ICourse } from '../../../../../../types/course';
import { debounce } from 'lodash';
import { CourseBinFilterDropdown } from '../../../../../../common/filters/course-bin';

export const CourseBin: React.FC = () => {

  const dispatch = useDispatch();

  const { courses, schoolId, search } = useSelector(
    (state) => ({
      schoolId: state.common.user.info.schoolId,
      search: state.domains.student.pages.courseRequests.courseBin.search,
      courses: state.domains.student.pages.courseRequests.courseBin.list
    }),
    shallowEqual
  );

  return (
    <>
      <div>
        <h3 className={'text-black text-lg font-semibold mb-3'}>Course Bin</h3>
        <div className={'flex justify-between'}>
          <div className='w-1/2 mr-1'>
            <SearchInput value={search} placeholder={"Search Courses"} handleOnChange={debounce((e) => dispatch(actions.onSearch(e.target.value)), 250)} />
          </div>
          <div className='w-1/2 ml-1'>
            <CourseBinFilterDropdown />
          </div>
        </div>
        <section style={{ maxHeight: 725 }} className={"mt-4 flex flex-col overflow-y-auto hide-scrollbar"}>
          {!!courses.length && courses.map((course: ICourse, index: number) => {
            return (
              <CourseCardSimple isDraggable={true} name={course.name} code={course.code} courseId={course.id} credits={course.credits}
                                borderColor={course.requirementCategories?.length ? course.requirementCategories[0].color : "#ccc"} />
            )
          })}
        </section>
      </div>
    </>
  )
}
