import { createAction } from 'redux-act';
import { IDegree } from '../../../../../../api/admin/degrees';
import { ISelectOption } from '../../../../../../ui/select';
import { IValidationErrorResponse } from '../../../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof create.start>
  | ReturnType<typeof create.done>
  | ReturnType<typeof create.error>;

export const create = {
  start: createAction(
    '[pages/admin/dashboard/settings/degrees/create] create.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/admin/dashboard/settings/degrees/create] create.error'
  ),
  done: createAction<IDegree>(
    '[pages/admin/dashboard/settings/degrees/create] create.done'
  ),
};

export const onChange = createAction<{
  [key: string]: string | boolean | undefined | number;
}>('[pages/admin/dashboard/settings/degrees/create] onChange');
export const onTypeChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/degrees/create] onTypeChange'
);
