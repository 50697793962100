// TODO: Need a better way of handling these front-end routes for route matching
export const studentDashboard = '/student/planner';
export const studentSchedules = '/student/planner/schedules';
export const studentNoSchedules = '/student/planner/schedules/create';
export const studentSchedule = '/student/planner/schedules/:id';
export const adminStudentSchedule = '/admin/students/:id';
export const adminSettingsCourse = '/admin/manage/courses/:id';
export const adminDegreeEdit = '/admin/manage/degrees/:id';
export const adminStudentProfile = '/admin/students/:id/profile';
export const adminCreateDegreeRequirement = "/admin/manage/degrees/:degreeId/requirements/create";
export const adminDegreeRequirementShow = '/admin/manage/degrees/:degreeId/requirements/:requirementId';
export const adminDegreeShowSchedule = '/admin/manage/degrees/:id/schedule';
export const adminDegreeUpdateRequirement = '/admin/manage/degrees/:degreeId/requirements/:requirementId/update';
export const academicYear = '/admin/academic-years/:id';
export const studentPreferenceSubmission = '/student/registration/:id/preferences/survey';
export const adminStudentCourseRequests = "/admin/students/:id/course-requests";
