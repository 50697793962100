import { createAction } from 'redux-act';
import { ICourseAvailability, ICourseAvailabilityOptions } from '../../../../../../../api/courses/availbility';
import { ISelectOption } from '../../../../../../../ui/select';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction(
  '[pages/admin/dashboard/settings/course/availability/create] fetch'
);

export const load = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/availability/create] load.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/availability/create] load.error'
  ),
  done: createAction<ICourseAvailabilityOptions>(
    '[pages/admin/dashboard/settings/course/availability/create] load.done'
  ),
};

export const create = {
  start: createAction(
    '[pages/admin/dashboard/settings/course/availability/create] create.start'
  ),
  error: createAction<any>(
    '[pages/admin/dashboard/settings/course/availability/create] create.error'
  ),
  done: createAction<ICourseAvailability>(
    '[pages/admin/dashboard/settings/course/availability/create] create.done'
  ),
};

export const onTermChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/course/availability/create] onTermChange'
);

export const onYearChange = createAction<ISelectOption>(
  '[pages/admin/dashboard/settings/course/availability/create] onYearChange'
);
