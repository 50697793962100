import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import { Modal } from '../../../../../../components/modal';
import { Button } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import * as actions from './actions';
import { Input } from '../../../../../../components/input';
import { SelectField } from '../../../../../../ui/select';
import {
  Checkbox,
  CheckBoxBorder,
  CheckBoxBorderSize,
  CheckboxLabelPosition,
  CheckBoxRounded,
} from '../../../../../../ui/checkbox';
import { ISeasonSelect } from './reducer';
import { Helpers } from '../../../../../../helpers';
import { IMajor, IMinor } from '../../../../../../api/majors';
import SkeletonModal from '../../../../../../components/modal/skeleton';

export const EditTrackModal: React.FC = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    isLoading,
    scheduleId,
    name,
    error,
    seasons,
    majors,
    minors,
    availableSeasons,
  } = useSelector(
    (state) => ({
      isOpen: state.pages.dashboard.modals.editTrack.isOpen,
      isLoading: state.pages.dashboard.modals.editTrack.isLoading,
      scheduleId: state.pages.dashboard.modals.editTrack.scheduleId,
      name: state.pages.dashboard.modals.editTrack.name,
      seasons: state.pages.dashboard.modals.editTrack.seasons,
      error: state.pages.dashboard.modals.editTrack.error,
      majors: state.pages.dashboard.modals.editTrack.majors,
      minors: state.pages.dashboard.modals.editTrack.minors,
      availableSeasons: state.pages.dashboard.modals.editTrack.availableSeasons,
    }),
    shallowEqual
  );

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return <SkeletonModal handleClose={() => dispatch(actions.close())} />;
  }

  return (
    <>
      <Modal
        handleClose={() => dispatch(actions.close())}
        isOpen={isOpen}
        renderBody={() => <></>}
        renderFooter={() => (
          <>
            <div className={'flex items-center justify-end mt-10'}>
              <div className="mr-2 w-2/12">
                <Button
                  size={ButtonSize.Medium}
                  style={ButtonStyle.Outline}
                  variant={ButtonVariant.Primary}
                  handleOnClick={() => dispatch(actions.close())}
                >
                  Cancel
                </Button>
              </div>
              <div className="w-3/12">
                  <Button
                      isLoading={isLoading}
                      size={ButtonSize.Medium}
                      style={ButtonStyle.Filled}
                      variant={ButtonVariant.Primary}
                      handleOnClick={() => dispatch(actions.update.start(scheduleId))}
                  >
                      Save Changes
                  </Button>
              </div>
            </div>
          </>
        )}
      >
        <div className={'flex flex-col'}>
          <div
            className={
              'flex items-center justify-between mb-6 border-b border-neutral-light'
            }
          >
            <div>
              <h2 className={'text-4xl text-black font-semibold mb-2'}>
                Edit Track
              </h2>
            </div>
          </div>
          <section className={'mb-4'}>
            <div className={'mb-3'}>
              <Input
                placeholder={'Track #1'}
                onChange={(e) => dispatch(actions.onChange(e.target.value))}
                name={'name'}
                label={'Name'}
                type={'text'}
                value={name}
                error={error}
                required={true}
              />
            </div>
            {/* TODO: Need to add selected majors vs available */}
            <div className="mb-3">
              <SelectField
                defaultValue={Helpers.mapToSelectOptions(
                  majors,
                  (m: IMajor) => ({ label: m.name, value: m.id.toString() })
                )}
                multiple={true}
                required={true}
                label={'Major'}
                placeholder={'Type to search majors...'}
                options={Helpers.mapToSelectOptions(majors, (m: IMajor) => ({
                  label: m.name,
                  value: m.id.toString(),
                }))}
                onChange={() => true}
                error={error}
              />
            </div>
            {/* TODO: Need to add selected minors vs available */}
            {minors.length && (
              <div className="mb-3">
                <SelectField
                  defaultValue={Helpers.mapToSelectOptions(
                    minors,
                    (m: IMinor) => ({ label: m.name, value: m.id.toString() })
                  )}
                  label={'Minor'}
                  placeholder={'Type to search minors...'}
                  options={Helpers.mapToSelectOptions(minors, (m: IMinor) => ({
                    label: m.name,
                    value: m.id.toString(),
                  }))}
                  onChange={() => true}
                  error={error}
                />
              </div>
            )}
          </section>
          {!!seasons.length && (
            <section>
              <h6
                className={'text-black font-medium text-sm border-b pb-2 mb-2'}
              >
                Term Seasons
              </h6>
              {seasons.map((season: ISeasonSelect, index: number) => (
                <div className={'mb-3'} key={index}>
                  <Checkbox
                    border={CheckBoxBorder.Black}
                    borderSize={CheckBoxBorderSize.TwoPixel}
                    containerClassNames={'w-full flex'}
                    labelClassNames={'text-black text-sm ml-4'}
                    labelPosition={CheckboxLabelPosition.AfterBox}
                    checked={season.checked}
                    label={season.label}
                    rounded={CheckBoxRounded.md}
                    onChange={() => dispatch(actions.season.select(index))}
                  />
                </div>
              ))}
            </section>
          )}
        </div>
      </Modal>
    </>
  );
};
