import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../infrastructure/selector';
import * as editTrackModalActions from '../modals/edit-track/actions';
import * as favoriteActions from './favorite/actions';

const fetchOnEdit: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(editTrackModalActions.update.done),
    map(() => actions.fetch())
  );

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(api.schedules.getSchedules()).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const favorite: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(favoriteActions.favorite.start),
    switchMap(({ payload }) =>
      from(api.schedules.favoriteSchedule(payload)).pipe(
        mergeMap(() => {
          return of(favoriteActions.favorite.done(), actions.fetch());
        })
      )
    ),
    catchError((error, source$) =>
      of(favoriteActions.favorite.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, fetchOnEdit, favorite);
