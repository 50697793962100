import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ISelectOption } from '../../../../../../ui/select';
import { IDegree, IDegreeRequirement } from '../../../../../../api/admin/degrees';

interface IState {
  list: IDegree[];
  isLoading: boolean;
  requirementsLoading: boolean;
  selectedDegree: ISelectOption;
  requirements: IDegreeRequirement[];
  selectedRequirement: ISelectOption;
}

const initialState: IState = {
  isLoading: false,
  requirementsLoading: false,
  list: [],
  selectedDegree: {
    label: '',
    value: '',
  },
  requirements: [],
  selectedRequirement: {
    label: '',
    value: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

reducer.on(actions.loadRequirements.start, (state, payload) => ({
  ...state,
  requirementsLoading: true,
}));

reducer.on(actions.loadRequirements.error, (state, payload) => ({
  ...state,
  requirementsLoading: false,
}));

reducer.on(actions.loadRequirements.done, (state, payload) => {
  return {
    ...state,
    requirementsLoading: false,
    requirements: [...payload.available],
  };
});

reducer.on(actions.onDegreeSelect, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    selectedDegree: payload,
  };
});

reducer.on(actions.onRequirementSelect, (state, payload) => {
  return {
    ...state,
    selectedRequirement: payload,
  };
});

export const majorsReducer = reducer;
