import React, { useState } from 'react';
import { ICourse } from '../../../../../types/course';
import { CourseCard } from '../../../../../components/course-card';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { Helpers } from '../../../../../helpers';
import Skeleton from 'react-loading-skeleton';
import { termSeasonService } from '../../../../../services/term-season/TermSeasonService';

export const Terms: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { currentTerm, nextTerm, isLoading } = useSelector(
    (state) => ({
      currentTerm:
        state.pages.admin.dashboard.drawers.studentDetail.currentTerm,
      nextTerm: state.pages.admin.dashboard.drawers.studentDetail.nextTerm,
      isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
    }),
    shallowEqual
  );

  if (isLoading) {
    return <Skeleton height={500} className={'rounded-lg mt-4'} />;
  }

  let term = activeTab == 0 ? currentTerm : nextTerm;

  const currentTermTabClasses = classNames(
    'w-1/2 text-baby-blue font text-center border text-sm rounded-md p-2 ',
    {
      'font-semibold bg-baby-blue-lightest border-baby-blue-lightest':
        activeTab == 0,
    },
    { 'font-normal, bg-white, border-baby-blue': activeTab == 1 }
  );
  const nextTermTabClasses = classNames(
    'w-1/2 text-baby-blue text-center ml-2 p-2 text-sm border rounded-md',
    { 'font-normal, bg-white, border-baby-blue': activeTab == 0 },
    {
      'font-semibold bg-baby-blue-lightest border-baby-blue-lightest':
        activeTab == 1,
    }
  );

  return (
    <section className={'mt-4'}>
      <div className={'bg-white rounded-md border-baby-blue p-4 border'}>
        <div className={'flex items-center mb-5'}>
          <div
            className={currentTermTabClasses}
            onClick={() => setActiveTab(0)}
          >
            Current Term
          </div>
          <div className={nextTermTabClasses} onClick={() => setActiveTab(1)}>
            Next Term
          </div>
        </div>
        {!!term && (
          <div className={'flex flex-col justify-between'}>
            <h5 className={'text-sm text-neutral-darkest font-semibold mb-1'}>
              {term.name}
            </h5>
            <span
              className={
                'uppercase text-baby-blue text-xs font-normal tracking-widest mb-6'
              }
            >
              {termSeasonService.getSeasonAsString(term.season)} {term.year}
            </span>
            {!term.courses.length && (
              <div className={'flex flex-col justify-between'}>
                <span className={'text-neutral-dark text-sm text-center'}>
                  There are no courses assigned to this term.
                </span>
              </div>
            )}
            {term.courses.map((course: ICourse) => (
              <CourseCard
                name={course.name}
                code={course.code}
                courseId={course.id}
                credits={course.credits}
                borderColor={
                  !!course.requirementCategories?.length
                    ? course.requirementCategories[0].color
                    : ''
                }
              />
            ))}
            <div className="mt-6">
              <span
                className={
                  'text-xs uppercase text-neutral-darkest font-semibold tracking-widest'
                }
              >
                {Helpers.getCoursesUnitTotal(term.courses)} units
              </span>
            </div>
          </div>
        )}
        {!term && (
          <div className={'flex flex-col justify-between'}>
            <span className={'text-neutral-dark text-sm text-center'}>
              There is no term data.
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
