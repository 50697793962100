import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Planner } from './planner';
import { Layout } from '../../../shared/layout';
import { NotificationBanners } from './planner/banners';
import { CreateNewTrackModal } from './planner/modals/create-track';
import { CourseDetailModal } from './planner/modals/course-detail';
import { DeleteTermModal } from './planner/modals/delete-term';
import { RequirementCategoryDetailModal } from './planner/modals/requirement-category-detail';
import { DeleteTrackModal } from './planner/modals/delete-track';
import { EditTrackModal } from './planner/modals/edit-track';
import { StudentRegistrationPage } from './registration';
import { routes } from '../routes';
import { CourseRequests } from './registration/course-requests';

export const StudentApp: React.FC = () => {

  return (
    <Layout>
      <>
        <NotificationBanners />
        <section className={'px-8 py-6 relative'}>
            <Routes>
              <Route
                path={routes.planner.index}
                element={<Planner />}
              />
              <Route
                path={routes.registration.index}
                element={<StudentRegistrationPage />}
              />
              <Route
                path={routes.courseRequest.index}
                element={<CourseRequests />}
              />
            </Routes>
        </section>
        <CreateNewTrackModal />
        <CourseDetailModal />
        <DeleteTermModal />
        <RequirementCategoryDetailModal />
        <DeleteTrackModal />
        <EditTrackModal />
      </>
    </Layout>
  )
}
