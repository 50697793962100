import { createAction } from 'redux-act';
import { ICourse } from '../../../../../../types/course';
import {
  ICourseStatusInformation,
  IUserGeneratedCourseDetails,
} from '../../../../../../api/courses';
import { IValidationErrorResponse } from '../../../../../../types/error';

export type IActions =
  | ReturnType<typeof beforeOpen>
  | ReturnType<typeof open>
  | ReturnType<typeof close>
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export interface ICourseDetailOpenPayload {
  courseId: number;
  termId: number;
}

export const beforeOpen = createAction<ICourseDetailOpenPayload>(
  '[pages/dashboard/modals/course-detail] beforeOpen'
);
export const open = createAction<ICourseDetailOpenPayload>(
  '[pages/dashboard/modals/course-detail] open'
);
export const close = createAction(
  '[pages/dashboard/modals/course-detail] close'
);

export const toggleEdit = createAction(
  '[pages/dashboard/modals/course-detail] toggleEdit'
);

export const fetch = createAction<ICourseDetailOpenPayload>(
  '[pages/dashboard/modals/course-detail] fetch'
);

export const load = {
  start: createAction<ICourseDetailOpenPayload>(
    '[pages/dashboard/modals/course-detail] load.start'
  ),
  error: createAction<any>('[pages/dashboard/modals/course-detail] load.error'),
  done: createAction<ICourse>(
    '[pages/dashboard/modals/course-detail] load.done'
  ),
};

export const loadStatus = {
  start: createAction<ICourseDetailOpenPayload>(
    '[pages/dashboard/modals/course-detail] loadStatus.start'
  ),
  error: createAction<any>(
    '[pages/dashboard/modals/course-detail] loadStatus.error'
  ),
  done: createAction<ICourseStatusInformation>(
    '[pages/dashboard/modals/course-detail] loadStatus.done'
  ),
};

export const onNameChange = createAction<string>(
  '[pages/dashboard/modals/course-detail] onNameChange'
);
export const onCourseCodeChange = createAction<string>(
  '[pages/dashboard/modals/course-detail] onCourseCodeChange'
);
export const onUnitChange = createAction<number>(
  '[pages/dashboard/modals/course-detail] onUnitChange'
);

export const addCustomCourseDetails = {
  start: createAction(
    '[pages/dashboard/modals/course-detail] addCustomCourseDetails.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/dashboard/modals/course-detail] addCustomCourseDetails.error'
  ),
  done: createAction<IUserGeneratedCourseDetails>(
    '[pages/dashboard/modals/course-detail] addCustomCourseDetails.done'
  ),
};

export const updateCustomCourseDetails = {
  start: createAction(
    '[pages/dashboard/modals/course-detail] updateCustomCourseDetails.start'
  ),
  error: createAction<IValidationErrorResponse>(
    '[pages/dashboard/modals/course-detail] updateCustomCourseDetails.error'
  ),
  done: createAction<IUserGeneratedCourseDetails>(
    '[pages/dashboard/modals/course-detail] updateCustomCourseDetails.done'
  ),
};
