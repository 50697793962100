import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { IValidationErrorResponse } from '../../types/error';
import { Label } from '../label';
import { Helpers } from '../../helpers';

export enum SelectBorderVariant {
  Primary,
  Secondary,
  Tertiary,
  Neutral,
}

export enum SelectSize {
  xs,
  sm,
  md,
  lg,
  xl,
}

export enum SelectVariant {
  Transparent,
  Primary,
  Neutral,
}

export const controlStyles = {
  backgroundColor: 'white',
  border: '1px solid black',
  borderRadius: '12px',
  padding: '4px',
  minHeight: 38,
};

const controlErrorStyles = {
  backgroundColor: 'white',
  border: '1px solid red',
  borderRadius: '12px',
  padding: '4px',
  minHeight: 38,
};

export interface ISelectOption {
  label: string;
  value: string;
}

interface ISelectFieldProps {
  label?: string;
  options: ISelectOption[];
  onChange: (option: any) => any;
  error: IValidationErrorResponse;
  placeholder?: string;
  required?: boolean;
  name?: string;
  multiple?: boolean;
  defaultValue?: ISelectOption[];
  size?: SelectSize;
  variant?: SelectVariant;
  containerStyles?: string;
  labelStyles?: string;
  borderVariant?: SelectBorderVariant;
  isClearable?: boolean;
  disabled?: boolean;
}

// TODO: Write tests for this component
export const SelectField: React.FC<ISelectFieldProps> = ({
  borderVariant,
  variant,
  name,
  options,
  onChange,
  error,
  placeholder,
  label,
  required,
  multiple,
  defaultValue,
  size,
  containerStyles,
  labelStyles,
  isClearable,
  disabled
}) => {
  const fieldName = name ?? '';
  const hasError = Helpers.hasError(error, fieldName);
  let labelClasses = 'text-sm font-semibold text-neutral-darkest mb-2';

  let fieldStyles = {
    ...controlStyles,
  };

  if (hasError) {
    fieldStyles = {
      ...controlErrorStyles,
    };
  }

  let inputStyles = {};

  if (size === SelectSize.xs) {
    fieldStyles = {
      ...fieldStyles,
      padding: '0 10px',
      minHeight: 28,
    };
    inputStyles = {
      ...inputStyles,
      padding: '0 8px',
    };
  }

  if (variant === SelectVariant.Transparent) {
    fieldStyles = {
      ...fieldStyles,
      backgroundColor: 'transparent',
    };
  }

  if (variant === SelectVariant.Neutral) {
    fieldStyles = {
      ...fieldStyles,
      backgroundColor: '#F1F1F1',
      border: '1px solid #F1F1F1',
    };
  }

  if (borderVariant === SelectBorderVariant.Primary) {
    fieldStyles = {
      ...fieldStyles,
      border: '1px solid #007AFF',
    };
  }

  if (labelStyles) {
    labelClasses = `${labelStyles}`;
  }

  const colorStyles: StylesConfig = {
    control: (styles) => ({ ...styles, ...fieldStyles }),
    option: (styles) => ({ ...styles }),
    input: (styles) => ({ ...styles, ...inputStyles }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  };

  return (
    <div className={containerStyles}>
      {label && (
        <Label classNames={labelStyles} name={label} required={required} />
      )}
      <Select
        isDisabled={disabled}
        isClearable={isClearable}
        defaultValue={defaultValue}
        isMulti={!!multiple}
        styles={colorStyles}
        className={'text-sm w-full text-black'}
        placeholder={placeholder}
        options={options}
        onChange={(option) => onChange(option)}
      />
      {hasError && (
        <span className={'text-red text-xs'}>{error.errors[fieldName]}</span>
      )}
    </div>
  );
};

SelectField.defaultProps = {
  placeholder: '',
  label: '',
  required: false,
  name: '',
  multiple: false,
  size: SelectSize.md,
  variant: SelectVariant.Primary,
  borderVariant: SelectBorderVariant.Neutral,
  isClearable: false,
  disabled: false
};
