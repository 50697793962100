import { createAction } from 'redux-act';
import { ICourseDetailOpenPayload } from '../../modals/course-detail/actions';
import { ICourse } from '../../../../../../types/course';
import {IRegistrationCoursePreferenceSurveyResponse} from "../../../../../../api/registration/course-preferences";

// export type IActions = ReturnType<typeof open> | ReturnType<typeof close>;

export const show = createAction<number>(
  '[pages/dashboard/banners/preference-submission-notification] show'
);
export const dismiss = createAction(
  '[pages/dashboard/banners/preference-submission-notification] dismiss'
);

export const fetch = createAction(
  '[pages/dashboard/banners/preference-submission-notification] fetch'
);

export const load = {
  start: createAction(
    '[pages/dashboard/banners/preference-submission-notification] load.start'
  ),
  error: createAction<any>('[pages/dashboard/banners/preference-submission-notification] load.error'),
  done: createAction<IRegistrationCoursePreferenceSurveyResponse>(
    '[pages/dashboard/banners/preference-submission-notification] load.done'
  ),
};
