import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { ToastVariant, useToasts } from '../../../../../../hooks/toasts';
import { useSelector } from '../../../../../../infrastructure/selector';
import { Button } from '../../../../../../ui';
import { ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../../ui/button';
import { Modal } from '../../../../../../components/modal';
import { Input, InputSize } from '../../../../../../components/input';
import { DateSelect } from '../../../../../../ui/date-picker';
import { IoCalendarOutline } from 'react-icons/io5';
import { useMutation, useQueryClient } from 'react-query';
import {
    academicYear, IUpdateAcademicYearTermRequest,
} from '../../../../../../api/academic-years';
import { IValidationErrorResponse } from '../../../../../../types/error';
import { AxiosError } from 'axios';
import moment from "moment";
import { SimpleDangerAction } from '../../../../../../components/simple-action';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export interface IEditTermModalProps {
    isOpen: boolean;
    handleClose: () => any;
    term: { id: number; label: string; startDate: string; endDate: string };
}
export const EditTermModal: React.FC<IEditTermModalProps> = ({ isOpen, handleClose, term }) => {
    const queryClient = useQueryClient();
    const [errors, setErrors] = useState<IValidationErrorResponse>({ errors: {}, message: "" });
    const [termName, setTermName] = useState<string>(term?.label ?? "");
    const [termStartDate, setTermStartDate] = useState<Date | null>(moment().toDate());
    const [termEndDate, setTermEndDate] = useState<Date | null>(moment().toDate());

    const { schoolId, academicYearId } = useSelector(
        (state) => ({
            academicYearId: state.common.match.params.id,
            schoolId: state.common.configs.configuration.schoolId,
        }),
        shallowEqual
    );

    const [addToasts] = useToasts();

    useEffect(() => {
        setTermName(term?.label ?? "");
        setTermStartDate(moment(term.startDate).isValid() ? moment(term.startDate).toDate() : new Date());
        setTermEndDate(moment(term.endDate).isValid() ? moment(term.endDate).toDate() : new Date());
    }, [term]);

    const mutation = useMutation({
        mutationFn: (payload: IUpdateAcademicYearTermRequest) => {
            return academicYear.updateAcademicYearTerm({
                schoolId: schoolId,
                academicYearId: academicYearId,
                termId: payload.termId,
                label: payload.label,
                startDate: payload.startDate,
                endDate: payload.endDate
            })
        },
        onSuccess: (response) => {
            handleClose();
            queryClient.invalidateQueries(`academicYearTerms-${academicYearId}`);
            addToasts({
                message: "Term updated successfully",
                variant: ToastVariant.Success
            });
        },
        onError: (error: AxiosError<IValidationErrorResponse>) => {
            if(error?.response?.data) {
                setErrors(error.response.data);
            }
        }
    });

    const destroyMutation = useMutation({
        mutationFn: (termId: number) => {
            return academicYear.destroyAcademicYearTerm(schoolId, academicYearId, termId)
        },
        onSuccess: (response) => {
            handleClose();
            queryClient.invalidateQueries(`academicYearTerms-${academicYearId}`);
            addToasts({
                message: "Term destroyed successfully",
                variant: ToastVariant.Success
            });
        },
        onError: (error: AxiosError<IValidationErrorResponse>) => {
            if(error?.response?.data) {
                setErrors(error.response.data);
                addToasts({
                    message: error.response.data.message,
                    variant: ToastVariant.Error
                });
            }
        }
    });

    const handleUpdate = () => {
        mutation.mutate({
            termId: term?.id ?? 0,
            schoolId: schoolId,
            academicYearId: academicYearId,
            label: termName,
            startDate: termStartDate,
            endDate: termEndDate
        })
    }

    const handleDestroy = () => {
        if(term?.id) {
            destroyMutation.mutate(term.id);
        }
    }

    if (!isOpen) {
        return null;
    }

    const handleModalClose = () => {
        handleClose();
        setErrors({ errors: {}, message: "" });
    }

    return (
        <>
            <Modal
                handleClose={() => handleModalClose()}
                isOpen={isOpen}
                renderBody={() => <div></div>}
                renderFooter={() => (
                    <>
                        <section className={'flex items-center mt-6'}>
                            <div className='flex items-center w-1/2'>
                                <SimpleDangerAction icon={BiTrash} label={'Delete Term'} onClick={() => handleDestroy()} />
                            </div>
                            <div className={'flex items-center justify-end w-1/2'}>
                                <div className='mr-2 w-4/12'>
                                    <Button
                                      size={ButtonSize.Small}
                                      style={ButtonStyle.Outline}
                                      variant={ButtonVariant.Primary}
                                      handleOnClick={() => handleModalClose()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={'w-4/12'}>
                                    <Button
                                      handleOnClick={() => handleUpdate()}
                                      isLoading={mutation.isLoading}
                                      size={ButtonSize.Small}
                                      style={ButtonStyle.Filled}
                                      variant={ButtonVariant.Primary}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </section>
                    </>
                )}
            >
                <div className={'flex flex-col'}>
                    <div className={'flex flex-col'}>
                        <section className={'flex items-center justify-center'}>
                            <div className={'text-center flex flex-col justify-center items-center'}>
                                <IoCalendarOutline
                                  className={'fill-neutral-light stroke-neutral-light text-2xl mb-1'} />
                                <h2 className={'text-2xl text-black font-semibold mb-4 text-center'}>Edit Term</h2>
                            </div>
                        </section>
                        <div className={'w-full'}>
                            <div className='mb-4'>
                                <Input error={errors} value={termName} size={InputSize.md}
                                       onChange={(e) => setTermName(e.target.value)} type={'text'} required={true}
                                       name={'label'} placeholder={'Fall Term'} label={'Term Name'} />
                            </div>
                        </div>
                        <div className={'flex items-center'}>
                            <div className={'w-1/2'}>
                                <div className='pr-2'>
                                    <DateSelect error={errors} name={'startDate'} label={`Start Date`} onChange={(date) => setTermStartDate(date)} selected={termStartDate ?? new Date()} />
                                </div>
                            </div>
                            <div className={"w-1/2"}>
                                <div className='pl-2'>
                                    <DateSelect error={errors} name={"endDate"} label={`End Date`} onChange={(date) => setTermEndDate(date)} selected={termEndDate ?? new Date()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
