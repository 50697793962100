import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../../../../../types/error';
import { IDegreeRequirement } from '../../../../../../../../api/admin/degrees';

interface IState {
  isLoading: boolean;
  error: IValidationErrorResponse;
  list: IDegreeRequirement[];
}

const initialState: IState = {
  isLoading: false,
  list: [],
  error: {
    errors: {},
    message: '',
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
  error: payload,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

export const listRequirementsReducer = reducer;
