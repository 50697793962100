import React from 'react';
import { useSelector } from '../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import {UserRoleType} from "../../constants";
import { Routes as DegreelyRoutes } from "../../common/routes/index";
import {NavLink} from "react-router-dom";

export const Logo: React.FC = () => {
  const { domain, user } = useSelector(
    (state) => ({
      domain: state.common.configs.domain,
      user: state.common.user.info
    }),
    shallowEqual
  );

  const url = user?.roleId === UserRoleType.Student ? DegreelyRoutes.student.schedules : DegreelyRoutes.admin.overview.index;

  if(domain.universityDomain === 'app') {
    return (
      <>
        <div className="w-2/12 flex items-center">
            <NavLink to={url}>
                <img
                    height={40}
                    width={130}
                    src="/assets/logos/degreely-logo.png"
                    alt=""
                />
            </NavLink>
        </div>
      </>
    );
  }

  if(domain.universityDomain == 'sa') {
    return (
      <>
        <div className="w-2/12 flex items-center">
          <img
            className={'mr-2'}
            height={40}
            width={100}
            src="/assets/logos/sonoma-academy-logo.png"
            alt="sonoma-logo"
          />
        </div>
      </>
    );
  }

  if(domain.universityDomain == 'usc') {
    return (
      <>
        <div className={'w-2/12'}>
          <img
            height={40}
            width={100}
            src="https://customsitesmedia.usc.edu/wp-content/uploads/sites/55/2019/01/15234738/PrimShield-Mono_SmallUse_CardOnTrans_RGB.png"
            alt=""
          />
        </div>
      </>
    );
  }

  return (
    <>
        <div className="w-2/12 flex items-center">
          <img
            height={40}
            width={130}
            src="/assets/logos/degreely-logo.png"
            alt=""
          />
        </div>
    </>
  );
};
