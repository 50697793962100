import { generatePath, Link } from 'react-router-dom';
import * as actions from '../actions';
import { Routes as DegreelyRoutes } from '../../../../../common/routes';
import { Button } from '../../../../../ui';
import { ButtonBorderRadius, ButtonSize, ButtonStyle, ButtonVariant } from '../../../../../ui/button';
import { RequiresMessagingAccess } from '../../../../../common/guards/messaging-access';
import React from 'react';
import { useAppSelector } from '../../../../../app/hooks';
import { shallowEqual, useDispatch } from 'react-redux';
import { getAdminRoute } from '../../../routes';

export const Actions: React.FC = () => {

  const dispatch = useDispatch();

  const { name, major, avatarUrl, isLoading, email, studentId, flagCount, hasActiveSchedule, activePreview } =
    useAppSelector(
      (state) => ({
        activePreview: state.pages.admin.dashboard.drawers.studentDetail.activePreview,
        isLoading: state.pages.admin.dashboard.drawers.studentDetail.isLoading,
        hasActiveSchedule: state.pages.admin.dashboard.drawers.studentDetail.hasActiveSchedule,
        name: state.pages.admin.dashboard.drawers.studentDetail.name,
        studentId: state.pages.admin.dashboard.drawers.studentDetail.studentId,
        email: state.pages.admin.dashboard.drawers.studentDetail.email,
        avatarUrl: state.pages.admin.dashboard.drawers.studentDetail.avatarUrl,
        major: state.pages.admin.dashboard.drawers.studentDetail.major,
        flagCount: state.pages.admin.dashboard.drawers.studentDetail.flags,
      }),
      shallowEqual
    );

  return (
    <div className={'mt-1 flex items-center'}>
      {hasActiveSchedule && (
        <div className={"w-1/3 mr-1"}>
          <Link
            className={"block"}
            onClick={() => dispatch(actions.close())}
            to={generatePath(getAdminRoute(DegreelyRoutes.admin.overview.students.show), {
              id: studentId,
            })}
          >
            <Button
              style={ButtonStyle.Filled}
              variant={ButtonVariant.Primary}
              size={ButtonSize.XXXSmall}
              radius={ButtonBorderRadius.MD}
            >
              {!!name && `View ${name.split(' ')[0]}'s Plan`}
            </Button>
          </Link>
        </div>
      )}
      <RequiresMessagingAccess>
        <div className='w-1/3 ml-1'>
          <Button
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            size={ButtonSize.XXXSmall}
            radius={ButtonBorderRadius.MD}
            handleOnClick={() => {
              dispatch(actions.openMessageDrawerWithRecipient({
                id: studentId,
                name,
                avatarUrl,
              }));
            }}
          >
            {!!name && `Message ${name.split(' ')[0]}`}
          </Button>
        </div>
      </RequiresMessagingAccess>
      <div className='w-1/3 ml-1'>
        <Link
          className={"block"}
          onClick={() => dispatch(actions.close())}
          to={generatePath(getAdminRoute(DegreelyRoutes.admin.overview.students.courseRequests.index), {
            id: studentId,
          })}
        >
          <Button
            style={ButtonStyle.Filled}
            variant={ButtonVariant.Primary}
            size={ButtonSize.XXXSmall}
            radius={ButtonBorderRadius.MD}
            handleOnClick={() => {}}
          >
            View Course Requests
          </Button>
        </Link>
      </div>
    </div>
  )
}
