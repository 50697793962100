import { combineReducers } from 'redux';

import { schedulesReducer as schedules } from './schedules/reducer';
import { plannerReducer as dashboard } from '../domains/student/pages/planner/reducer';
import { adminPagesReducer as admin } from '../domains/admin/pages/reducer';
import { authReducer as auth } from '../domains/auth/reducer';
import { studentProfileReducer as studentProfile } from "./student-profile/reducer";
import { reducers as profile } from "./profile/reducer";

export const reducers = combineReducers({
  schedules,
  dashboard,
  admin,
  auth,
  studentProfile,
  profile
});
