import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.courses.availability.getCourseAvailabilityOptions(
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error.response.data)).pipe(concat(source$))
    )
  );

const create: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.create.start),
    switchMap(() =>
      from(
        api.courses.availability.createCourseAvailability({
          courseId: state$.value.common.match.params.id,
          termSeason: parseInt(state$.value.pages.admin.settings.course.availability.create.selectedTerm?.value) ?? null,
          termYear: parseInt(state$.value.pages.admin.settings.course.availability.create.selectedYear?.value) ?? null,
          available: false
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.create.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.create.error(error.response.data)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, create);
