import React, { useEffect } from 'react';
import { useSelector } from '../../../../../infrastructure/selector';
import { shallowEqual, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../components/button';
import * as actions from '../actions';
import { StylesConfig } from 'react-select';
import { Header } from './header';
import { AvatarUpload } from './avatar';
import { NameField } from './fields/name';
import { PronounsField } from './fields/pronouns';
import { SchoolField } from './fields/school';
import { ProgramField } from './fields/program';
import { UserRoleField } from './fields/user-role';
import { ProgramStartFields } from './fields/program-start';
import { IntendedGraduationFields } from './fields/intended-graduation';
import { LegalFields } from './fields/legal';
import { StudentIdField } from './fields/student-id';
import { PhoneField } from './fields/phone';

export const controlStyles = {
  backgroundColor: 'white',
  border: '1px solid black',
  borderRadius: '12px',
  padding: '4px',
};

export const controlErrorStyles = {
  backgroundColor: 'white',
  border: '1px solid red',
  borderRadius: '12px',
  padding: '4px',
};

export const colorStyles: StylesConfig = {
  control: (styles) => ({ ...styles, ...controlStyles }),
  option: (styles) => ({ ...styles }),
  input: (styles) => ({ ...styles }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
};

interface IRegisterPageTwoProps {
  handleNext: () => any;
}

export const RegisterPageTwo: React.FC<IRegisterPageTwoProps> = ({
  handleNext,
}) => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { isLoading, showEmailVerificationMessage } = register;

  useEffect(() => {
    if (showEmailVerificationMessage) {
      handleNext();
    }
  }, [showEmailVerificationMessage]);

  const handleRegister = async (e: any) => {
    e.preventDefault();
    dispatch(actions.fetch());
  };

  return (
    <div className={'p-10 flex flex-col items-center w-full'}>
      <Header />
      <AvatarUpload />

      <section className={'w-5/12'}>
        <NameField />
        <PhoneField />
        <PronounsField />
        <SchoolField />
        <ProgramField />
        <UserRoleField />
        <StudentIdField />
        <ProgramStartFields />
        <IntendedGraduationFields />
        <LegalFields />

        <div className={'mb-4'}>
          <Button
            className={
              'w-full bg-blue-darkest font-semibold text-white px-4 py-3 text- rounded-xl mt-6'
            }
            handleOnClick={(e) => handleRegister(e)}
            isLoading={isLoading}
          >
            Submit
          </Button>
          <p
            className={
              'text-center font-light text-neutral-darkest text-xs mt-4'
            }
          >
            Already have an account?{' '}
            <Link className={'text-baby-blue hover:underline'} to={'/login'}>
              Log in
            </Link>
          </p>
        </div>
      </section>
    </div>
  );
};
