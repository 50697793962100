import React from 'react';
import { DegreeRequirementsList } from './requirements/list';
import { Degree } from './degree';
import { generatePath, Link, NavLink, Outlet } from 'react-router-dom';
import { Routes } from '../../../../../../common/routes';
import { useSelector } from '../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { FaArrowLeft } from 'react-icons/fa';

export const AdminEditDegreePage: React.FC = () => {

  return (
    <>
      <div className={"mb-4"}>
        <Link className={"flex items-center"} to={generatePath("/admin/manage/degrees")}>
          <FaArrowLeft className={"fill-baby-blue text-sm"}/>
          <span className={"text-sm ml-1 block text-baby-blue"}>Back</span>
        </Link>
      </div>
      <ul className={"flex items-center mb-5"}>
        {/*<li className={"mr-2"}><NavLink end={true} className={({ isActive }) => classNames("text-xs hover:underline", { "text-baby-blue underline": isActive, "text-neutral": !isActive })} to={generatePath(Routes.admin.manage.degrees.show, { id: degreeId.toString() })}>Data View</NavLink></li>*/}
        {/*<li><NavLink className={({ isActive }) => classNames("text-xs hover:underline", { "text-baby-blue underline": isActive, "text-neutral": !isActive })} to={generatePath(Routes.admin.manage.degrees.schedule, { id: degreeId.toString() })}>Schedule View</NavLink></li>*/}
      </ul>
      <Degree />
      <DegreeRequirementsList />
    </>
  );
};
