import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../infrastructure/selector';
import * as actions from "./actions";
import { ICourseAvailability } from '../../../../../../../api/courses/availbility';
import { termSeasonService } from '../../../../../../../services/term-season/TermSeasonService';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';

export const CourseAvailabilityList: React.FC = () => {

  const dispatch = useDispatch();

  const { isLoading, list } = useSelector(
    (state) => ({
      list: state.pages.admin.settings.course.availability.list.list,
      isLoading: state.pages.admin.settings.course.concurrency.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch())
  }, []);

  return (
    <>
      <div className={"mt-4"}>
        {list.map((item: ICourseAvailability, index: number) => {
          return (
            <div key={index} className={"w-1/2 mb-2"}>
                <span className={"text-xs text-neutral flex items-center"}><FaTimes className={"mr-2"} onClick={() => dispatch(actions.destroy.start(item.id))} />Not Available - {termSeasonService.getSeasonAsString(item.termSeason)} {item.termYear}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}
