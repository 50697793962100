import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { useSpring, animated } from 'react-spring';
import { useSelector } from '../../../../infrastructure/selector';
import { Avatar } from './avatar';
import { ProgressPreview } from './screens/progress-preview';
import { ProfilePreview } from './screens/profile-preview';
import { Actions } from './actions/index';

export const StudentDetailDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const drawerRef = useRef<HTMLDivElement>(null);
  const [screenPosition, setScreenPosition] = useState<number>(-1000);

  const { isOpen, activePreview } = useSelector(
    (state) => ({
      activePreview: state.pages.admin.dashboard.drawers.studentDetail.activePreview,
      isOpen: state.pages.admin.dashboard.drawers.studentDetail.isOpen,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!!drawerRef.current) {
      // setScreenPosition(-drawerRef.current.clientWidth);
      // @ts-ignore
      // document.addEventListener('resize', (event) => setScreenPosition(-drawerRef.current.clientWidth));
    }
    // return () => {
    //     document.removeEventListener('resize', () => {});
    // }
  }, [drawerRef.current?.clientWidth]);

  // @ts-ignore
  const animation = useSpring({ right: isOpen ? 0 : screenPosition });

  const components = [
    ProgressPreview,
    ProfilePreview
  ]

  return (
    <>
      <animated.div
        ref={drawerRef}
        style={animation}
        className={
          'z-50 cursor-pointer fixed top-0 sm:w-10/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:3/12 bg-neutral-lightest shadow-lg rounded-md h-full p-5'
        }
      >
        <div className={'w-full'}>
          <div>
            <MdClose
              className={'fill-neutral-dark text-2xl'}
              onClick={() => dispatch(actions.close())}
            />
            <div className={'overflow-y-scroll h-screen pb-20 hide-scrollbar'}>
              <Avatar />
              <Actions />
              {components.map((Component, index) => index == activePreview && <Component />)}
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};
