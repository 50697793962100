import { combineReducers } from 'redux';

import { studentsAdminDashboardReducer as students } from '../students/reducer';
import { courseDataReducer as courses } from '../courses/reducer';
import { courseReducer as course } from '../course/reducer';
import { adminStudentSnapshotReducer as student } from '../student/reducer';
import { adminOverviewReducer as overview } from './main/reducer';
import { adminDrawersReducer as drawers } from '../../drawers/reducer';

export const adminDashboardReducer = combineReducers({
  overview,
  students,
  courses,
  course,
  student,
  drawers,
});
