import { createAction } from 'redux-act';
import { IUser } from '../../../../api/user';
import { IValidationErrorResponse } from '../../../../types/error';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof login.start>
  | ReturnType<typeof login.done>
  | ReturnType<typeof login.error>;

export const fetch = createAction('[pages/login] fetch');

export const login = {
  start: createAction('[pages/login] login.start'),
  error: createAction<IValidationErrorResponse>('[pages/login] login.error'),
  done: createAction<IUser>('[pages/login] login.done'),
};

export const onChange = createAction<{ [key: string]: string }>(
  '[pages/login] onChange'
);
