import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import * as actions from "./actions";
import { RequirementCourses } from '../../components/requirement-courses';
import { ISelectedDegreeRequirementCourse } from '../../types';

export const EditRequirementCourses: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, degree, error, degreeId, requirementId, schoolId, assigned, selected, list, availableSeasons, gradeLevels } = useSelector(
    (state) => ({
      schoolId: state.common.configs.configuration.schoolId,
      availableSeasons: state.common.configs.configuration.availableSeasons,
      gradeLevels: state.common.configs.configuration.gradeLevels,
      degreeId: state.common.match.params.degreeId,
      requirementId: state.common.match.params.requirementId,
      isLoading: state.pages.admin.settings.degrees.update.degree.isLoading,
      degree: state.pages.admin.settings.degrees.update.degree,
      error: state.pages.admin.settings.degrees.update.degree.error,
      assigned: state.pages.admin.settings.degrees.requirements.update.courses.assigned,
      list: state.pages.admin.settings.degrees.requirements.update.courses.list,
      selected: state.pages.admin.settings.degrees.requirements.update.courses.selected
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   dispatch(actions.fetch());
  // }, []);

  return (
    <>
      <RequirementCourses
        isEdit={true}
        isLoading={false}
        assigned={assigned}
        availableSeasons={availableSeasons}
        gradeLevels={gradeLevels}
        handleAssign={(course: number) => dispatch(actions.assign(course))}
        handleFetch={() => dispatch(actions.fetch())}
        handleScheduleRequirementChange={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.onScheduleRequirementChange(course))}
        handleUnAssign={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.unAssign(course))}
        handleSelect={(course: ISelectedDegreeRequirementCourse) => dispatch(actions.select(course))}
        handleOnSearch={(value: string) => dispatch(actions.search(value))}
        selected={selected}
        list={list}
        schoolId={schoolId}
      />
    </>
  );
};
