import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { concat } from 'rxjs/operators';
import { api } from '../../../../../../api';
import { IEpic } from '../../../../../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.admin.courses.getAvailableDegreesByCourseId(
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error(error)).pipe(concat(source$))
    )
  );

const fetchRequirements: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetchRequirements),
    map(() => actions.loadRequirements.start())
  );

const loadRequirements: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadRequirements.start),
    switchMap(() => {
      const degreeId = parseFloat(
        state$.value.pages.admin.settings.course.majors.selectedDegree.value
      );

      return from(
        api.admin.degrees.getAvailableDegreeRequirementsByCourseId(
          degreeId,
          state$.value.common.match.params.id
        )
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadRequirements.done(data));
        })
      );
    }),
    catchError((error, source$) =>
      of(actions.loadRequirements.error(error)).pipe(concat(source$))
    )
  );

const assignCourseToDegree: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.assignCourseToDegree.start),
    switchMap(() => {
      const degreeId = parseFloat(
        state$.value.pages.admin.settings.course.majors.selectedDegree.value
      );
      const requirementId = parseFloat(
        state$.value.pages.admin.settings.course.majors.selectedRequirement
          .value
      );

      return from(
        api.admin.degrees.assignCourseToDegree({
          degreeId,
          requirementId,
          courseIds: [parseFloat(state$.value.common.match.params.id)],
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.assignCourseToDegree.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.assignCourseToDegree.error(error)).pipe(
        concat(source$)
      );
    })
  );

const removeCourseFromDegree: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.removeCourseFromDegree.start),
    switchMap(({ payload }) => {
      return from(
        api.admin.degrees.removeCourseFromDegree({
          degreeId: payload,
          courseIds: [parseFloat(state$.value.common.match.params.id)],
        })
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.removeCourseFromDegree.done(data));
        })
      );
    }),
    catchError((error, source$) => {
      return of(actions.removeCourseFromDegree.error(error)).pipe(
        concat(source$)
      );
    })
  );

export const epic = combineEpics(
  fetch,
  load,
  fetchRequirements,
  loadRequirements,
  assignCourseToDegree,
  removeCourseFromDegree
);
