import React, { useEffect } from 'react';
import { api } from '../../../../api';
import { Input } from '../../../../components/input';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/button';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../infrastructure/selector';
import * as actions from './actions';
import { AuthHeader } from '../../shared/header';
import * as forgotPasswordModalActions from './modals/forgot-password/actions';
import { ForgotPasswordModal } from './modals/forgot-password';
import { ResetPasswordModal } from './modals/reset-password';
import { history } from '../../../../app/store';
import { Helpers } from '../../../../helpers';

export const Login: React.FC = () => {
  const dispatch = useDispatch();

  const { login } = useSelector(
    (state) => ({
      login: state.pages.auth.login,
    }),
    shallowEqual
  );

  const { email, password, isLoading, error } = login;

  const handleLogin = async (e: any) => {
    e.preventDefault();
    dispatch(actions.fetch());
  };

  useEffect(() => {
    api.user.getUser().then(({ data }) => {
      Helpers.redirectToDashboard(data);
    });
  }, []);

  return (
    <>
      <section className={'flex items-center justify-between h-full'}>
        <section
          className={
            'w-full md:w-5/6 lg:w-5/6 flex flex-col items-center justify-center'
          }
        >
          <AuthHeader
            includeLogo={true}
            title={'Welcome Back'}
            subtitle={'please enter your details below'}
          />

          <form className={'w-1/3'}>
            <div className={'mb-9 mr-5 w-full'}>
              <Input
                placeholder={'enter email'}
                required={true}
                type={'text'}
                label={'Email'}
                name={'email'}
                value={email}
                error={error}
                onChange={(e) =>
                  dispatch(
                    actions.onChange({
                      key: 'email',
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className={'mb-4 mr-5 w-full'}>
              <Input
                placeholder={'enter password'}
                required={true}
                type={'password'}
                label={'Password'}
                name={'password'}
                value={password}
                error={error}
                onChange={(e) =>
                  dispatch(
                    actions.onChange({
                      key: 'password',
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div className={'text-right w-full'}>
              <a
                onClick={() => dispatch(forgotPasswordModalActions.open())}
                className={'text-blue text-xs text-right underline'}
                href="#"
              >
                Forgot password
              </a>
            </div>
            <div className={'mb-4 mr-5 w-full'}>
              <Button
                className={
                  'w-full bg-baby-blue font-semibold text-white px-4 py-3 text- rounded-xl mt-6 mb-4'
                }
                handleOnClick={(e) => handleLogin(e)}
                isLoading={isLoading}
              >
                Sign In
              </Button>
            </div>
            {/*<hr className={'border border-neutral-light mb-6'} />*/}
            {/*<p*/}
            {/*  className={*/}
            {/*    'text-neutral-darkest text-md mt-4 text-center font-semibold'*/}
            {/*  }*/}
            {/*>*/}
            {/*  Don't have an account?*/}
            {/*</p>*/}
            {/*<Button*/}
            {/*  className={*/}
            {/*    'w-full bg-secondary font-semibold text-white px-4 py-3 text- rounded-xl mt-6 mb-4'*/}
            {/*  }*/}
            {/*  handleOnClick={(e) => {*/}
            {/*    history.push('/register');*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Create One*/}
            {/*</Button>*/}
          </form>
        </section>
        <div
          style={{ background: "url('/assets/degreely-pattern.jpg')", backgroundPosition: "center", backgroundSize: "cover" }}
          className={'bg-blue-darkest w-0 md:w-1/6 lg:w-2/6 h-screen'}
        ></div>
        <ForgotPasswordModal />
        <ResetPasswordModal />
      </section>
    </>
  );
};
