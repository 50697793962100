import React, { useEffect } from 'react';
import { CourseList } from '../../../../../../components/course-list/list';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../infrastructure/selector';
import * as actions from './actions';
import { generatePath, useNavigate } from 'react-router-dom';
import { Pagination } from '../../../../../../components/pagination';

export const List: React.FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, list, user, currentPage, lastPage, totalPages } = useSelector(
    (state) => ({
      list: state.pages.admin.settings.courses.list.list,
      currentPage: state.pages.admin.settings.courses.list.currentPage,
      lastPage: state.pages.admin.settings.courses.list.lastPage,
      totalPages: state.pages.admin.settings.courses.list.totalPages,
      user: state.common.user.info,
      isLoading: state.pages.admin.settings.courses.list.isLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  return (
    <>
      <CourseList
        list={list}
        isLoading={isLoading}
        handleOnClickItem={(id: number) => navigate(generatePath("/admin/manage/courses/:id", { id: id.toString() }))}
      />
      <Pagination handleOnNextClick={(page: number) => dispatch(actions.paginate(page))} handleOnPreviousClick={(page: number) => dispatch(actions.paginate(page))} handleOnPageClick={() => false} currentPage={currentPage} lastPage={lastPage} totalPages={totalPages} />
    </>
  )
}
