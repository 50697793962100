import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import * as registerActions from '../../domains/auth/pages/register/actions';
import { api } from '../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../infrastructure/selector';

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(api.user.getUser()).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) => {
        return of(actions.load.error(error)).pipe(concat(source$))
    }
    )
  );

const logoutAfterRegister: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(registerActions.register.done),
    map(() => actions.logout.start())
  );

const logout: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.logout.start),
    switchMap(() =>
      from(api.auth.logout()).pipe(
        mergeMap(() => {
          return of(actions.logout.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.logout.error(error)).pipe(concat(source$))
    )
  );

export const epic = combineEpics(fetch, load, logout, logoutAfterRegister);
