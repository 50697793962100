import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ISchool } from '../../../../../../../api/schools';
import * as actions from '../../../actions';
import { colorStyles, controlErrorStyles, controlStyles } from '../../index';
import { shallowEqual, useDispatch } from 'react-redux';
import { api } from '../../../../../../../api';
import { Helpers } from '../../../../../../../helpers';
import { useSelector } from '../../../../../../../infrastructure/selector';

export const SchoolField: React.FC = () => {
  const dispatch = useDispatch();

  const [schools, setSchools] = useState<ISchool[]>([]);

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { error } = register;

  useEffect(() => {
    api.schools
      .getSchools()
      .then((res) => {
        setSchools(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const errorKey = 'school_id';
  let hasError =
    !!Object.keys(error).length && error.errors.hasOwnProperty(errorKey);

  let styles = {
    ...colorStyles,
  };

  if (hasError) {
    styles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  return (
    <div className={'mb-4'}>
      <label
        className={'block text-blue-darkest text-sm mb-1 font-semibold'}
        htmlFor="password"
      >
        School*
      </label>
      <Select
        placeholder={'select school'}
        name={'schoolId'}
        styles={styles}
        className={'text-sm text-black'}
        options={Helpers.mapToSelectOptions<ISchool>(
          schools,
          (item: ISchool) => ({ label: item.name, value: item.id.toString() })
        )}
        onChange={(option: any) =>
          dispatch(
            actions.onChange({
              key: 'schoolId',
              value: option?.value,
            })
          )
        }
      />
      {Helpers.hasError(error, 'school_id') && (
        <span className={'text-red text-xs'}>{error.errors['school_id']}</span>
      )}
    </div>
  );
};
