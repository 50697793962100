import React from 'react';
import { Label } from '../label';
import classNames from 'classnames';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';

export enum CheckboxLabelPosition {
  BeforeBox,
  AfterBox,
}

export enum CheckBoxBorder {
  None,
  Black,
  Neutral,
  Primary,
}

interface ICheckboxProps {
  checked: boolean;
  onChange: (e: any) => any;
  label?: string;
  size?: CheckBoxSize;
  rounded?: CheckBoxRounded;
  labelPosition?: CheckboxLabelPosition;
  labelClassNames?: string;
  containerClassNames?: string;
  border?: CheckBoxBorder;
  borderSize?: CheckBoxBorderSize;
  disabled?: boolean;
}

export enum CheckBoxBorderSize {
  OnePixel,
  TwoPixel,
}

export enum CheckBoxSize {
  xs,
  sm,
  md,
  lg,
  xl,
}

export enum CheckBoxRounded {
  none,
  xs,
  sm,
  md,
  lg,
  full,
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  onChange,
  label,
  size,
  rounded,
  labelPosition,
  labelClassNames,
  containerClassNames,
  border,
  borderSize,
  disabled
}) => {
  let styles = '';

  if (size == CheckBoxSize.md) {
    styles = 'h-5 w-5 flex items-center justify-center cursor-pointer';
  }

  if (size == CheckBoxSize.sm) {
    styles = 'h-3 w-3 flex items-center justify-center cursor-pointer';
  }

  if (rounded == CheckBoxRounded.md) {
    styles += ' rounded-md';
  }

  if (rounded == CheckBoxRounded.sm) {
    styles += ' rounded-sm';
  }

  if (border == CheckBoxBorder.Black && !checked) {
    styles += ' border border-black';
  }

  if (border == CheckBoxBorder.Neutral && !checked) {
    styles += ' border border-neutral';
  }

  if (border == CheckBoxBorder.Primary && !checked) {
    styles += ' border border-baby-blue';
  }

  if (borderSize == CheckBoxBorderSize.OnePixel) {
    styles += ' border';
  }

  if (borderSize == CheckBoxBorderSize.TwoPixel) {
    styles += ' border-2';
  }

  const classes = classNames(
    styles.split(' '),
    { 'bg-baby-blue': checked },
    { 'border border-baby-blue': checked }
  );

  return (
    <label
      role={'label'}
      htmlFor={label}
      className={containerClassNames + ' ' + 'cursor-pointer'}
    >
      <input
        disabled={disabled}
        type={'checkbox'}
        id={label}
        name={label}
        className={'p-2'}
        hidden
        onChange={(e) => onChange(e)}
        checked={checked}
      />
      {labelPosition == CheckboxLabelPosition.BeforeBox && !!label && (
        <Label name={label} classNames={labelClassNames} required={false} />
      )}
      <div className={classes}>
        {checked && (
          <FaCheck role={'check'} className={'stroke-white text-xs'} />
        )}
      </div>
      {labelPosition == CheckboxLabelPosition.AfterBox && !!label && (
        <Label name={label} classNames={labelClassNames} required={false} />
      )}
    </label>
  );
};

Checkbox.defaultProps = {
  label: '',
  size: CheckBoxSize.md,
  rounded: CheckBoxRounded.sm,
  labelPosition: CheckboxLabelPosition.AfterBox,
  labelClassNames: 'mb-0 font-normal',
  containerClassNames: 'flex items-center cursor-pointer',
  border: CheckBoxBorder.Black,
  borderSize: CheckBoxBorderSize.OnePixel,
};
