import { createReducer } from 'redux-act';
import * as actions from './actions';
import * as detailActions from '../details/actions';
import { ICourse } from '../../../../../../types/course';
import { ISelectOption } from '../../../../../../ui/select';

interface IState {
  list: ICourse[];
  isLoading: boolean;
  selected: ISelectOption[];
}

const initialState: IState = {
  isLoading: false,
  list: [],
  selected: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(detailActions.load.done, (state, payload) => ({
  ...state,
  selected:
    payload.course?.coursePrerequisiteGroups
      ?.map((group: any) => {
        return group.list.map((course: ICourse) => ({
          label: course.code + ' - ' + course.name,
          value: course.id,
        }));
      })
      .flat() ?? [],
}));

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload.list],
  };
});

reducer.on(actions.onChange, (state, payload) => {
  return {
    ...state,
    selected: payload,
  };
});

export const prerequisiteRequirementsReducer = reducer;
