import React from 'react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import {SimpleAction} from "../../../../../components/simple-action";

export interface ITermTrackHeader {
    handleOnAddTerm: () => any;
}
export const TermTrackHeader: React.FC<ITermTrackHeader> = ({ handleOnAddTerm }) => {


    return (
        <div className={"mt-5"}>
            <div className={"pb-2 flex items-end justify-between"}>
                <div>
                    <h3 className={"text-xl text-neutral-darkest"}>Your Plan</h3>
                    <span className={"text-xs text-neutral"}>Drag and drop courses between terms</span>
                </div>
            </div>
                <div className="flex items-center">
                    <div className={"grow bg-neutral-lighter mr-4"} style={{ height: "2px" }}></div>
                    <SimpleAction icon={HiPlus} label={"Add Term"} onClick={() => handleOnAddTerm()}/>
                </div>
        </div>
    )

}
