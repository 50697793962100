import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { DeleteRequirementModal } from './requirement/modals/delete-requirement';
import { AdminDegreesIndexPage } from './list';
import { AdminEditDegreePage } from './edit';
import { DegreeRequirementShowPage } from './requirement/show';
import { DegreeRequirementUpdatePage } from './requirement/edit';
import { CreateDegreeRequirementPage } from './requirement/create';

export const AdminSettingsDegreesPage: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={<AdminDegreesIndexPage />}
        />
        <Route
          path={"/:degreeId"}
          element={<AdminEditDegreePage />}
          />
        <Route
          path={"/:degreeId/requirements/:requirementId"}
          element={<DegreeRequirementShowPage />}
        />
        <Route
          path={"/:degreeId/requirements/:requirementId/update"}
          element={<DegreeRequirementUpdatePage />}
        />

        <Route
          path={"/:degreeId/requirements/create"}
          element={<CreateDegreeRequirementPage />}
        />
      </Routes>
      <DeleteRequirementModal />
    </>
  );
};
