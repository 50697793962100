import { createAction } from 'redux-act';
import { ISchedule } from '../../../../../api/schedules';

export type IActions =
  | ReturnType<typeof fetch>
  | ReturnType<typeof load.start>
  | ReturnType<typeof load.done>
  | ReturnType<typeof load.error>;

export const fetch = createAction('[pages/dashboard/track-list] fetch');

export const load = {
  start: createAction('[pages/dashboard/track-list] load.start'),
  error: createAction<any>('[pages/dashboard/track-list] load.error'),
  done: createAction<ISchedule[]>('[pages/dashboard/track-list] load.done'),
};
