import React, { useEffect, useState } from 'react';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { IPreppedStudentAccountInfo } from '../../../../../../../api/admin/invites';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import { AiOutlineCheck } from '@react-icons/all-files/ai/AiOutlineCheck';
import { Input, InputSize } from '../../../../../../../components/input';
import { SelectField } from '../../../../../../../ui';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { ISelectOption, SelectSize } from '../../../../../../../ui/select';
import { Helpers } from '../../../../../../../helpers';
import { IDegree } from '../../../../../../../api/admin/degrees';

export interface IStudentItemProps {
  student: IPreppedStudentAccountInfo;
  index: number;
  handleRemoveStudent: () => any;
  onEditing: (editing: boolean) => any;
  handleEditStudent: (
    student: IPreppedStudentAccountInfo,
    index: number
  ) => any;
}
export const StudentItem: React.FC<IStudentItemProps> = ({
  student,
  handleRemoveStudent,
  handleEditStudent,
  index,
  onEditing,
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { availableDegrees, availableSeasons, schoolId } = useSelector(
    (state) => ({
      uploadedStudents:
        state.pages.admin.invitations.modals.studentInvitesModal.uploaded,
      availableDegrees: state.pages.admin.common.config.degrees.list,
      availableSeasons: state.common.configs.configuration.availableSeasons,
      schoolId: state.common.user.info.schoolId,
    }),
    shallowEqual
  );

  const [name, setName] = useState<string>(student.name);
  const [studentId, setStudentId] = useState<string>(student.studentId);
  const [email, setEmail] = useState<string>(student.email);
  const [degree, setDegree] = useState<ISelectOption>(student.degree);
  const [startTerm, setStartTerm] = useState<ISelectOption>(student.startTerm);
  const [startYear, setStartYear] = useState<number>(student.startYear);

  useEffect(() => {
    if (isEditMode) {
      onEditing(isEditMode);
    }
  }, [isEditMode]);

  if (isEditMode) {
    return (
      <section className={'flex items-center mt-4 mb-2 border-b pb-3'}>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          <Input
            containerStyles={'mr-2'}
            size={InputSize.xs}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type={'text'}
            required={true}
            name={'name'}
          />
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          <Input
            containerStyles={'mr-2'}
            size={InputSize.xs}
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
            type={'text'}
            required={true}
            name={'studentId'}
          />
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          <Input
            containerStyles={'mr-2'}
            size={InputSize.xs}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={'text'}
            required={true}
            name={'email'}
          />
        </div>
        <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
          <SelectField
            containerStyles={'mr-2'}
            size={SelectSize.xs}
            error={{ errors: {}, message: '' }}
            defaultValue={[student.degree]}
            onChange={(option: ISelectOption) => setDegree(option)}
            options={Helpers.mapToSelectOptions<IDegree>(
              availableDegrees,
              (degree: IDegree) => ({
                value: degree.id.toString(),
                label: degree.name,
              })
            )}
            name={'degree'}
          />
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          <SelectField
            containerStyles={'mr-2'}
            size={SelectSize.xs}
            error={{ errors: {}, message: '' }}
            defaultValue={[student.startTerm]}
            onChange={(option: ISelectOption) => setStartTerm(option)}
            options={availableSeasons}
            name={'startTerm'}
          />
        </div>
        <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
          <Input
            containerStyles={'mr-2'}
            size={InputSize.xs}
            value={startYear.toString()}
            onChange={(e) => setStartYear(e.target.value)}
            type={'text'}
            required={true}
            name={'startYear'}
          />
        </div>
        <div
          className={
            'w-1/12 font-medium text-neutral-darkest text-xs text-right flex justify-end'
          }
        >
          <AiOutlineCheck
            className={'fill-baby-blue mr-2 text-lg cursor-pointer'}
            onClick={() => {
              setIsEditMode(!isEditMode);
              handleEditStudent(
                {
                  name,
                  schoolId,
                  studentId,
                  email,
                  degree,
                  startTerm,
                  startYear: Number(startYear),
                },
                index
              );
            }}
          />
        </div>
      </section>
    );
  }

  return (
    <section className={'flex items-center mt-4 mb-2 border-b pb-3'}>
      <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
        {student.name}
      </div>
      <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
        {student.studentId}
      </div>
      <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
        {student.email}
      </div>
      <div className={'w-2/12 font-medium text-neutral-darkest text-xs'}>
        {student.degree.label}
      </div>
      <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
        {student.startTerm.label}
      </div>
      <div className={'w-1/12 font-medium text-neutral-darkest text-xs'}>
        {student.startYear}
      </div>
      <div
        className={
          'w-1/12 font-medium text-neutral-darkest text-xs text-right flex justify-end'
        }
      >
        <AiOutlineEdit
          className={'fill-baby-blue mr-2 text-lg cursor-pointer'}
          onClick={() => setIsEditMode(!isEditMode)}
        />
        <BiTrash
          className={'fill-baby-blue text-lg cursor-pointer'}
          onClick={() => handleRemoveStudent()}
        />
      </div>
    </section>
  );
};
