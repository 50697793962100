import React from 'react';
import Select from 'react-select';
import * as actions from '../../../actions';
import { colorStyles, controlErrorStyles, controlStyles } from '../../index';
import { Season, UserRoleType } from '../../../../../../../constants';
import { shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useSelector } from '../../../../../../../infrastructure/selector';
import { Helpers } from '../../../../../../../helpers';

const getYearsList = () => {
  let yearsList: number[] = [];
  let currentYear = moment().year();

  for (let i = currentYear - 5; i < currentYear; i++) {
    yearsList.push(i);
  }

  for (let i = currentYear; i <= currentYear + 5; i++) {
    yearsList.push(i);
  }

  return yearsList.map((year) => ({
    value: year,
    label: year,
  }));
};

export const ProgramStartFields: React.FC = () => {
  const dispatch = useDispatch();

  const { register } = useSelector(
    (state) => ({
      register: state.pages.auth.register,
    }),
    shallowEqual
  );

  const { error, userRoleType } = register;

  const availableStartYears = getYearsList();

  const availableSeasons = [
    {
      value: Season.Fall.toString(),
      label: 'Fall',
    },
    {
      value: Season.Spring.toString(),
      label: 'Spring',
    },
    {
      value: Season.Summer.toString(),
      label: 'Summer',
    },
  ];

  let startSeasonStyles = {
    ...colorStyles,
  };
  let startYearStyles = {
    ...colorStyles,
  };

  let startSeasonHasError = Helpers.hasError(error, 'start_season');
  let startYearHasError = Helpers.hasError(error, 'start_year');

  if (startSeasonHasError) {
    startSeasonStyles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  if (startYearHasError) {
    startYearStyles = {
      ...colorStyles,
      control: (styles) => ({
        ...styles,
        ...controlStyles,
        ...controlErrorStyles,
      }),
    };
  }

  if (userRoleType == UserRoleType.Administrator) {
    return null;
  }

  return (
    <div className={'flex justify-between mb-4'}>
      <div className={'w-full'}>
        <label
          className={
            'block text-neutral-darkest text-sm mb-1 font-semibold text-blue-darkest'
          }
          htmlFor="password"
        >
          Program Start*
        </label>
        <div className={'w-full flex justify-between'}>
          <div className={'w-full mr-4'}>
            <Select
              styles={startSeasonStyles}
              options={availableSeasons}
              className={'text-sm w-full text-black'}
              name={'start_season'}
              placeholder={'select season'}
              id={'start_season'}
              onChange={(option: any) =>
                dispatch(
                  actions.onChange({
                    key: 'startSeason',
                    value: option?.value,
                  })
                )
              }
            />
            {startSeasonHasError && (
              <span className={'text-red text-xs'}>
                {error.errors['start_season']}
              </span>
            )}
          </div>

          <div className={'w-full ml-4'}>
            <Select
              styles={startYearStyles}
              options={availableStartYears}
              className={'text-sm w-full text-black'}
              name={'start_year'}
              placeholder={'select year'}
              id={'start_season'}
              onChange={(option: any) =>
                dispatch(
                  actions.onChange({
                    key: 'startYear',
                    value: option?.value,
                  })
                )
              }
            />
            {startYearHasError && (
              <span className={'text-red text-xs'}>
                {error.errors['start_year']}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
