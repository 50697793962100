import React from 'react';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

export enum ModalVariant {
  Centered,
  FullWidth,
  FullScreen,
}

export enum ModalPosition {
  Centered,
  Top,
  Bottom,
}

export interface IModalProps {
  isOpen: boolean;
  renderHeader?: React.FC<IModalHeaderProps>;
  renderBody: React.FC;
  renderFooter: React.FC;
  handleClose: () => any;
  variant?: ModalVariant;
  position?: ModalPosition;
  children?: any;
}

export interface IModalHeaderProps {
  handleClose: () => any;
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  renderHeader: ModalHeader,
  renderBody: ModalBody,
  renderFooter: ModalFooter,
  handleClose,
  variant,
  position,
  children,
}) => {
  let modalClasses = 'relative bg-white rounded-lg shadow-lg p-10';
  let modalStyles = {};

  if (variant === ModalVariant.FullWidth) {
    modalClasses += ' w-full m-8';
  } else {
    modalClasses += ' sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-6/12';
    modalStyles = { minHeight: 400, maxHeight: "auto" };
  }

  let modalPositionClasses = 'w-screen h-screen fixed flex z-50 top-0 left-0';

  if (position === ModalPosition.Centered) {
    modalPositionClasses += ' items-center justify-center';
  }

  if (position === ModalPosition.Top) {
    modalPositionClasses += '';
  }

  if (!isOpen) return null;

  return (
    <>
      <div
        className={
          'fixed bg-black top-0 left-0 h-screen w-screen flex items-center justify-center z-10 opacity-60'
        }
      ></div>
      <div className={modalPositionClasses}>
        <div className={modalClasses} style={modalStyles}>
          <header>
            <IoClose
              onClick={() => handleClose()}
              className={'text-3xl fill-neutral-dark cursor-pointer'}
            />
          </header>
          <ModalBody />
          <div className={'my-8 w-12/12 mx-auto'}>
            <div className={'flex flex-col pb-4'}>
              {children}
            </div>
          </div>
          <footer>
            <ModalFooter />
          </footer>
        </div>
      </div>
    </>
  );
};

Modal.defaultProps = {
  renderHeader: ({ handleClose }) => {
    return <header onClick={() => handleClose()}>header</header>;
  },
  renderBody: () => <section>body</section>,
  renderFooter: () => <footer>footer</footer>,
  variant: ModalVariant.Centered,
  position: ModalPosition.Centered,
};
