import {CourseCard} from "../../../../../../../components/course-card";
import React from "react";
import { ICourseRequest } from '../../../../../../../api/registration-periods';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

export interface IPreferenceBucketProps {
    requests: any[];
    onDragEnd: (result: DropResult) => void;
}

const grid = 0;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

export const PreferenceBucket: React.FC<IPreferenceBucketProps> = ({ requests, onDragEnd }) => {

    return (
      <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
        <div className={'flex flex-1'}>
                  {requests.map((zone, index) => {

                      if(zone.placeholder) {
                          return (
                            <div className='w-1/3 mr-8'>
                                <Droppable droppableId={index.toString()} direction='horizontal'>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                      >
                                            <section
                                              className={'flex-1 bg-white shadow-lg rounded-md py-2 px-2 mb-4 relative border-dotted border-t-2 border-r-2 border-b-2 border-neutral-light cursor-pointer hover:bg-neutral-lightest'}
                                              style={{ height: 95 }}>
                                                <div
                                                  className={'w-2 bg-neutral absolute top-0 left-0 rounded-tl-md rounded-bl-md h-full'}></div>
                                                <div
                                                  className={'flex items-center justify-center h-full'}>
                                                    <h6
                                                      className={'text-neutral-darkest font-normal text-md'}>Drag
                                                        class here</h6>
                                                    <span
                                                      className='className text-neutral font-light text-xxs -mb-2'></span>
                                                </div>
                                            </section>
                                      </div>
                                    )}
                                </Droppable>
                            </div>
                          )
                      }

                      return (
                        <div className='w-1/3 mr-8'>
                            <Droppable droppableId={index.toString()} direction='horizontal'>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                  >
                                      <Draggable isDragDisabled={false} key={zone.courseId}
                                                 draggableId={zone.courseId.toString()}
                                                 index={index}>
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                                <CourseCard name={zone.courseName} code={zone.courseCode}
                                                            courseId={zone.courseId} credits={zone.courseCredits}
                                                            borderColor={'00AAFF'} />
                                            </div>
                                          )}
                                      </Draggable>
                                  </div>
                                )}
                            </Droppable>
                        </div>
                      );
                  })}
            </div>
      </DragDropContext>
)}
