import { createReducer } from 'redux-act';
import * as actions from './actions';
import { ICourseRequest } from '../../../../../api/course-requests';

interface IState {
  isLoading: boolean;
  list: ICourseRequest[];
}

const initialState: IState = {
  isLoading: false,
  list: [],
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.load.start, (state, payload) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.load.error, (state, payload) => ({
  ...state,
  isLoading: false,
}));

reducer.on(actions.load.done, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    list: [...payload],
  };
});

export const courseRequestsReducer = reducer;
