import { combineEpics, ofType } from 'redux-observable';
import { catchError, concat, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../../api';
import { IEpic } from '../../../../../../../infrastructure/selector';
import { epic as courses } from "./courses/epic";
import { ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router';

const reset: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(ROUTER_ON_LOCATION_CHANGED),
    map(() => actions.reset())
  );
const fetch: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchRequirements: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.loadRequirements.start())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = state$.value.common.match.params.requirementId;

      return from(
        api.degrees.getDegreeRequirement(degreeId, requirementId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.load.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

const loadRequirements: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.loadRequirements.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = parseInt(state$.value.common.match.params.requirementId);

      return from(
        api.degrees.getDegreeRequirements(degreeId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.loadRequirements.done(data.filter((r) => r.id !== requirementId)));
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.loadRequirements.error(error.response.data)).pipe(concat(source$))
      }
    )
  );


const destroy: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.destroy.start),
    switchMap(() => {

      const degreeId = state$.value.common.match.params.degreeId;
      const requirementId = state$.value.common.match.params.requirementId;

      return from(
        api.degrees.destroyDegreeRequirement(degreeId, requirementId)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.destroy.done());
        })
      );
    }),
    catchError((error, source$) => {
        return of(actions.destroy.error(error.response.data)).pipe(concat(source$))
      }
    )
  );

export const epic = combineEpics(fetch, load, destroy, loadRequirements, courses, fetchRequirements);
