import React from 'react';
import { useSelector } from '../../../infrastructure/selector';
import { shallowEqual } from 'react-redux';
import { RiArrowDropDownLine } from '@react-icons/all-files/ri/RiArrowDropDownLine';
import { FaCog } from '@react-icons/all-files/fa/FaCog';
import { BiLogOut } from '@react-icons/all-files/bi/BiLogOut';
import { NavLink } from 'react-router-dom';
import { NavUserAvatar } from '../../../components/avatar';
import { Routes } from '../../../common/routes';
import {Helpers} from "../../../helpers";
import {StudentOnly} from "../../../common/guards/student-only";
import {FaTrello} from "@react-icons/all-files/fa/FaTrello";
import {BiSolidUser} from "react-icons/bi";
import { HiArrowUpOnSquareStack } from 'react-icons/hi2';

export const UserUniversity: React.FC = () => {

  const { user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.common.user.isLoading,
    }),
    shallowEqual
  );

  return (
    <>
      <StudentOnly>
        <div
            data-cy={"user-dropdown-navigation"}
            className={
              'flex justify-between items-center relative group cursor-pointer'
            }
        >
          <div className={'flex justify-between items-center'}>
            <NavUserAvatar
                name={user?.name}
                imageUrl={user?.avatarUrl}
                size={40}
            />
            <div className={'flex flex-col ml-3'}>
            <span className={'text-baby-blue text-sm font-medium'}>
              {user?.name}
            </span>
              <span className={'text-neutral-light text-xs font-normal'}>
              {user?.email}
            </span>
            </div>
          </div>
          <div className={'ml-2'}>
            <RiArrowDropDownLine className={'fill-neutral-light text-2xl'} />
          </div>
          <div
              className={
                'hidden bg-white rounded-md absolute w-full top-9 group-hover:block z-10 shadow-md'
              }
          >
            <ul>
              <li
                className={
                  'p-3 text-sm text-neutral-dark hover:bg-neutral-lightest cursor-pointer'
                }
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? 'text-baby-blue flex items-center'
                      : 'text-neutral-dark flex items-center'
                  }
                  to={Routes.student.schedules + "/" + user?.activeScheduleId}
                >
                  <FaTrello className={'mr-2 text-lg'} />
                  Schedules
                </NavLink>
              </li>
              <li
                className={
                  'p-3 text-sm text-neutral-dark hover:bg-neutral-lightest cursor-pointer'
                }
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? 'text-baby-blue flex items-center'
                      : 'text-neutral-dark flex items-center'
                  }
                  to={Routes.student.courseRequests.index}
                >
                  <HiArrowUpOnSquareStack className={"text-lg mr-2"} />
                  Course Requests
                </NavLink>
              </li>
              <li
                className={
                  'p-3 text-sm text-neutral-dark hover:bg-neutral-lightest cursor-pointer'
                }
              >
                <NavLink
                  data-cy={"user-profile-navigation"}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-baby-blue flex items-center'
                      : 'text-neutral-dark flex items-center'
                  }
                  to={Routes.user.profile}
                >
                  <BiSolidUser className={'mr-2 fill-text-neutral-dark'} />
                  Profile
                </NavLink>
              </li>
              <li
                className={
                  'p-3 text-sm text-neutral-dark hover:bg-neutral-lightest cursor-pointer flex items-center'
                }
                onClick={() => Helpers.handleLogout()}
              >
                <BiLogOut className={'mr-2 fill-text-neutral-dark'} />
                Logout
              </li>
            </ul>
          </div>
        </div>
      </StudentOnly>
    </>
  );
};
