import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CoursePreferenceSurvey } from './course-preference-survey';
import { CourseRequests } from './course-requests';

export const StudentRegistrationPage: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path={'/preferences/survey'} element={<CoursePreferenceSurvey />} />
      </Routes>
    </div>
  );
}
