import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as actions from './actions';
import { api } from '../../../../../../api';
import { concat } from 'rxjs/operators';
import { IEpic } from '../../../../../../infrastructure/selector';
import * as modalActions from "../modals/course-request-backups-modal/actions";

const fetch: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.fetch),
    map(() => actions.load.start())
  );

const fetchOnAdd: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.add.done, modalActions.add.done),
    map(() => actions.fetch())
  );

const fetchOnRemove: IEpic<any> = (action$) =>
  action$.pipe(
    ofType(actions.remove.done),
    map(() => actions.fetch())
  );

const load: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.load.start),
    switchMap(() =>
      from(
        api.courseRequests.getCourseRequests(state$.value.common.user.info.id)
      ).pipe(
        mergeMap(({ data }) => {
          return of(actions.load.done(data));
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.load.error()).pipe(concat(source$))
    )
  );

const add: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.add.start),
    switchMap(({ payload }) =>
      from(
        api.courseRequests.addCourseRequest(state$.value.common.user.info.id, payload)
      ).pipe(
        mergeMap(() => {
          return of(actions.add.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.add.error()).pipe(concat(source$))
    )
  );

const remove: IEpic<any> = (action$, state$) =>
  action$.pipe(
    ofType(actions.remove.start),
    switchMap(({ payload }) =>
      from(
        api.courseRequests.removeCourseRequest(state$.value.common.user.info.id, payload)
      ).pipe(
        mergeMap(() => {
          return of(actions.remove.done());
        })
      )
    ),
    catchError((error, source$) =>
      of(actions.remove.error()).pipe(concat(source$))
    )
  );
export const epic = combineEpics(
  fetch,
  fetchOnAdd,
  fetchOnRemove,
  load,
  add,
  remove
);
