import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../../infrastructure/selector';
import * as actions from "./actions";
import { generatePath, Link } from 'react-router-dom';
import { Routes } from '../../../../../../../../common/routes';
import { RequirementDetails } from '../../components/requirement-details';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import { FaArrowLeft } from 'react-icons/fa';

export const EditRequirementInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, degreeId, requirementId, info } = useSelector(
    (state) => ({
      info: state.pages.admin.settings.degrees.requirements.update.info,
      degreeId: state.common.match.params.degreeId,
      requirementId: state.common.match.params.requirementId,
      isLoading: state.pages.admin.settings.degrees.update.degree.isLoading,
      degree: state.pages.admin.settings.degrees.update.degree,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, []);

  if (isLoading) {
    return null;
  }

  const { name, numberRequired, type, color, error, fallbackRequirement, fallbackRequirementOptions  } = info;

  return (
    <>
      <Link className={"flex items-center"} to={generatePath(Routes.admin.manage.degrees.show, { id: degreeId })}>
        <FaArrowLeft className={"fill-baby-blue text-sm"}/>
        <span className={"text-sm ml-1 block text-baby-blue"}>Back</span>
      </Link>
      <div className={"flex items-center justify-between mt-4"}>
        <h1 className={'text-neutral-darkest text-lg mb-2'}>
          Degree Requirement: {name}
        </h1>
        <div className={"flex items-center"}>
          <Link className={"flex items-center"} to={generatePath(Routes.admin.manage.degrees.requirements.show, { degreeId: degreeId, requirementId: requirementId })}>
            <MdCheck className={"fill-baby-blue"}/>
            <span className={"text-sm ml-1 block text-baby-blue"}>Done</span>
          </Link>
        </div>
      </div>

      <hr className={''} />

      <RequirementDetails
        error={error}
        name={name}
        fallbackRequirementOptions={fallbackRequirementOptions}
        fallbackRequirement={{ value: fallbackRequirement.id?.toString(), label: fallbackRequirement.name }}
        type={{ label: type.label, value: type.value }}
        color={color} numberRequired={numberRequired}
        onChange={(key, value) => dispatch(actions.onChange({ key, value }))}
        onColorChange={(color) => dispatch(actions.onColorChange(color))}
        onTypeChange={(option) => dispatch(actions.onTypeChange(option))}
      />
    </>
  );
};
