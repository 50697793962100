import { createReducer } from 'redux-act';
import * as actions from './actions';
import { IValidationErrorResponse } from '../../../../../../types/error';
import { ISeasonSelect } from '../edit-track/reducer';
import { IAvailableSeason } from '../../../../../../api/schools';
import { IMajor, IMinor } from '../../../../../../api/majors';
import { ISelectOption } from '../../../../../../ui/select';

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  name: string;
  error: IValidationErrorResponse;
  available_seasons: IAvailableSeason[];
  majors: IMajor[];
  minors: IMinor[];
  form: {
    name: string;
    majors: number[];
    minors: number[];
    seasons: ISeasonSelect[];
  };
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  name: '',
  error: {
    message: '',
    errors: {},
  },
  majors: [],
  minors: [],
  available_seasons: [],
  form: {
    name: '',
    majors: [],
    minors: [],
    seasons: [],
  },
};

const reducer = createReducer<typeof initialState>({}, initialState);

reducer.on(actions.open, (state) => ({
  ...state,
  isOpen: true,
}));

reducer.on(actions.close, (state) => ({
  ...state,
  isOpen: false,
  error: {
    message: '',
    errors: {},
  },
}));

reducer.on(actions.loadConfiguration.done, (state, payload) => ({
  ...state,
  available_seasons: payload.availableSeasons,
  form: {
    ...state.form,
    seasons: payload.availableSeasons.map((s: IAvailableSeason) => ({
      checked: true,
      label: s.label,
      value: s.value,
      display: true,
    })),
  },
}));

reducer.on(actions.loadMajors.done, (state, payload) => ({
  ...state,
  majors: payload,
}));

reducer.on(actions.onNameChange, (state, payload) => ({
  ...state,
  error: {
    message: '',
    errors: {},
  },
  form: {
    ...state.form,
    name: payload,
  },
}));

reducer.on(actions.season.set, (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    seasons: payload,
  },
}));

reducer.on(actions.onMajorChange, (state, payload) => ({
  ...state,
  error: {
    message: '',
    errors: {},
  },
  form: {
    ...state.form,
    majors: payload.map((option: ISelectOption) => parseInt(option.value)),
  },
}));

reducer.on(actions.onMinorChange, (state, payload) => ({
  ...state,
  error: {
    message: '',
    errors: {},
  },
  form: {
    ...state.form,
    minors: payload.map((option: ISelectOption) => parseInt(option.value)),
  },
}));

reducer.on(actions.create.start, (state) => ({
  ...state,
  isLoading: true,
}));

reducer.on(actions.create.error, (state, payload) => ({
  ...state,
  error: payload,
  isLoading: false,
}));

reducer.on(actions.create.done, (state) => ({
  ...state,
  isLoading: false,
}));

export const createTrackModalReducer = reducer;
