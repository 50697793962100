import React from 'react';
import { CreateAvailability } from './create';
import { CourseAvailabilityList } from './list';

export const Availability: React.FC = () => {
    return (
      <div className="w-full mr-5 mt-4">
        <h3 className={'font-semibold text-neutral-darkest mt-3'}>
          Not Available For the Following Circumstances
        </h3>
        <span className={'text-neutral text-xs'}>
          Courses by default are available to all students for all terms and years. If you would like to restrict the course to a specific term or year, please select the term and year below.
        </span>
        <CourseAvailabilityList />
        <CreateAvailability />
      </div>
    )
}
