import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home } from '../../../icons';
import { GraduationCap } from '../../../icons/graduation-cap';
import { Courses } from '../../../icons/courses';
import { AddCourse } from '../../../icons/add-course';
import {AddProgram} from "../../../icons/add-program";
import {InviteStudent} from "../../../icons/invite-student";
import {InviteAdvisor} from "../../../icons/invite-advisor";
import { getAdminRoute } from '../../../domains/admin/routes';
import { Routes } from '../../../common/routes';

export const CollapsedSidebarNavigation = () => {
  return (
    <div>
        <ul className={"flex flex-col mb-8"}>
          <li className={"flex items-center flex-col"}>
            <NavLink to={getAdminRoute(Routes.admin.overview.index)} end={true} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active stroke-baby-blue text-baby-blue" : "p-1 stroke-white bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <Home classes={'text-2xl'}/>
              <span className={"text-xxxs font-light pt-1 group-[.is-active]:text-baby-blue"}>Overview</span>
            </NavLink>
          </li>
          <li className={"flex items-center flex-col"}>
            <NavLink to={getAdminRoute(Routes.admin.overview.students.index)} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "p-1 fill-white bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <GraduationCap classes={"text-2xl"} />
              <span className={"text-xxxs font-light pt-1"}>Students</span>
            </NavLink>
          </li>
          <li className={"flex items-center flex-col"}>
            <NavLink to={getAdminRoute(Routes.admin.overview.courses.index)} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1 bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <Courses />
              <span className={"text-xxxs font-light pt-1"}>Courses</span>
            </NavLink>
          </li>
          {/*<li className={"flex items-center flex-col mb-4"}>*/}
          {/*  <Programs />*/}
          {/*  <span className={"text-white text-xxxs font-light pt-1"}>Programs</span>*/}
          {/*</li>*/}
        </ul>
        <ul className={"flex flex-col mb-8"}>
          <li className={"flex items-center flex-col mb-4 text-center"}>
            <NavLink to={"/admin/manage/courses"} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1 bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <AddCourse />
              <span className={"text-xxxs font-light pt-1"}>Edit Courses</span>
            </NavLink>
          </li>
          <li className={"flex items-center flex-col mb-4 text-center"}>
            <NavLink to={"/admin/manage/degrees"} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1 bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <AddProgram />
              <span className={"text-xxxs font-light pt-1"}>Programs</span>
            </NavLink>
          </li>
        </ul>
        <ul className={"flex flex-col mb-8"}>
          <li className={"flex items-center flex-col mb-4 text-center"}>
            <NavLink to={"/admin/invitations/students"} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1 bg-baby-blue flex items-center flex-col mb-4 text-white"}>
              <InviteStudent />
              <span className={"text-xxxs font-light pt-1"}>Invite Student</span>
            </NavLink>
          </li>
          {/*<li className={"flex items-center flex-col mb-4"}>*/}
          {/*  <NavLink to={"/admin/invitations/student"} className={({ isActive}) => isActive ? "p-1 bg-white flex items-center flex-col mb-4 rounded-md group is-active fill-baby-blue text-baby-blue" : "fill-white p-1 bg-baby-blue flex items-center flex-col mb-4 text-white"}>*/}
          {/*    <InviteAdvisor />*/}
          {/*    <span className={"text-xxxs font-light pt-1"}>Invite Advisor</span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
        </ul>
    </div>
  )
}
