import { combineReducers } from 'redux';

import { authReducer as auth } from './auth/reducer';
import { adminDomainReducer as admin } from './admin/reducer';
import { studentDomainReducer as student } from './student/reducer';

export const reducers = combineReducers({
  auth,
  admin,
  student
});
