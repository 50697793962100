import React, { useEffect } from 'react';
import { RequirementProgress } from '../../../../../components/requirement-progress';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { IScheduleRequirementProgress } from '../../../../../api/schedules';

export const CourseProgress: React.FC = () => {
  const { requirements, isLoading } = useSelector(
    (state) => ({
      requirements: state.pages.dashboard.courseProgress.requirements,
      isLoading: state.pages.dashboard.courseProgress.isLoading,
    }),
    shallowEqual
  );

  if (!requirements.length) {
    return null;
  }

  return (
    <>
      <section className={'mt-4'}>
        <div className={'flex mt-3'}>
          {requirements.map(
            (requirement: IScheduleRequirementProgress, index: number) => (
              <RequirementProgress
                key={index}
                id={requirement.id}
                color={requirement.color}
                title={requirement.name}
                unit={requirement.unit}
                total={requirement.required}
                completed={requirement.completed}
                planned={requirement.planned}
              />
            )
          )}
        </div>
      </section>
    </>
  );
};
