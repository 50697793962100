
export const appRoutePrefix = "/admin";


export const routes = {
    dashboard: {
      index: '/dashboard',
      courses: {
        index: '/dashboard/courses',
        show: '/dashboard/courses/:id',
      },
      students: {
        profile: '/dashboard/students/:id/profile',
        index: '/dashboard/students',
        show: '/dashboard/students/:id',
      },
      reports: '/dashboard/reports',
    },
    invitations: {
      student: '/invitations/student',
    },
    settings: {
      index: '/settings',
      courses: {
        create: '/settings/courses/create',
        index: '/settings/courses',
        show: '/settings/courses/:id',
      },
      degrees: {
        index: '/settings/degrees',
        show: '/settings/degrees/:id',
      },
    },
    academicYears: {
      index: '/academic-years',
      show: '/academic-years/:id',
      create: '/academic-years/create',
    },
};

export const getAdminRoute = (path: string) => {
  return `${appRoutePrefix}${path}`;
}
