import { AxiosPromise } from 'axios';
import client from '../../axios.client';
import { IRangeFilter } from '../../../domains/admin/pages/common/filters/students/reducer';
import { IFilter } from '../../../types/filter';

export interface IStudentFilterResponse {
  cohorts: IFilter[];
  grades: IFilter[];
  percentCompleted: IRangeFilter;
  percentInProgress: IRangeFilter;
  percentPlanned: IRangeFilter;
}

export interface IStudentFiltersPayload {
  schoolId: number;
  cohorts: IFilter[];
  grades: IFilter[];
  percentCompleted: IRangeFilter;
  percentInProgress: IRangeFilter;
  percentPlanned: IRangeFilter;
  onlyFlaggedStudents: boolean;
}

export interface IStudentFiltersAPI {
  getFilters(
    payload: IStudentFiltersPayload
  ): AxiosPromise<IStudentFilterResponse>;
}

class StudentFiltersAPI implements IStudentFiltersAPI {
  getFilters(
    payload: IStudentFiltersPayload
  ): AxiosPromise<IStudentFilterResponse> {
    return client.post(`/api/students/filters`, {
      ...payload,
    });
  }
}

export const studentFiltersAPI = new StudentFiltersAPI();
