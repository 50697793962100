import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from './actions';
import { useSelector } from '../../../../../infrastructure/selector';
import { CourseList } from '../../../../../components/course-list/list';
import { Add } from '../../../../../ui/add';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../common/routes';
import { SearchInput } from '../../../../../components/search-input';
import { getAdminRoute } from '../../../routes';

export const Courses: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, courses, user } = useSelector(
    (state) => ({
      user: state.common.user.info,
      isLoading: state.pages.admin.dashboard.overview.courses.isLoading,
      courses: state.pages.admin.dashboard.overview.courses.list,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetch());
  }, [user.schoolId]);

  return (
    <div>
      <div className="flex justify-between">
        <h5 className={'text-neutral-darkest text-lg font-semibold mb-2'}>
          Courses
        </h5>
        <Add
          handleOnClick={() => navigate(getAdminRoute(Routes.admin.manage.courses.create))}
        />
      </div>
      <SearchInput
        handleOnChange={(e) => dispatch(actions.onChange(e.target.value))}
        placeholder={'Enter course name...'}
      />
      <CourseList
        list={courses}
        isLoading={isLoading}
        handleOnClickItem={(id) =>
          navigate(generatePath(getAdminRoute(Routes.admin.overview.courses.show), { id }))
        }
      />
    </div>
  );
};
